import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Icon,
  IconButton,
  styled,
  Typography,
} from '@mui/material'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import React from 'react'
import { WhatsAppProfile } from '@/app/module/phonenumbers/schemas'
import { DialogTitleContent } from '@/app/component/atom/dialog-title-content'
import IconText from '@/app/component/layout/icon-text'
import ExternalLink from '@/app/component/atom/external-link'
import { Input } from '@/app/component/atom/form'
import { ChangeEvent } from '@/app/types'
import { useRegisterWhatsappSenderId } from '@/app/module/phonenumbers/hooks/use-register-whatsapp-did'
import { useAppDispatch } from '@/app/store/redux-hooks'
import { createNotification } from '@/app/module/notifications/store/actions'
import { LoadingButton } from '@mui/lab'

type Props = Omit<DialogProps, 'onClose'> & {
  didId: number
  wabaId: string
  onClose: () => void
}

/**
 * Assuming the phone number has whatsappMetaOtp === false and the registration process has been completed,
 * we ask the user to enter their WhatsApp profile to be used for registering with Meta.
 * We currently only ask for profile name, but it can be extended to ask for other details like logo picture, address, description, etc etc.
 */
const ConnectMetaProfileDialog: React.FC<Props> = ({ didId, open, wabaId, onClose }) => {
  const dispatch = useAppDispatch()
  const [profile, setProfile] = React.useState<WhatsAppProfile>({
    name: '',
  })

  const [register, { isLoading }] = useRegisterWhatsappSenderId()
  const handleNameChange = (e: ChangeEvent) => setProfile({ ...profile, name: e.value })
  const handleRegister = async () => {
    try {
      await register(didId, wabaId, profile.name)
      dispatch(
        createNotification({
          'register-wa-did': {
            type: 'success',
            message: 'Your WhatsApp Sender ID has been successfully registered with Meta.',
          },
        }),
      )
      onClose()
    } catch {
      dispatch(
        createNotification({
          'register-wa-did': {
            type: 'error',
            disableAutoHide: true,
            message:
              'We were unable to complete the final step of setting up your WhatsApp Sender ID. Please try again later or contact support if the issue persists.',
          },
        }),
      )
    }
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <DialogTitleContent>
          <IconText>
            <WhatsAppIcon />
            <Typography variant="h6">Register WhatsApp Sender ID</Typography>
          </IconText>
          <IconButton onClick={onClose}>
            <Icon>close</Icon>
          </IconButton>
        </DialogTitleContent>
      </DialogTitle>
      <DialogContent>
        <FormContainer>
          <Typography variant="body1">Before continuing, please specify your WhatsApp Business Profile Name</Typography>
          <Input
            label="WhatsApp Business Profile Name"
            name="name"
            info={
              <Typography>
                The profile name must comply with Meta's{' '}
                <ExternalLink href="https://www.facebook.com/business/help/757569725593362" target="_blank">
                  WhatsApp display name guidelines
                </ExternalLink>
              </Typography>
            }
            onChange={handleNameChange}
          />
        </FormContainer>
      </DialogContent>
      <DialogActions>
        <LoadingButton disabled={!profile.name} loading={isLoading} onClick={handleRegister} variant="contained">
          Register
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

const FormContainer = styled('div')({
  paddingTop: 4,
})

export default ConnectMetaProfileDialog
