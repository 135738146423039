import React, { PropsWithChildren } from 'react'
import { z, ZodObject } from 'zod'
import { useForm, FieldValues, DefaultValues, FormProvider, FormState, Mode } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { FormField, FormFieldProps } from './field'
import { buildFieldConfig } from './utils/build-fields'

type FormProps<S extends ZodObject<{}>, T extends FieldValues = z.infer<S>> = PropsWithChildren<{
  defaultValues?: DefaultValues<T>
  fields?: Partial<{
    [K in keyof T]: FormFieldProps<T>
  }>
  id?: string
  mode?: Mode
  name?: string
  reValidateMode?: Exclude<Mode, 'onTouched' | 'all'>
  schema: S
  shouldReset?: boolean // on defaultValues change
  onSubmit: (data: T) => void
  renderActions?: (formState: FormState<T>) => React.ReactNode
}>

export function Form<S extends ZodObject<{}>, T extends FieldValues = z.infer<S>>({
  children,
  defaultValues,
  fields,
  id,
  mode = 'onBlur',
  name,
  reValidateMode = 'onBlur',
  schema,
  shouldReset,
  onSubmit,
  renderActions,
}: FormProps<S, T>) {
  const methods = useForm({
    defaultValues,
    mode,
    reValidateMode,
    resolver: zodResolver(schema),
  })
  const computedFields = React.useMemo(() => buildFieldConfig(schema, fields), [schema, fields])

  const { reset } = methods
  React.useEffect(() => {
    if (!shouldReset) {
      return
    }

    reset(defaultValues)
  }, [defaultValues, shouldReset, reset])

  return (
    <FormProvider {...methods}>
      <form id={id} name={name} aria-label={name} onSubmit={methods.handleSubmit(onSubmit)} noValidate>
        {Object.values<FormFieldProps<T>>(computedFields).map((field) => {
          return <FormField key={field.fieldProps.name} {...field} />
        })}
        {children}
        {renderActions && renderActions(methods.formState)}
      </form>
    </FormProvider>
  )
}
