import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import Layout from '@/app/component/layout/page'
import { AuthGuard, AuthHeader } from '@/app/module/auth'
import Notifications from '@/app/module/notifications'
import { updateItem } from '@/app/module/contacts/store/actions'
import CreateContactForm from '@/app/module/contacts/component/form/create-contact-form'

const Container = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  maxWidth: '960px',
  margin: 'auto',
}))

type ContactsItemPageProps = {
  name: string
  match: {
    url: string
    path: string
  }
  clearItem: () => void
}

// this complicated function is supposed to handle adding the contact to a group
// if needed, by redirecting to the contact page with the right query parameters
function getUrlDestination(urlString?: string, itemId?: number) {
  const [pathname, search = ''] = urlString?.split('?') || []
  const searchParams = new URLSearchParams(search)
  if (itemId) {
    if (['/contacts', '/contacts/'].includes(pathname)) {
      // if we are already on the contacts page, just add the query parameter
      searchParams.set('addToGroupContacts', String(itemId))
      return `${pathname}?${searchParams.toString()}`
    }
    // otherwise redirect to the default contacts page with the query parameter
    return `/contacts?addToGroupContacts=${itemId}`
  }
  return urlString || '/contacts'
}

function ContactsAddItemPage({ name, match, clearItem }: ContactsItemPageProps) {
  const history = useHistory()
  const location = useLocation()
  const onExitItem = React.useCallback(
    (itemId?: number) => {
      const urlDestination = getUrlDestination(location.state?.fromUrl, itemId)
      history.push(urlDestination)
    },
    [history, location],
  )

  React.useEffect(() => {
    return () => {
      clearItem()
    }
  }, [clearItem])

  return (
    <AuthGuard url={match.url} path={match.path}>
      <Layout title="Add Contact - engageSPARK">
        <AuthHeader currentPage={name} />
        <Container>
          <Box sx={{ my: 4 }}>
            <Typography variant="h5">Add contact</Typography>
          </Box>
          <CreateContactForm activeFilter={history.location.state?.contactsQuery?.filter} onExit={onExitItem} />
        </Container>
      </Layout>
      <Notifications />
    </AuthGuard>
  )
}

export default connect(
  ({ contacts }) => ({
    data: contacts.list.data,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        clearItem: () => () => {
          dispatch(updateItem({}))
        },
      },
      dispatch,
    ),
)(ContactsAddItemPage)
