import { DialogProps, GlobalStyles, Dialog as MuiDialog } from '@mui/material'
import React from 'react'

type Props = DialogProps

const Dialog: React.FC<Props> = ({ onClose, ...props }) => {
  // ignore backdropClick event
  const handleClose = React.useCallback(
    (event: any, reason: 'backdropClick' | 'escapeKeyDown') => {
      if (reason === 'backdropClick') {
        return
      }

      onClose?.(event, reason)
    },
    [onClose],
  )

  return (
    <>
      <MuiDialog {...props} onClose={handleClose} />
      {props.open && <GlobalStyles styles={styles} />}
    </>
  )
}

const styles = {
  body: {
    overflow: 'hidden !important',
  },
}

export default Dialog
