import { headers, toQuery } from '@/app/service/util'
import { omit, pathOr } from 'ramda'
import config from './index'

const defaultPageSizeModel = {
  default: {
    pageSize: 25,
  },
}

const contacts = pathOr(defaultPageSizeModel, ['modules', 'contacts'], config)
const cati = pathOr(defaultPageSizeModel, ['modules', 'cati'], config)
const campaigns = pathOr(defaultPageSizeModel, ['modules', 'campaigns'], config)
const exports = pathOr(defaultPageSizeModel, ['modules', 'exports'], config)

export default {
  auth: {
    sign: {
      method: 'POST',
      headers,
      getPath: () => `${config.svc.url}/v1/sign`,
    },
    signin: {
      headers,
      method: 'POST',
      getPath: () => `${config.svc.url}/v1/auth/signin`,
    },
    signup: {
      headers,
      method: 'POST',
      getPath: () => `${config.svc.url}/v1/auth/signup`,
    },
    signupEmail: {
      headers,
      method: 'POST',
      getPath: () => `${config.svc.url}/v1/auth/signupemail`,
    },
    requestResetPassword: {
      headers: () => ({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
      method: 'POST',
      json: false,
      getPath: () => `${config.svc.url}/v1/auth/passwordreset`,
    },
    resetPassword: {
      headers,
      method: 'POST',
      getPath: () => `${config.svc.url}/v1/auth/passwordresetchange`,
    },
    emailCheck: {
      method: 'GET',
      getPath: ({
        query = {
          email: '',
        },
      } = {}) => `${config.svc.url}/v1/auth/emailcheck?${toQuery(query)}`,
    },
    verifyOTP: {
      headers: (token) => ({
        headers: {
          ...headers(token).headers,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }),
      method: 'POST',
      json: false,
      getPath: () => `${config.svc.url}/v1/auth/totp`,
    },
    checkInvitation: {
      headers,
      method: 'GET',
      getPath: ({ invitationToken } = {}) => `${config.svc.url}/invites/check.json?invtoken=${invitationToken}`,
    },
    acceptInvitation: {
      headers: (token) => ({
        headers: {
          ...headers(token).headers,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }),
      method: 'POST',
      json: false,
      getPath: () => `${config.svc.url}/invites/accept.json`,
    },
    acceptInvitationWithSignup: {
      method: 'POST',
      getPath: () => `${config.svc.url}/invites/signupaccept.json`,
    },
  },
  contacts: {
    get: {
      headers,
      method: 'GET',
      getPath: ({
        orgId = 0,
        query: { page = 1, size = contacts.pageSize.default, searchTerm, subscriptionFilter, subscriptionStatus } = {},
      } = {}) => {
        const query = toQuery({
          page,
          size,
          search: searchTerm,
          subscriptionfilter: subscriptionFilter,
          subscriptionstatus: subscriptionStatus,
        })
        return `${config.svc.url}/v1/organizations/${orgId}/contacts/?${query}`
      },
    },
    getItem: {
      headers,
      method: 'GET',
      getPath: ({ orgId = 0, itemId = ':itemId', query: { subscriptionFilter, subscriptionStatus } = {} } = {}) => {
        const q = toQuery({ subscriptionfilter: subscriptionFilter, subscriptionstatus: subscriptionStatus })
        return `${config.svc.url}/v1/organizations/${orgId}/contacts/${itemId}/${q ? `?${q}` : ''}`
      },
    },
    addItem: {
      headers,
      method: 'POST',
      getPath: ({ orgId = 0 } = {}) => `${config.svc.url}/v1/organizations/${orgId}/contacts/`,
    },
    saveItem: {
      headers,
      method: 'PUT',
      getPath: ({ orgId = 0, itemId = ':itemId' } = {}) =>
        `${config.svc.url}/v1/organizations/${orgId}/contacts/${itemId}/`,
    },
    delete: {
      headers,
      method: 'POST',
      getPath: ({ orgId = 0 } = {}) => `${config.svc.url}/v1/organizations/${orgId}/contacts/delete`,
    },
    deleteItem: {
      headers,
      method: 'DELETE',
      json: false,
      getPath: ({ orgId = 0, itemId = ':itemId' } = {}) =>
        `${config.svc.url}/v1/organizations/${orgId}/contacts/${itemId}/`,
    },
    download: {
      headers,
      method: 'GET',
      getSignablePath: ({ orgId = 0, ext = 'xlsx' } = {}) => `/v1/organizations/${orgId}/contacts/export.${ext}`,
      getPath: ({
        signedPath = '',
        urlToken = ':urlToken',
        query: { id = [], ex = [], group = [], search = '' } = {},
      } = {}) => {
        let url = `${config.svc.url}${signedPath}?token=${urlToken}`
        if (id.length) {
          url += `&id=${id.join(',')}`
        }
        if (ex.length) {
          url += `&ex=${ex.join(',')}`
        }
        if (group.length > 0) {
          url += `&group=${group.join(',')}`
        }
        if (search !== '') {
          url += `&search=${search}`
        }
        return url
      },
    },
    upload: {
      headers: (token) => ({
        headers: {
          ...omit(['Content-Type'], headers(token).headers),
        },
      }),
      method: 'POST',
      getPath: ({ orgId = 0 } = {}) => `${config.svc.url}/v1/organizations/${orgId}/bulkupload/`,
    },
    uploadConfirm: {
      headers,
      method: 'POST',
      getPath: ({ orgId = 0 } = {}) => `${config.svc.url}/v1/organizations/${orgId}/bulkupload/confirm`,
    },
    getUploadStatus: {
      headers,
      method: 'GET',
      getPath: ({ orgId = 0, uploadKey = ':uploadKey' } = {}) =>
        `${config.svc.url}/v1/organizations/${orgId}/bulkupload/completed?key=${uploadKey}`,
    },
    getInvalidFile: {
      headers,
      method: 'GET',
      getSignablePath: ({ orgId = 0 } = {}) => `/v1/organizations/${orgId}/bulkupload/invalid.csv`,
      getPath: ({ signedPath = '', uploadKey = ':uploadKey', urlToken = ':urlToken' } = {}) =>
        `${config.svc.url}${signedPath}?key=${uploadKey}&token=${urlToken}`,
    },
  },
  customFields: {
    get: {
      headers,
      method: 'GET',
      getPath: ({ orgId = 0 } = {}) => `${config.svc.url}/v1/organizations/${orgId}/customfields/`,
    },
    addItem: {
      headers,
      method: 'POST',
      getPath: ({ orgId = 0 } = {}) => `${config.svc.url}/v1/organizations/${orgId}/customfields/`,
    },
    saveItem: {
      headers,
      method: 'PUT',
      getPath: ({ orgId = 0, itemId = ':itemId' } = {}) =>
        `${config.svc.url}/v1/organizations/${orgId}/customfields/${itemId}/`,
    },
    deleteItem: {
      headers,
      method: 'DELETE',
      getPath: ({ orgId = 0, itemId = ':itemId' } = {}) =>
        `${config.svc.url}/v1/organizations/${orgId}/customfields/${itemId}/`,
    },
  },
  demos: {
    get: {
      headers,
      method: 'GET',
      getPath: () => `${config.svc.url}/demos/`,
    },
    select: {
      headers,
      method: 'POST',
      getPath: ({ itemId } = {}) => `${config.svc.url}/demos/${itemId}/try`,
    },
  },
  user: {
    getDetails: {
      headers,
      method: 'GET',
      getPath: () => `${config.svc.url}/v1/user/`,
    },
    save: {
      headers,
      method: 'POST',
      getPath: () => `${config.svc.url}/v1/user/`,
    },
    saveUser: {
      headers,
      method: 'POST',
      getPath: () => `${config.svc.url}/v1/user/`,
    },
    getOrganization: {
      headers,
      method: 'GET',
      getPath: ({ orgId = 0 } = {}) => `${config.svc.url}/v1/organizations/${orgId}/`,
    },
    switchOrganization: {
      headers: (token) => ({
        headers: {
          Authorization: `JWT ${token}`,
        },
      }),
      method: 'POST',
      getPath: () => `${config.svc.url}/v1/user/`,
    },
    initTotp: {
      headers,
      method: 'GET',
      getPath: () => `${config.svc.url}/v1/user/totp.json`,
    },
    enableTotp: {
      headers,
      method: 'POST',
      getPath: () => `${config.svc.url}/v1/user/totp.json`,
    },
    disableTotp: {
      headers,
      method: 'DELETE',
      getPath: () => `${config.svc.url}/v1/user/totp.json`,
    },
    getRecoveryCodes: {
      headers,
      method: 'GET',
      getPath: () => `${config.svc.url}/v1/user/recoverycodes.json`,
    },
    resetRecoveryCodes: {
      headers,
      method: 'POST',
      getPath: () => `${config.svc.url}/v1/user/recoverycodes.json`,
    },
  },
  organizations: {
    save: {
      headers,
      method: 'POST',
      getPath: () => `${config.svc.url}/v1/organizations/`,
    },
    inviteUser: {
      headers,
      method: 'POST',
      getPath: ({ orgId = 0 } = {}) => `${config.svc.url}/v1/organizations/${orgId}/invites/`,
    },
    getInvitations: {
      headers,
      method: 'GET',
      getPath: ({ orgId = 0 } = {}) => `${config.svc.url}/v1/organizations/${orgId}/invites/`,
    },
    getUsers: {
      headers,
      method: 'GET',
      getPath: ({ orgId = 0 } = {}) => `${config.svc.url}/v1/organizations/${orgId}/users/`,
    },
    updateUser: {
      headers,
      method: 'POST',
      getPath: ({ orgId = 0, userId = ':userId' } = {}) =>
        `${config.svc.url}/v1/organizations/${orgId}/users/${userId}/`,
    },
    deleteUser: {
      headers,
      method: 'DELETE',
      getPath: ({ orgId = 0, userId = ':userId' } = {}) =>
        `${config.svc.url}/v1/organizations/${orgId}/users/${userId}/`,
    },
    deleteInvitation: {
      headers,
      method: 'DELETE',
      getPath: ({ orgId = 0, inviteId = ':inviteId' } = {}) =>
        `${config.svc.url}/v1/organizations/${orgId}/invites/${inviteId}/`,
    },
    getSubscriptions: {
      headers,
      method: 'GET',
      getPath: ({
        orgId = 0,
        query: {
          page = 1,
          size = cati.pageSize.default,
          direction,
          searchTerm,
          template,
          subdone = true,
          skipdeletedcontacts,
        } = {},
      } = {}) => {
        const query = toQuery({
          page,
          size,
          direction,
          search: searchTerm,
          template,
          subdone,
          skipdeletedcontacts,
        })
        return `${config.svc.url}/v1/organizations/${orgId}/subs/?${query}`
      },
    },
    transferFunds: {
      headers,
      method: 'POST',
      getPath: ({ orgId = 0 } = {}) => `${config.svc.url}/v1/organizations/${orgId}/fundstransfer.json`,
    },
  },
  snippets: {
    getList: {
      headers,
      method: 'GET',
      getPath: ({ orgId = 0 } = {}) => `${config.svc.url}/v1/organizations/${orgId}/snippets/`,
    },
    save: {
      headers,
      method: 'POST',
      getPath: ({ orgId = 0 } = {}) => `${config.svc.url}/v1/organizations/${orgId}/snippets/`,
    },
  },
  phoneNumbers: {
    getList: {
      headers,
      method: 'GET',
      getPath: ({ orgId = 0 } = {}) => `${config.svc.url}/v1/organizations/${orgId}/phonenumbers/`,
    },
    getItem: {
      headers,
      method: 'GET',
      getPath: ({ orgId = 0, phoneNumberId = ':phoneNumberId' } = {}) =>
        `${config.svc.url}/v1/organizations/${orgId}/phonenumbers/${phoneNumberId}/`,
    },
    addHook: {
      headers,
      method: 'POST',
      getPath: ({ orgId = 0, phoneNumberId } = {}) =>
        `${config.svc.url}/v1/organizations/${orgId}/phonenumbers/${phoneNumberId}/hooks/`,
    },
    updateHook: {
      headers,
      method: 'POST',
      getPath: ({ orgId = 0, phoneNumberId, itemId } = {}) =>
        `${config.svc.url}/v1/organizations/${orgId}/phonenumbers/${phoneNumberId}/hooks/${itemId}/`,
    },
    deleteHook: {
      headers,
      method: 'DELETE',
      json: false,
      getPath: ({ orgId = 0, phoneNumberId, itemId } = {}) =>
        `${config.svc.url}/v1/organizations/${orgId}/phonenumbers/${phoneNumberId}/hooks/${itemId}/`,
    },
    addWhatsappHook: {
      headers,
      method: 'POST',
      getPath: ({ orgId = 0, phoneNumberId } = {}) =>
        `${config.svc.url}/v1/organizations/${orgId}/phonenumbers/${phoneNumberId}/wahooks/`,
    },
    updateWhatsappHook: {
      headers,
      method: 'POST',
      getPath: ({ orgId = 0, phoneNumberId, itemId } = {}) =>
        `${config.svc.url}/v1/organizations/${orgId}/phonenumbers/${phoneNumberId}/wahooks/${itemId}/`,
    },
    deleteWhatsappHook: {
      headers,
      method: 'DELETE',
      json: false,
      getPath: ({ orgId = 0, phoneNumberId, itemId } = {}) =>
        `${config.svc.url}/v1/organizations/${orgId}/phonenumbers/${phoneNumberId}/wahooks/${itemId}/`,
    },
    addCallHook: {
      headers,
      method: 'POST',
      getPath: ({ orgId = 0, phoneNumberId } = {}) =>
        `${config.svc.url}/v1/organizations/${orgId}/phonenumbers/${phoneNumberId}/callhooks/`,
    },
    updateCallHook: {
      headers,
      method: 'POST',
      getPath: ({ orgId = 0, phoneNumberId, itemId } = {}) =>
        `${config.svc.url}/v1/organizations/${orgId}/phonenumbers/${phoneNumberId}/callhooks/${itemId}/`,
    },
    deleteCallHook: {
      headers,
      method: 'DELETE',
      json: false,
      getPath: ({ orgId = 0, phoneNumberId, itemId } = {}) =>
        `${config.svc.url}/v1/organizations/${orgId}/phonenumbers/${phoneNumberId}/callhooks/${itemId}/`,
    },
    post: {
      headers,
      method: 'POST',
      getPath: ({ orgId = 0, phoneNumberId } = {}) =>
        `${config.svc.url}/v1/organizations/${orgId}/phonenumbers/${phoneNumberId}/`,
    },
  },
  calls: {
    get: {
      headers,
      method: 'GET',
      getPath: ({ orgId = 0, query: { dir, failed, limit, after, search, startDate, endDate } = {} } = {}) => {
        const query = toQuery({ dir, failed, limit, after, search, startDate, endDate })
        return `${config.svc.url}/v1/organizations/${orgId}/calls/?${query}`
      },
    },
    download: {
      headers,
      method: 'GET',
      getSignablePath: ({ orgId = 0 } = {}) => `/v1/organizations/${orgId}/calls/export.xlsx`,
      getPath: ({
        signedPath = ':signedPath',
        urlToken = ':urlToken',
        query: { dir, failed, search, startDate, endDate } = {},
      } = {}) => {
        const query = toQuery({ dir, failed, search, startDate, endDate })
        return `${config.svc.url}${signedPath}?token=${urlToken}&${query}`
      },
    },
  },
  topups: {
    get: {
      headers,
      method: 'GET',
      getPath: ({ orgId = 0, query: { dir, failed, limit, after, search, startDate, endDate } = {} } = {}) => {
        const query = toQuery({ dir, failed, limit, after, search, startDate, endDate })
        return `${config.svc.url}/v1/organizations/${orgId}/topups/?${query}`
      },
    },
    download: {
      headers,
      method: 'GET',
      getSignablePath: ({ orgId = 0 } = {}) => `/v1/organizations/${orgId}/topups/export.xlsx`,
      getPath: ({
        signedPath = ':signedPath',
        urlToken = ':urlToken',
        query: { dir, failed, search, startDate, endDate } = {},
      } = {}) => {
        const query = toQuery({ dir, failed, search, startDate, endDate })
        return `${config.svc.url}${signedPath}?token=${urlToken}&${query}`
      },
    },
  },
  whatsapp: {
    get: {
      headers,
      method: 'GET',
      getPath: ({ orgId = 0, query: { dir, failed, limit, after, search, startDate, endDate } = {} } = {}) => {
        const query = toQuery({ dir, failed, limit, after, search, startDate, endDate })
        return `${config.svc.url}/v1/organizations/${orgId}/whatsapp/?${query}`
      },
    },
    send: {
      headers,
      method: 'POST',
      getPath: () => `${config.svc.url}/v1/whatsapp/contact`,
    },
    download: {
      headers,
      method: 'GET',
      getSignablePath: ({ orgId = 0 } = {}) => `/v1/organizations/${orgId}/whatsapp/export.xlsx`,
      getPath: ({
        signedPath = ':signedPath',
        urlToken = ':urlToken',
        query: { dir, failed, search, startDate, endDate } = {},
      } = {}) => {
        const query = toQuery({ dir, failed, search, startDate, endDate })
        return `${config.svc.url}${signedPath}?token=${urlToken}&${query}`
      },
    },
  },
  messages: {
    get: {
      headers,
      method: 'GET',
      getPath: ({ orgId = 0, query: { dir, failed, limit, after, search, startDate, endDate } = {} } = {}) => {
        const query = toQuery({ dir, failed, limit, after, search, startDate, endDate })
        return `${config.svc.url}/v1/organizations/${orgId}/messages/?${query}`
      },
    },
    send: {
      headers,
      method: 'POST',
      getPath: ({ orgId } = {}) => `${config.svc.url}/v1/organizations/${orgId}/sms/contacts`,
    },
    download: {
      headers,
      method: 'GET',
      getSignablePath: ({ orgId = 0 } = {}) => `/v1/organizations/${orgId}/messages/export.xlsx`,
      getPath: ({
        signedPath = ':signedPath',
        urlToken = ':urlToken',
        query: { dir, failed, search, startDate, endDate } = {},
      } = {}) => {
        const query = toQuery({ dir, failed, search, startDate, endDate })
        return `${config.svc.url}${signedPath}?token=${urlToken}&${query}`
      },
    },
  },
  senderIds: {
    get: {
      headers,
      method: 'GET',
      getPath: ({ orgId = 0 } = {}) => `${config.svc.url}/v1/organizations/${orgId}/senderids/`,
    },
    getList: {
      headers,
      method: 'GET',
      getPath: ({ orgId = 0 } = {}) => `${config.svc.url}/v1/organizations/${orgId}/senderids/`,
    },
  },
  campaigns: {
    getList: {
      headers,
      method: 'GET',
      getPath: ({
        orgId = 0,
        archived = 'false',
        published,
        launched,
        open,
        aborted,
        templates,
        search,
        query: { page = 1, size = campaigns.pageSize.default } = {},
      } = {}) => {
        const query = toQuery({ page, size, archived, aborted, published, launched, open, templates, search })
        return `${config.svc.url}/v1/organizations/${orgId}/engagements/?${query}`
      },
    },
    getItem: {
      headers,
      method: 'GET',
      getPath: ({ orgId = 0, engId = ':engId' } = {}) =>
        `${config.svc.url}/v1/organizations/${orgId}/engagements/${engId}/`,
    },
    getSummary: {
      headers,
      method: 'GET',
      getPath: ({ orgId = 0, engId = ':engId' } = {}) =>
        `${config.svc.url}/v1/organizations/${orgId}/engagements/${engId}/summary`,
    },
    saveItem: {
      headers,
      method: 'POST',
      getPath: ({ orgId = 0, engId } = {}) => {
        if (engId) {
          return `${config.svc.url}/v1/organizations/${orgId}/engagements/${engId}/`
        }
        return `${config.svc.url}/v1/organizations/${orgId}/engagements/`
      },
    },
    setPublishItem: {
      headers,
      method: 'POST',
      getPath: ({ orgId = 0, engId = ':engId' } = {}) =>
        `${config.svc.url}/v1/organizations/${orgId}/engagements/${engId}/published`,
    },
    setOpenItem: {
      headers,
      method: 'POST',
      getPath: ({ orgId = 0, engId = ':engId' } = {}) =>
        `${config.svc.url}/v1/organizations/${orgId}/engagements/${engId}/open`,
    },
    setArchivedItem: {
      headers,
      method: 'POST',
      getPath: ({ orgId = 0, engId = ':engId' } = {}) =>
        `${config.svc.url}/v1/organizations/${orgId}/engagements/${engId}/archived`,
    },
    setAbortItem: {
      headers,
      method: 'POST',
      getPath: ({ orgId = 0, engId = ':engId' } = {}) =>
        `${config.svc.url}/v1/organizations/${orgId}/engagements/${engId}/abort`,
    },
    subscribeToItem: {
      headers,
      method: 'POST',
      getPath: ({ orgId = 0, engId = ':engId' } = {}) =>
        `${config.svc.url}/v1/organizations/${orgId}/engagements/${engId}/subscribecontacts`,
    },
    unsubscribe: {
      headers,
      method: 'POST',
      getPath: ({ orgId = 0, engId = ':engId' } = {}) =>
        `${config.svc.url}/v1/organizations/${orgId}/engagements/${engId}/unsubscribecontacts`,
    },
    copyItem: {
      headers,
      method: 'POST',
      getPath: ({ orgId = 0, engId = ':engId' } = {}) =>
        `${config.svc.url}/v1/organizations/${orgId}/engagements/${engId}/copy`,
    },
    getItemRuns: {
      headers,
      method: 'GET',
      getPath: ({ orgId = 0, engId = ':engId' } = {}) =>
        `${config.svc.url}/v1/organizations/${orgId}/engagements/${engId}/runs/`,
    },
    abortItemRun: {
      headers,
      method: 'POST',
      getPath: ({ orgId = 0, engId = ':engId', runId = ':runId' } = {}) =>
        `${config.svc.url}/v1/organizations/${orgId}/engagements/${engId}/runs/${runId}/abort`,
    },
    exportReport: {
      headers,
      method: 'GET',
      getDownloadPath: ({ signedPath = '', urlToken = ':urlToken' } = {}) =>
        `${config.svc.url}${signedPath}?token=${urlToken}`,
      getPath: ({ orgId = 0, engId = ':engId', tz, fields = [], labels = false } = {}) =>
        `${config.svc.url}/v1/organizations/${orgId}/engagements/${engId}/exportreport?tz=${tz}&columns=${fields.join(
          ',',
        )}&labels=${labels}`,
      getSignablePath: ({ orgId = 0, exportId = 0, name = 'filename.ext' } = {}) =>
        `/v1/organizations/${orgId}/exports/${exportId}/${name}`,
    },
  },
  groups: {
    getList: {
      headers,
      method: 'GET',
      getPath: ({ orgId = 0, query: { page, size, type, search } = {} } = {}) => {
        const query = { page, size, type, sortBy: 'name', direction: 'ASC', search }
        return `${config.svc.url}/v1/organizations/${orgId}/groups/?${toQuery(query)}`
      },
    },
    getItem: {
      headers,
      method: 'GET',
      getPath: ({ orgId = 0, query: { itemId } = {} } = {}) =>
        `${config.svc.url}/v1/organizations/${orgId}/groups/${itemId}/`,
    },
    addItem: {
      headers,
      method: 'POST',
      getPath: ({ orgId = 0 } = {}) => `${config.svc.url}/v1/organizations/${orgId}/groups/`,
    },
    saveItem: {
      headers,
      method: 'PUT',
      getPath: ({ orgId = 0, itemId = ':itemId' } = {}) =>
        `${config.svc.url}/v1/organizations/${orgId}/groups/${itemId}/`,
    },
    delete: {
      headers,
      method: 'DELETE',
      getPath: ({ orgId = 0, itemId = ':itemId' } = {}) =>
        `${config.svc.url}/v1/organizations/${orgId}/groups/${itemId}/`,
    },
    getContacts: {
      headers,
      method: 'GET',
      getPath: ({
        orgId = 0,
        groupId = ':groupId',
        query: { page = 1, size = contacts.pageSize.default, searchTerm } = {},
      } = {}) => {
        const query = { groupid: groupId, page, search: searchTerm, size }
        return `${config.svc.url}/v1/organizations/${orgId}/contacts/?${toQuery(query)}`
      },
    },
    addContacts: {
      headers,
      method: 'POST',
      getPath: ({ orgId = 0 } = {}) => `${config.svc.url}/v1/organizations/${orgId}/groups/contacts`,
    },
    removeContacts: {
      headers,
      method: 'DELETE',
      getPath: ({ orgId = 0, groupId = ':groupId' } = {}) =>
        `${config.svc.url}/v1/organizations/${orgId}/groups/${groupId}/contacts`,
    },
  },
  creditCards: {
    getList: {
      headers,
      method: 'GET',
      getPath: ({ orgId = 0 } = {}) => `${config.svc.url}/v1/organizations/${orgId}/payments/creditcards/`,
    },
    saveItem: {
      headers,
      method: 'POST',
      getPath: ({ orgId = 0 } = {}) => `${config.svc.url}/v1/organizations/${orgId}/payments/creditcards/`,
    },
    deleteItem: {
      headers,
      method: 'DELETE',
      json: false,
      getPath: ({ orgId = 0, itemId = ':itemId' } = {}) =>
        `${config.svc.url}/v1/organizations/${orgId}/payments/creditcards/${itemId}/`,
    },
    chargeItem: {
      headers,
      method: 'POST',
      getPath: ({ orgId = 0, itemId = ':itemId' } = {}) =>
        `${config.svc.url}/v1/organizations/${orgId}/payments/creditcards/${itemId}/charge`,
    },
  },
  receipts: {
    getList: {
      headers,
      method: 'GET',
      getPath: ({ orgId = 0 } = {}) => `${config.svc.url}/v1/organizations/${orgId}/purchases.json`,
    },
  },
  invoices: {
    generateProforma: {
      headers,
      method: 'POST',
      jsonResponse: false,
      getPath: ({ orgId = 0 } = {}) => `${config.svc.url}/v1/organizations/${orgId}/banktransfer/`,
    },
  },
  files: {
    get: {
      headers,
      method: 'POST',
      getSignablePath: ({ orgId = 0, name = 'filename.ext' } = {}) => `/v1/organizations/${orgId}/files/${name}`,
      getPath: ({ signedPath = '', urlToken = ':urlToken' } = {}) => `${config.svc.url}${signedPath}?token=${urlToken}`,
    },
    getList: {
      headers,
      method: 'POST',
      getPath: ({ orgId = 0 } = {}) => `${config.svc.url}/v1/organizations/${orgId}/files/`,
    },
    save: {
      headers: (token) => ({
        headers: {
          ...omit(['Content-Type'], headers(token).headers),
        },
      }),
      method: 'POST',
      getPath: ({ orgId = 0, type = '' } = {}) => `${config.svc.url}/v1/organizations/${orgId}/files/${type}`,
    },
    delete: {
      headers,
      method: 'POST',
      getPath: ({ orgId = 0, name = 'filename.ext' } = {}) =>
        `${config.svc.url}/v1/organizations/${orgId}/files/${name}`,
    },
  },
  plan: {
    getList: {
      headers,
      method: 'GET',
      getPath: () => `${config.svc.url}/plans/`,
    },
    get: {
      headers,
      method: 'GET',
      getPath: ({ orgId = 0 } = {}) => `${config.svc.url}/v1/organizations/${orgId}/plan.json`,
    },
    post: {
      headers,
      method: 'POST',
      getPath: ({ orgId = 0 } = {}) => `${config.svc.url}/v1/organizations/${orgId}/plan.json`,
    },
    delete: {
      headers,
      method: 'DELETE',
      json: false,
      getPath: ({ orgId = 0 } = {}) => `${config.svc.url}/v1/organizations/${orgId}/plan.json`,
    },
  },
  billing: {
    get: {
      headers,
      method: 'GET',
      getPath: ({ orgId = 0 } = {}) => `${config.svc.url}/v1/organizations/${orgId}/billing.json`,
    },
  },
  balance: {
    get: {
      headers,
      method: 'GET',
      getPath: ({ orgId = 0 } = {}) => `${config.svc.url}/v1/organizations/${orgId}/balance.json`,
    },
    post: {
      headers,
      method: 'POST',
      getPath: ({ orgId = 0 } = {}) => `${config.svc.url}/v1/organizations/${orgId}/balance.json`,
    },
  },
  auditLog: {
    download: {
      headers,
      method: 'GET',
      json: false,
      getSignablePath: ({ orgId }) => `/v1/organizations/${orgId}/auditlog.csv`,
      getPath: ({ orgId = 0 } = {}) => `${config.svc.url}/v1/organizations/${orgId}/auditlog.csv`,
    },
  },
  cati: {
    getItem: {
      headers,
      method: 'GET',
      getPath: ({ orgId = 0, engId = ':engId', runId = ':runId' } = {}) =>
        `${config.svc.url}/v1/organizations/${orgId}/engagements/${engId}/runs/${runId}/`,
    },
    step: {
      headers,
      method: 'POST',
      getPath: ({ orgId = 0, engId = ':engId', runId = ':runId' } = {}) =>
        `${config.svc.url}/v1/organizations/${orgId}/engagements/${engId}/runs/${runId}/catistep`,
    },
    sub: {
      headers,
      method: 'GET',
      getPath: ({ orgId = 0, subId = ':subId' } = {}) => `${config.svc.url}/v1/organizations/${orgId}/subs/${subId}/`,
    },
    setStatus: {
      headers,
      method: 'POST',
      getPath: ({ orgId = 0, subId = ':subId' } = {}) => `${config.svc.url}/v1/organizations/${orgId}/subs/${subId}/`,
    },
  },
  exports: {
    getList: {
      headers,
      method: 'GET',
      getPath: ({ orgId = 0, query: { page = 1, size = exports.pageSize.default } = {} } = {}) => {
        const query = toQuery({ page, size })
        return `${config.svc.url}/v1/organizations/${orgId}/exports/?${query}`
      },
    },
    delete: {
      headers,
      method: 'DELETE',
      getPath: ({ orgId = 0, exportId = ':exportId' } = {}) =>
        `${config.svc.url}/v1/organizations/${orgId}/exports/${exportId}/`,
    },
  },
  apiKey: {
    getList: {
      headers,
      method: 'GET',
      getPath: () => `${config.svc.url}/v1/user/authtokens/`,
    },
    create: {
      headers,
      method: 'POST',
      getPath: () => `${config.svc.url}/v1/user/authtokens/`,
    },
    getItem: {
      headers,
      method: 'GET',
      getPath: ({ tokenId = ':tokenId' } = {}) => `${config.svc.url}/v1/user/authtokens/${tokenId}/`,
    },
    update: {
      headers,
      method: 'POST',
      getPath: ({ tokenId = ':tokenId' } = {}) => `${config.svc.url}/v1/user/authtokens/${tokenId}/`,
    },
    delete: {
      headers,
      method: 'DELETE',
      getPath: ({ tokenId = ':tokenId' } = {}) => `${config.svc.url}/v1/user/authtokens/${tokenId}/`,
    },
  },
}
