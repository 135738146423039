import { esApi as baseApi, GetOrgInvoiceListApiResponse } from '@/__generated/es-api'

export const esApi = baseApi.enhanceEndpoints({
  addTagTypes: ['Contact', 'Organization', 'OrgDid', 'OrgGroup'],
  endpoints: {
    getContact: {
      providesTags: ['Contact'],
    },
    getOrgDid: {
      providesTags: ['OrgDid'],
    },
    getOrgDids: {
      providesTags: ['OrgDid'],
    },
    getOrgGroup: {
      providesTags: ['OrgGroup'],
    },
    getOrgGroupsList: {
      providesTags: ['OrgGroup'],
    },
    getOrgInvoiceList: {
      transformResponse: (response: GetOrgInvoiceListApiResponse) => ({
        ...response,
        months: [...(response.months || [])].reverse(),
      }),
    },
    getOrganization: {
      providesTags: ['Organization'],
    },
    updateContact: {
      invalidatesTags: ['Contact'],
    },
    updateOrganization: {
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled

          dispatch(
            baseApi.util.updateQueryData('getOrganization', { orgId: arg.orgId }, (data) => ({
              ...data,
              ...arg.body,
            })),
          )
        } catch {
          // do nothing
        }
      },
    },
    addOrgGroupsContacts: {
      invalidatesTags: ['OrgGroup'],
    },
    createOrgGroup: {
      invalidatesTags: ['OrgGroup'],
    },
    updateOrgGroup: {
      invalidatesTags: ['OrgGroup'],
    },
    deleteOrgGroup: {
      invalidatesTags: ['OrgGroup'],
    },
  },
})

export type ErrorResponse = {
  status: number
  data: {
    error: string
    errorCode?: string // can be undefined for older errors
  }
}

const isErrorResponse = (error: unknown): error is ErrorResponse => {
  return !!error && typeof error === 'object' && 'status' in error && 'data' in error && 'error' in (error.data as any)
}

export const getErrorMessage = (error: unknown, fallback: string) => {
  if (!isErrorResponse(error)) {
    return fallback
  }
  return error.data.error
}
