import React from 'react'
import Button from '@mui/material/Button'
import Loading from '@/app/component/guard/loading'
import { Form } from '@/app/component/form'
import { useUpdateGroup } from '@/app/module/groups/hooks/use-update-group'
import { useGetGroup } from '@/app/module/groups/hooks/use-get-group'
import { useDeleteGroup } from '@/app/module/groups/hooks/use-delete-group'
import { Box } from '@mui/material'
import DeleteDialog from '@/app/component/atom/delete-dialog'
import { GroupQuery } from '@/app/module/groups/types'
import DeleteProtectionToggle from '@/app/module/contacts/component/form/delete-protection-toggle'
import { FormContainer } from './common'
import { fields, schema } from './specs'

type Props = {
  groupId: number
  orgId: number
  query: GroupQuery
  token: string

  getGroups: (payload: { token: string; orgId: number; query: GroupQuery }) => void
  onExit: () => void
}

const UpdateGroupForm: React.FC<Props> = ({ groupId, orgId, query, token, getGroups, onExit }) => {
  const [deleteConfirm, setDeleteConfirm] = React.useState(false)
  const [deleteGroup, { isLoading: isDeleting }] = useDeleteGroup()
  const [updateGroup, { isLoading: isUpdating }] = useUpdateGroup()

  const { data, isFetching, isLoading, isUninitialized } = useGetGroup(groupId)

  const [noAutodelete, setNoAutodelete] = React.useState(data?.noAutodelete || false)
  const [changed, setChanged] = React.useState(false)
  React.useEffect(() => {
    setNoAutodelete(data?.noAutodelete || false)
  }, [data?.noAutodelete])
  const handleNoAutodeleteChange = React.useCallback((value: boolean) => {
    setNoAutodelete(value)
    setChanged(true)
  }, [])

  const handleSubmit = React.useCallback(
    async (values: { name: string }) => {
      const success = await updateGroup(groupId, { name: values.name, description: '', noAutodelete })
      if (!success) {
        return
      }
      getGroups({ token, orgId, query }) // FIXME: this should be removed when we move getGroups to generated API
      onExit()
    },
    [groupId, noAutodelete, orgId, query, token, getGroups, onExit, updateGroup],
  )

  const handleDeleteGroup = React.useCallback(async () => {
    setDeleteConfirm(false)
    const success = await deleteGroup(groupId)
    if (!success) {
      return
    }
    getGroups({ token, orgId, query }) // FIXME: this should be removed when we move getGroups to generated API
    onExit()
  }, [groupId, orgId, query, token, deleteGroup, getGroups, onExit])

  const loading = isDeleting || isFetching || isLoading || isUninitialized || isUpdating

  const defaultValues = React.useMemo(
    () => ({
      name: data?.name || '',
    }),
    [data],
  )

  return (
    <Loading isLoading={loading} testId="group-item">
      <FormContainer>
        <Form
          defaultValues={defaultValues}
          fields={fields}
          name="update-group"
          schema={schema}
          shouldReset
          onSubmit={handleSubmit}
          renderActions={({ isDirty, isValid }) => (
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 3 }}>
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => setDeleteConfirm(true)}
                disabled={!data || loading}
              >
                Delete group
              </Button>

              <Button
                color="primary"
                type="submit"
                variant="contained"
                disabled={loading || (!changed && !isDirty) || !isValid}
              >
                Save
              </Button>
            </Box>
          )}
        >
          <DeleteProtectionToggle checked={noAutodelete} isGroup onChange={handleNoAutodeleteChange} />
        </Form>
      </FormContainer>
      <DeleteDialog
        text="Are you sure you want to delete the selected group? (Contacts inside the group will not be deleted)"
        isOpen={deleteConfirm}
        onClose={() => setDeleteConfirm(false)}
        onConfirm={handleDeleteGroup}
      />
    </Loading>
  )
}

export default UpdateGroupForm
