import { useAppDispatch } from '@/app/store/redux-hooks'
import { useSelector } from 'react-redux'
import { esApi, getErrorMessage } from '@/app/store/api'
import { useCallback } from 'react'
import { UpdateOrgGroupApiArg } from '@/__generated/es-api'
import { createNotification } from '@/app/module/notifications/store/actions'
import { ERROR_SAVE_GROUPS_ITEM_SERVER } from '@/app/module/groups/definitions'
import { selectOrgId } from '@/app/module/user/store/selectors'

export const useUpdateGroup = () => {
  const dispatch = useAppDispatch()
  const orgId = useSelector(selectOrgId)
  const [update, res] = esApi.endpoints.updateOrgGroup.useMutation()

  const updateGroup = useCallback(
    async (groupId: number, payload: Omit<UpdateOrgGroupApiArg['body'], 'type'>) => {
      try {
        await update({
          orgId: orgId.toString(),
          groupId: groupId.toString(),
          body: {
            ...payload,
            type: 'NORMAL',
          },
        }).unwrap()
        dispatch(
          createNotification({
            'update-group-success': {
              type: 'success',
              message: 'Group has been saved successfully',
            },
          }),
        )
        return true
      } catch (error) {
        dispatch(
          createNotification({
            'update-group-failure': {
              type: 'error',
              message: getErrorMessage(error, ERROR_SAVE_GROUPS_ITEM_SERVER),
            },
          }),
        )
        return false
      }
    },
    [orgId, dispatch, update],
  )

  return [updateGroup, res] as const
}
