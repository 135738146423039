import { filter as filterArray, find, map, pathOr, pick, propEq, values } from 'ramda'
import { createSelector } from 'reselect'
import config from '@/config'
import { INPUT_DEFAULT_MAX_LENGTH } from '@/app/definitions'
import { selectCustomFields } from '@/app/module/custom-fields/store/selectors'
import { selectGroupValues, partSelectPage as selectGroupsPage } from '@/app/module/groups/store/selectors'
import { selectSegmentValues } from '@/app/module/segments/store/selectors'
import { selectUserCountry, selectUserTimezone } from '@/app/module/user/store/selectors'
import { selectCountries, selectLanguages } from '@/app/module/utils/store/selectors'
import { CONTACTS_CUSTOM_FIELD_CHAR_LIMIT, CONTACTS_DEFAULT_FIELD_CHAR_LIMIT } from '@/app/module/contacts/definitions'

const GROUP_TYPE = pathOr({}, ['modules', 'groups', 'type'], config)
const SEGMENT_TYPE = pathOr({}, ['modules', 'segments', 'type'], config)

export const partSelectHeaders = () => [
  {
    label: 'First Name',
    key: 'firstName',
    fields: ['firstName'],
    visible: true,
  },
  {
    label: 'Last Name',
    key: 'lastName',
    fields: ['lastName'],
    visible: true,
  },
  {
    label: 'Phone number',
    key: 'fullPhoneNumber',
    display: 'phone',
    fields: ['fullPhoneNumber'],
    visible: true,
  },
  {
    label: 'Language',
    key: 'language',
    fields: ['language'],
    editable: false,
    visible: true,
  },
]

const partSelectAdditionalHeaders = () => [
  {
    label: 'Created',
    key: 'createdAt',
    display: 'datetime',
    fields: ['createdAt'],
    editable: false,
    visible: true,
  },
  {
    label: 'Updated',
    key: 'updatedAt',
    display: 'datetime',
    fields: ['updatedAt'],
    editable: false,
    visible: true,
  },
  {
    label: 'Last Used',
    key: 'lastUseAt',
    display: 'datetime',
    fields: ['lastUseAt'],
    editable: false,
    visible: true,
  },
  {
    label: 'Carrier',
    key: 'carrier',
    fields: ['carrier'],
    editable: false,
    visible: true,
  },
]

const partSelectAllHeaders = createSelector(
  partSelectHeaders,
  partSelectAdditionalHeaders,
  (headers, additionalHeaders) => [...headers, ...additionalHeaders],
)

export const selectContactsItemConfig = createSelector(
  selectUserTimezone,
  selectCustomFields,
  selectLanguages,
  selectCountries,
  selectUserCountry,
  (state) => state.isNewContact,
  (tz, customFields, languageList, countriesList, defaultCountry, isNewContact = false) => {
    const specs = {
      id: {
        type: 'hidden',
        display: 'hidden',
        label: 'engageSPARK Contact ID',
      },
      groups: {
        type: 'hidden',
        display: 'hidden',
        label: 'Groups',
      },
      firstName: {
        type: 'text',
        display: 'text',
        editable: true,
        label: 'First name',
        maxLength: CONTACTS_DEFAULT_FIELD_CHAR_LIMIT,
      },
      lastName: {
        type: 'text',
        display: 'text',
        editable: true,
        label: 'Last name',
        maxLength: CONTACTS_DEFAULT_FIELD_CHAR_LIMIT,
      },
      fullPhoneNumber: {
        type: 'phone',
        display: 'phone',
        editable: true,
        label: 'Phone number',
        countries: countriesList,
        maxLength: INPUT_DEFAULT_MAX_LENGTH,
        defaultCountryCode: pathOr('', ['value'], defaultCountry),
      },
      language: {
        type: 'autocomplete',
        display: 'text',
        editable: true,
        restricted: false,
        label: 'Language',
        values: languageList,
        icon: 'language',
        maxLength: CONTACTS_DEFAULT_FIELD_CHAR_LIMIT,
      },
      ...customFields.reduce(
        (acc, { id: customFieldId, name, fieldType = 'text', editable = true }) => ({
          ...acc,
          [`customFields.${customFieldId}`]: {
            name: `customFields.${customFieldId}`,
            label: name,
            display: fieldType === 'audio' ? 'text' : fieldType,
            type: fieldType === 'audio' ? 'text' : fieldType,
            editable: fieldType === 'audio' ? false : editable,
            maxLength: CONTACTS_CUSTOM_FIELD_CHAR_LIMIT,
          },
        }),
        {},
      ),
      createdAt: {
        type: 'datetime',
        display: 'datetime',
        label: 'Created',
        editable: false,
        tz,
      },
      updatedAt: {
        type: 'datetime',
        display: 'datetime',
        label: 'Updated',
        editable: false,
        tz,
      },
      lastUseAt: {
        type: 'datetime',
        display: 'datetime',
        label: 'Last Used',
        editable: false,
        tz,
      },
      carrier: {
        type: 'text',
        display: 'text',
        label: 'Carrier',
        editable: false,
      },
    }

    if (isNewContact) {
      delete specs.carrier
      delete specs.createdAt
      delete specs.updatedAt
      delete specs.lastUseAt
    }

    return specs
  },
)

export const selectPersistentHeaders = pathOr([], ['contacts', 'list', 'headers'])

export const selectHeaders = createSelector(
  partSelectHeaders,
  partSelectAdditionalHeaders,
  selectCustomFields,
  selectPersistentHeaders,
  (state) => state.isNewContact,
  (headers, additionalHeaders, customFields, persistentHeaders, isNewContact = false) => {
    let aggregatedHeader = [
      ...headers,
      ...customFields.map(({ id, name }) => ({
        label: name,
        key: id,
        editable: true,
        fields: [`customFields.${id}`],
        visible: true,
      })),
      ...additionalHeaders,
    ]
    if (isNewContact) {
      aggregatedHeader = aggregatedHeader.filter(
        (header) => ['createdAt', 'updatedAt', 'lastUseAt', 'carrier'].indexOf(header.key) === -1,
      )
    }

    if (persistentHeaders.length > 0) {
      const result = []
      persistentHeaders.map((header) => {
        const foundHeader = find(propEq('key', header.key), aggregatedHeader)
        if (foundHeader) {
          aggregatedHeader = filterArray((el) => el.key !== foundHeader.key, aggregatedHeader)
          result.push({
            ...header,
            label: foundHeader.label,
          })
        }
        return foundHeader
      })

      return [...result, ...aggregatedHeader]
    }

    return aggregatedHeader
  },
)

export const selectGroupHeaders = () => [
  {
    title: 'Group',
    fields: ['group'],
  },
]

const partSelectContacts = pathOr({}, ['contacts', 'list', 'data', 'values'])

const partSelectPageValues = pathOr({}, ['contacts', 'list', 'data', 'pages'])

const partSelectSearchValues = pathOr({}, ['contacts', 'list', 'data', 'searches', 'data'])
const partSelectSearchTerm = pathOr(false, ['contacts', 'list', 'data', 'searches', 'active'])

const partSelectFilterValues = pathOr({}, ['contacts', 'list', 'data', 'filters', 'data'])
const partSelectFilterApplied = pathOr(false, ['contacts', 'list', 'data', 'filter'])

const partSelectPage = pathOr('1', ['contacts', 'list', 'data', 'page'])

const partSelectContactsItem = pathOr({}, ['contacts', 'item', 'data'])

export const selectOrdered = createSelector(
  partSelectPage,
  partSelectPageValues,
  partSelectSearchValues,
  partSelectSearchTerm,
  partSelectFilterValues,
  partSelectFilterApplied,
  (page, pages, searches, searchTerm, filters, filterApplied) => {
    if (filterApplied) {
      return pathOr([], [`${filterApplied.name}.${filterApplied.value}`, page], filters)
    }
    if (searchTerm) {
      return pathOr([], [searchTerm, page], searches)
    }
    return pathOr([], [page], pages)
  },
)

export const partSelectContactsPage = createSelector(selectOrdered, partSelectContacts, pick)

const getValues =
  ({ headers, customFields }) =>
  (contactsItem) => ({
    ...headers.reduce(
      (acc, { fields }) => ({
        ...acc,
        ...fields.reduce(
          (subAcc, key) => ({
            ...subAcc,
            [key]: contactsItem[key],
          }),
          {},
        ),
      }),
      {},
    ),
    ...customFields.reduce(
      (acc, { id }) => ({
        ...acc,
        [`customFields.${id}`]: pathOr('', ['customFields', id], contactsItem),
      }),
      {},
    ),
  })

export const selectContactsValues = createSelector(
  partSelectAllHeaders,
  partSelectContactsPage,
  selectCustomFields,
  (headers, contacts, customFields) => map(getValues({ headers, customFields }))(contacts),
)

export const selectContactsItemValues = createSelector(
  partSelectAllHeaders,
  partSelectContactsItem,
  selectCustomFields,
  (headers, contactsItem, customFields) => getValues({ headers, customFields })(contactsItem),
)

export const selectContactFilters = createSelector(
  partSelectFilterApplied,
  selectGroupValues,
  selectSegmentValues,
  (filter, groups, segments) => ({
    [GROUP_TYPE.name]: {
      type: 'radio',
      name: GROUP_TYPE.label,
      value: filter && filter.name === GROUP_TYPE.name ? filter.value : '',
      values: groups,
    },
    [SEGMENT_TYPE.name]: {
      type: 'radio',
      name: SEGMENT_TYPE.label,
      value: filter && filter.name === SEGMENT_TYPE.name ? filter.value : '',
      values: segments,
    },
  }),
)

export const selectGroupsConfig = createSelector(selectGroupsPage, (groups) => ({
  id: {
    type: 'hidden',
    display: 'hidden',
  },
  group: {
    type: 'select',
    editable: 'true',
    values: values(groups).map(({ id, name }) => ({ label: name, value: id.toString() })),
    label: 'Group',
  },
}))

export const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const selectNewContactValues = ({ contacts }) =>
  map(
    (contact) => ({
      ...contact,
      ...(contact.customFields && {
        ...contact.customFields,
      }),
      actionId: contact.id,
    }),
    pathOr([], ['list', 'data', 'values'], contacts),
  )
