import { esClient as api } from '@/app/store/empty-api'

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAirtimePricing: build.query<GetAirtimePricingApiResponse, GetAirtimePricingApiArg>({
      query: (queryArg) => ({ url: `/airtime/pricing`, params: { country: queryArg.country } }),
    }),
    authSignin: build.mutation<AuthSigninApiResponse, AuthSigninApiArg>({
      query: (queryArg) => ({ url: `/v1/auth/signin`, method: 'POST', body: queryArg.body }),
    }),
    authTotp: build.mutation<AuthTotpApiResponse, AuthTotpApiArg>({
      query: (queryArg) => ({ url: `/v1/auth/totp`, method: 'POST', body: queryArg.body }),
    }),
    authPasswordReset: build.mutation<AuthPasswordResetApiResponse, AuthPasswordResetApiArg>({
      query: (queryArg) => ({ url: `/v1/auth/passwordreset`, method: 'POST', body: queryArg.body }),
    }),
    authPasswordResetChange: build.mutation<AuthPasswordResetChangeApiResponse, AuthPasswordResetChangeApiArg>({
      query: (queryArg) => ({ url: `/v1/auth/passwordresetchange`, method: 'POST', body: queryArg.body }),
    }),
    getUser: build.query<GetUserApiResponse, GetUserApiArg>({
      query: () => ({ url: `/v1/user/` }),
    }),
    sendSmsContact: build.mutation<SendSmsContactApiResponse, SendSmsContactApiArg>({
      query: (queryArg) => ({ url: `/v1/sms/contact`, method: 'POST', body: queryArg.body }),
    }),
    sendSmsPhonenumber: build.mutation<SendSmsPhonenumberApiResponse, SendSmsPhonenumberApiArg>({
      query: (queryArg) => ({ url: `/v1/sms/phonenumber`, method: 'POST', body: queryArg.body }),
    }),
    createOrganization: build.mutation<CreateOrganizationApiResponse, CreateOrganizationApiArg>({
      query: (queryArg) => ({ url: `/v1/organizations/`, method: 'POST', body: queryArg.body }),
    }),
    getOrganization: build.query<GetOrganizationApiResponse, GetOrganizationApiArg>({
      query: (queryArg) => ({ url: `/v1/organizations/${queryArg.orgId}/` }),
    }),
    updateOrganization: build.mutation<UpdateOrganizationApiResponse, UpdateOrganizationApiArg>({
      query: (queryArg) => ({ url: `/v1/organizations/${queryArg.orgId}/`, method: 'POST', body: queryArg.body }),
    }),
    getOrgBalance: build.query<GetOrgBalanceApiResponse, GetOrgBalanceApiArg>({
      query: (queryArg) => ({ url: `/v1/organizations/${queryArg.orgId}/balance.json` }),
    }),
    getOrgBilling: build.query<GetOrgBillingApiResponse, GetOrgBillingApiArg>({
      query: (queryArg) => ({
        url: `/v1/organizations/${queryArg.orgId}/billing.json`,
        params: { start: queryArg.start, end: queryArg.end },
      }),
    }),
    getOrgInvoiceList: build.query<GetOrgInvoiceListApiResponse, GetOrgInvoiceListApiArg>({
      query: (queryArg) => ({ url: `/v1/organizations/${queryArg.orgId}/invoices/` }),
    }),
    getOrgPurchaseList: build.query<GetOrgPurchaseListApiResponse, GetOrgPurchaseListApiArg>({
      query: (queryArg) => ({ url: `/v1/organizations/${queryArg.orgId}/purchases.json` }),
    }),
    getOrgUserList: build.query<GetOrgUserListApiResponse, GetOrgUserListApiArg>({
      query: (queryArg) => ({ url: `/v1/organizations/${queryArg.orgId}/users` }),
    }),
    getOrgUser: build.query<GetOrgUserApiResponse, GetOrgUserApiArg>({
      query: (queryArg) => ({ url: `/v1/organizations/${queryArg.orgId}/users/${queryArg.userId}` }),
    }),
    updateOrgUser: build.mutation<UpdateOrgUserApiResponse, UpdateOrgUserApiArg>({
      query: (queryArg) => ({
        url: `/v1/organizations/${queryArg.orgId}/users/${queryArg.userId}`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    deleteOrgUser: build.mutation<DeleteOrgUserApiResponse, DeleteOrgUserApiArg>({
      query: (queryArg) => ({ url: `/v1/organizations/${queryArg.orgId}/users/${queryArg.userId}`, method: 'DELETE' }),
    }),
    getSmsList: build.query<GetSmsListApiResponse, GetSmsListApiArg>({
      query: (queryArg) => ({
        url: `/v1/organizations/${queryArg.orgId}/messages/`,
        params: {
          after: queryArg.after,
          limit: queryArg.limit,
          dir: queryArg.dir,
          failed: queryArg.failed,
          search: queryArg.search,
          startDate: queryArg.startDate,
          endDate: queryArg.endDate,
        },
      }),
    }),
    getSms: build.query<GetSmsApiResponse, GetSmsApiArg>({
      query: (queryArg) => ({ url: `/v1/organizations/${queryArg.orgId}/messages/${queryArg.messageId}/` }),
    }),
    createCampaign: build.mutation<CreateCampaignApiResponse, CreateCampaignApiArg>({
      query: (queryArg) => ({
        url: `/v1/organizations/${queryArg.orgId}/engagements/`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getCampaign: build.query<GetCampaignApiResponse, GetCampaignApiArg>({
      query: (queryArg) => ({ url: `/v1/organizations/${queryArg.orgId}/engagements/${queryArg.campaignId}/` }),
    }),
    updateCampaign: build.mutation<UpdateCampaignApiResponse, UpdateCampaignApiArg>({
      query: (queryArg) => ({
        url: `/v1/organizations/${queryArg.orgId}/engagements/${queryArg.campaignId}/`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    deleteCampaign: build.mutation<DeleteCampaignApiResponse, DeleteCampaignApiArg>({
      query: (queryArg) => ({
        url: `/v1/organizations/${queryArg.orgId}/engagements/${queryArg.campaignId}/`,
        method: 'DELETE',
      }),
    }),
    subscribeContact: build.mutation<SubscribeContactApiResponse, SubscribeContactApiArg>({
      query: (queryArg) => ({
        url: `/v1/organizations/${queryArg.orgId}/engagements/${queryArg.campaignId}/subscribe`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    unsubscribeContact: build.mutation<UnsubscribeContactApiResponse, UnsubscribeContactApiArg>({
      query: (queryArg) => ({
        url: `/v1/organizations/${queryArg.orgId}/engagements/${queryArg.campaignId}/unsubscribe`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    createSpeechPreview: build.mutation<CreateSpeechPreviewApiResponse, CreateSpeechPreviewApiArg>({
      query: (queryArg) => ({ url: `/v1/organizations/${queryArg.orgId}/say`, method: 'POST', body: queryArg.body }),
    }),
    sendOrgTopup: build.mutation<SendOrgTopupApiResponse, SendOrgTopupApiArg>({
      query: (queryArg) => ({ url: `/v1/organizations/${queryArg.orgId}/topup/`, method: 'POST', body: queryArg.body }),
    }),
    getOrgTopupsList: build.query<GetOrgTopupsListApiResponse, GetOrgTopupsListApiArg>({
      query: (queryArg) => ({
        url: `/v1/organizations/${queryArg.orgId}/topups/`,
        params: {
          after: queryArg.after,
          limit: queryArg.limit,
          failed: queryArg.failed,
          search: queryArg.search,
          startDate: queryArg.startDate,
          endDate: queryArg.endDate,
        },
      }),
    }),
    getOrgTopups: build.query<GetOrgTopupsApiResponse, GetOrgTopupsApiArg>({
      query: (queryArg) => ({ url: `/v1/organizations/${queryArg.orgId}/topups/${queryArg.topupId}/` }),
    }),
    getOrgDids: build.query<GetOrgDidsApiResponse, GetOrgDidsApiArg>({
      query: (queryArg) => ({ url: `/v1/organizations/${queryArg.orgId}/phonenumbers/` }),
    }),
    getOrgDid: build.query<GetOrgDidApiResponse, GetOrgDidApiArg>({
      query: (queryArg) => ({ url: `/v1/organizations/${queryArg.orgId}/phonenumbers/${queryArg.didId}/` }),
    }),
    postOrgDid: build.mutation<PostOrgDidApiResponse, PostOrgDidApiArg>({
      query: (queryArg) => ({
        url: `/v1/organizations/${queryArg.orgId}/phonenumbers/${queryArg.didId}/`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    postDidRegisterWhatsApp: build.mutation<PostDidRegisterWhatsAppApiResponse, PostDidRegisterWhatsAppApiArg>({
      query: (queryArg) => ({
        url: `/v1/organizations/${queryArg.orgId}/phonenumbers/${queryArg.didId}/registerwhatsapp`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    createOrgDidHook: build.mutation<CreateOrgDidHookApiResponse, CreateOrgDidHookApiArg>({
      query: (queryArg) => ({
        url: `/v1/organizations/${queryArg.orgId}/phonenumbers/${queryArg.didId}/hooks/`,
        method: 'POST',
        body: queryArg.schemaInboundHookPayload,
      }),
    }),
    updateOrgDidHook: build.mutation<UpdateOrgDidHookApiResponse, UpdateOrgDidHookApiArg>({
      query: (queryArg) => ({
        url: `/v1/organizations/${queryArg.orgId}/phonenumbers/${queryArg.didId}/hooks/${queryArg.hookId}/`,
        method: 'POST',
        body: queryArg.schemaInboundHook,
      }),
    }),
    deleteOrgDidHook: build.mutation<DeleteOrgDidHookApiResponse, DeleteOrgDidHookApiArg>({
      query: (queryArg) => ({
        url: `/v1/organizations/${queryArg.orgId}/phonenumbers/${queryArg.didId}/hooks/${queryArg.hookId}/`,
        method: 'DELETE',
      }),
    }),
    createOrgDidCallHook: build.mutation<CreateOrgDidCallHookApiResponse, CreateOrgDidCallHookApiArg>({
      query: (queryArg) => ({
        url: `/v1/organizations/${queryArg.orgId}/phonenumbers/${queryArg.didId}/callhooks/`,
        method: 'POST',
        body: queryArg.schemaInboundHookPayload,
      }),
    }),
    updateOrgDidCallHook: build.mutation<UpdateOrgDidCallHookApiResponse, UpdateOrgDidCallHookApiArg>({
      query: (queryArg) => ({
        url: `/v1/organizations/${queryArg.orgId}/phonenumbers/${queryArg.didId}/callhooks/${queryArg.hookId}/`,
        method: 'POST',
        body: queryArg.schemaInboundHook,
      }),
    }),
    deleteOrgDidCallHook: build.mutation<DeleteOrgDidCallHookApiResponse, DeleteOrgDidCallHookApiArg>({
      query: (queryArg) => ({
        url: `/v1/organizations/${queryArg.orgId}/phonenumbers/${queryArg.didId}/callhooks/${queryArg.hookId}/`,
        method: 'DELETE',
      }),
    }),
    createOrgDidWaHook: build.mutation<CreateOrgDidWaHookApiResponse, CreateOrgDidWaHookApiArg>({
      query: (queryArg) => ({
        url: `/v1/organizations/${queryArg.orgId}/phonenumbers/${queryArg.didId}/wahooks/`,
        method: 'POST',
        body: queryArg.schemaInboundHookPayload,
      }),
    }),
    updateOrgDidWaHook: build.mutation<UpdateOrgDidWaHookApiResponse, UpdateOrgDidWaHookApiArg>({
      query: (queryArg) => ({
        url: `/v1/organizations/${queryArg.orgId}/phonenumbers/${queryArg.didId}/wahooks/${queryArg.hookId}/`,
        method: 'POST',
        body: queryArg.schemaInboundHook,
      }),
    }),
    deleteOrgDidWaHook: build.mutation<DeleteOrgDidWaHookApiResponse, DeleteOrgDidWaHookApiArg>({
      query: (queryArg) => ({
        url: `/v1/organizations/${queryArg.orgId}/phonenumbers/${queryArg.didId}/wahooks/${queryArg.hookId}/`,
        method: 'DELETE',
      }),
    }),
    getOrgPhonenumber: build.query<GetOrgPhonenumberApiResponse, GetOrgPhonenumberApiArg>({
      query: (queryArg) => ({
        url: `/v1/organizations/${queryArg.orgId}/phonenumber`,
        params: { phonenumber: queryArg.phonenumber, region: queryArg.region },
      }),
    }),
    createContact: build.mutation<CreateContactApiResponse, CreateContactApiArg>({
      query: (queryArg) => ({
        url: `/v1/organizations/${queryArg.orgId}/contacts/`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getContact: build.query<GetContactApiResponse, GetContactApiArg>({
      query: (queryArg) => ({ url: `/v1/organizations/${queryArg.orgId}/contacts/${queryArg.contactId}/` }),
    }),
    updateContact: build.mutation<UpdateContactApiResponse, UpdateContactApiArg>({
      query: (queryArg) => ({
        url: `/v1/organizations/${queryArg.orgId}/contacts/${queryArg.contactId}/`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    getOrgGroupsList: build.query<GetOrgGroupsListApiResponse, GetOrgGroupsListApiArg>({
      query: (queryArg) => ({
        url: `/v1/organizations/${queryArg.orgId}/groups/`,
        params: { page: queryArg.page, size: queryArg.size, sortBy: queryArg.sortBy, direction: queryArg.direction },
      }),
    }),
    createOrgGroup: build.mutation<CreateOrgGroupApiResponse, CreateOrgGroupApiArg>({
      query: (queryArg) => ({
        url: `/v1/organizations/${queryArg.orgId}/groups/`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getOrgGroup: build.query<GetOrgGroupApiResponse, GetOrgGroupApiArg>({
      query: (queryArg) => ({ url: `/v1/organizations/${queryArg.orgId}/groups/${queryArg.groupId}/` }),
    }),
    updateOrgGroup: build.mutation<UpdateOrgGroupApiResponse, UpdateOrgGroupApiArg>({
      query: (queryArg) => ({
        url: `/v1/organizations/${queryArg.orgId}/groups/${queryArg.groupId}/`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    deleteOrgGroup: build.mutation<DeleteOrgGroupApiResponse, DeleteOrgGroupApiArg>({
      query: (queryArg) => ({
        url: `/v1/organizations/${queryArg.orgId}/groups/${queryArg.groupId}/`,
        method: 'DELETE',
      }),
    }),
    addOrgGroupsContacts: build.mutation<AddOrgGroupsContactsApiResponse, AddOrgGroupsContactsApiArg>({
      query: (queryArg) => ({
        url: `/v1/organizations/${queryArg.orgId}/groups/contacts/`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    deleteOrgGroupContacts: build.mutation<DeleteOrgGroupContactsApiResponse, DeleteOrgGroupContactsApiArg>({
      query: (queryArg) => ({
        url: `/v1/organizations/${queryArg.orgId}/groups/${queryArg.groupId}/contacts`,
        method: 'DELETE',
        body: queryArg.body,
      }),
    }),
    getOrgCustomfields: build.query<GetOrgCustomfieldsApiResponse, GetOrgCustomfieldsApiArg>({
      query: (queryArg) => ({ url: `/v1/organizations/${queryArg.orgId}/customfields/` }),
    }),
    createOrgCustomfield: build.mutation<CreateOrgCustomfieldApiResponse, CreateOrgCustomfieldApiArg>({
      query: (queryArg) => ({
        url: `/v1/organizations/${queryArg.orgId}/customfields/`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    updateOrgCustomfield: build.mutation<UpdateOrgCustomfieldApiResponse, UpdateOrgCustomfieldApiArg>({
      query: (queryArg) => ({
        url: `/v1/organizations/${queryArg.orgId}/customfields/${queryArg.customfieldId}`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    deleteOrgCustomfield: build.mutation<DeleteOrgCustomfieldApiResponse, DeleteOrgCustomfieldApiArg>({
      query: (queryArg) => ({
        url: `/v1/organizations/${queryArg.orgId}/customfields/${queryArg.customfieldId}`,
        method: 'DELETE',
      }),
    }),
    createOrgReport: build.mutation<CreateOrgReportApiResponse, CreateOrgReportApiArg>({
      query: (queryArg) => ({ url: `/v1/organizations/${queryArg.orgId}/report`, method: 'POST', body: queryArg.body }),
    }),
    getExportsList: build.query<GetExportsListApiResponse, GetExportsListApiArg>({
      query: (queryArg) => ({
        url: `/v1/organizations/${queryArg.orgId}/exports/`,
        params: { page: queryArg.page, size: queryArg.size },
      }),
    }),
    getExport: build.query<GetExportApiResponse, GetExportApiArg>({
      query: (queryArg) => ({ url: `/v1/organizations/${queryArg.orgId}/exports/${queryArg.exportId}/` }),
    }),
    getExportsFile: build.query<GetExportsFileApiResponse, GetExportsFileApiArg>({
      query: (queryArg) => ({
        url: `/v1/organizations/${queryArg.orgId}/exports/${queryArg.exportId}/${queryArg.filename}`,
      }),
    }),
    getFileList: build.query<GetFileListApiResponse, GetFileListApiArg>({
      query: (queryArg) => ({
        url: `/v1/organizations/${queryArg.orgId}/files/`,
        params: { category: queryArg.category },
      }),
    }),
    uploadFile: build.mutation<UploadFileApiResponse, UploadFileApiArg>({
      query: (queryArg) => ({ url: `/v1/organizations/${queryArg.orgId}/files/`, method: 'POST', body: queryArg.body }),
    }),
    downloadFile: build.query<DownloadFileApiResponse, DownloadFileApiArg>({
      query: (queryArg) => ({ url: `/v1/organizations/${queryArg.orgId}/files/${queryArg.filename}` }),
    }),
    updateFile: build.mutation<UpdateFileApiResponse, UpdateFileApiArg>({
      query: (queryArg) => ({
        url: `/v1/organizations/${queryArg.orgId}/files/${queryArg.filename}`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    deleteFile: build.mutation<DeleteFileApiResponse, DeleteFileApiArg>({
      query: (queryArg) => ({
        url: `/v1/organizations/${queryArg.orgId}/files/${queryArg.filename}`,
        method: 'DELETE',
      }),
    }),
    transcribeFile: build.mutation<TranscribeFileApiResponse, TranscribeFileApiArg>({
      query: (queryArg) => ({
        url: `/v1/organizations/${queryArg.orgId}/transcribe`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getSnippetList: build.query<GetSnippetListApiResponse, GetSnippetListApiArg>({
      query: (queryArg) => ({
        url: `/v1/organizations/${queryArg.orgId}/snippets/`,
        params: { all: queryArg.all, category: queryArg.category },
      }),
    }),
    createSnippet: build.mutation<CreateSnippetApiResponse, CreateSnippetApiArg>({
      query: (queryArg) => ({
        url: `/v1/organizations/${queryArg.orgId}/snippets/`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    getSnippet: build.query<GetSnippetApiResponse, GetSnippetApiArg>({
      query: (queryArg) => ({ url: `/v1/organizations/${queryArg.orgId}/snippets/${queryArg.snippetId}` }),
    }),
    updateSnippet: build.mutation<UpdateSnippetApiResponse, UpdateSnippetApiArg>({
      query: (queryArg) => ({
        url: `/v1/organizations/${queryArg.orgId}/snippets/${queryArg.snippetId}`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    deleteSnippet: build.mutation<DeleteSnippetApiResponse, DeleteSnippetApiArg>({
      query: (queryArg) => ({
        url: `/v1/organizations/${queryArg.orgId}/snippets/${queryArg.snippetId}`,
        method: 'DELETE',
      }),
    }),
  }),
  overrideExisting: false,
})
export { injectedRtkApi as esApi }
export type GetAirtimePricingApiResponse = /** status 200 Airtime pricing for the country */ SchemaPricing
export type GetAirtimePricingApiArg = {
  /** A two letter country code (ISO 3166-1 alpha-2), e.g. DE, TH */
  country: string
}
export type AuthSigninApiResponse = /** status 200 Signin successful */ {
  /** Token you can set in Authentication header */
  access?: string
  /** If this is true the session cannot be used yet, 2FA must be checked first, with the /auth/totp endpoint. */
  totpEnabled?: boolean
  /** The token is valid until this time. */
  validUntil?: string
}
export type AuthSigninApiArg = {
  body: {
    /** Email address. */
    username: string
    /** Password. */
    password: string
    /** Recaptcha token. */
    verify_token: string
  }
}
export type AuthTotpApiResponse = /** status 200 Signin successful */ {
  /** New access token you can set in Authentication header */
  access?: string
  /** This will always be true. */
  totpEnabled?: boolean
  /** The token is valid until this time. */
  validUntil?: string
}
export type AuthTotpApiArg = {
  body: {
    /** Passcode from 2fa. */
    passcode?: string
    /** One of the recovery codes. */
    recovery?: string
  }
}
export type AuthPasswordResetApiResponse = /** status 200 Signin successful */ {
  /** Just a thank you. */
  message?: string
}
export type AuthPasswordResetApiArg = {
  body: {
    /** The account name. */
    email: string
  }
}
export type AuthPasswordResetChangeApiResponse = /** status 200 Signin successful */ object
export type AuthPasswordResetChangeApiArg = {
  body: {
    /** Key received via email. */
    key: string
    /** Outdated, ignored. */
    id?: string
    /** New password. */
    password1?: string
    /** New password again. */
    password2?: string
  }
}
export type GetUserApiResponse = /** status 200 Successful response. */ {
  /** Emailaddress used to log in. */
  username?: string
  /** First name of the user. */
  firstName?: string
  /** Last name of the user. */
  lastName?: string
  /** Organization ID which is used if the user logs in the site. Note that most API calls need an explicit Organization ID, this field is not used there. */
  activeOrgId?: Orgid
  /** List of organizations the user has access to. */
  orgs?: {
    orgId?: Orgid
    /** Name of the organization. */
    name?: string
    /** Current balance in USD. Can be negative. */
    balance?: number
    /** Current balance in USD as a string, with cents. Can be negative. */
    balance_human?: string
    /** Role of the user in this organization. There are a few predefined rols. */
    role?: string
    /** Various permission booleans based on the role. */
    permissions?: object
    /** Lists which contacts and which fields from those contacts this user has access to. */
    contactPermissions?: object
  }[]
}
export type GetUserApiArg = void
export type SendSmsContactApiResponse =
  /** status 200 OK. All fields look good and we've queued the message for delivery. */ SchemaSms
export type SendSmsContactApiArg = {
  body: {
    /** ID of the organization. It can be a string or a number. You can find it in your [Organization Profile](https://app.engagespark.com/account/organization/info). */
    orgId: number | string
    /** Sender ID. In general these need to be set, unless you use a default one. */
    from: string
    /** The content of the message. A message can be up to 7 parts long; longer messages will be rejected.

        You can personalize the SMS using the contact's data, for example the first name. Example: `Hello {{contact.firstName}}`

        In many countries, Unicode characters can be used. However, country-specific GSM 03.38 code pages are generally not supported.

        ⚠️ Please note that when using unicode-characters, the SMS length calculation changes. Read more about [SMS character limits](https://www.engagespark.com/support/sms-character-limit/). */
    message: string
    /** If not empty; if we have already sent a message with the given value we won't send the message again. This is useful when a POST failed due to network errors. */
    clientDedup?: string
    /** Whenever we receive a delivery receipt we POST to this URL. */
    clientPushURL?: string
    /** ID we'll return in lists of SMSs. The ID can be anything and doesn't need to be unique. engageSPARK doesn't do anything with this. */
    clientRef?: string
    /** engageSPARK ID of the contact. If provided, the contact must exist and be part of the organization. */
    contactId?: number | string
    /** Full phone number including the country code; it may have a leading "+".

        This number needs to have an international dialing code.

        For example, for a number from the Philippines (with the dialing code 63), 639123456789 would be correct, whereas 9123456789 would be missing the dialing code. If you are not sure about the dialing code of the country you want to send calls or SMS to, [check this Wikipedia article](https://en.wikipedia.org/wiki/List_of_country_calling_codes#Ordered_by_code) on dialing codes. */
    fullPhoneNumber?: string
    /** Country part of the phone number. If this is used phoneNumberCountry must also be set. */
    phoneNumber?: string
    /** Two-letter ISO code of the country. Used with the "phoneNumber" option. */
    phoneNumberCountry?: string
    /** Legacy; same as "fullPhoneNumber". */
    to?: string
  }
}
export type SendSmsPhonenumberApiResponse =
  /** status 200 OK. All fields look good and we've queued the message for delivery. */ SchemaSms
export type SendSmsPhonenumberApiArg = {
  body: {
    /** ID of the organization. It can be a string or a number. You can find it in your [Organization Profile](https://app.engagespark.com/account/organization/info). */
    orgId: number | string
    /** Sender ID. In general these need to be set, unless you use a default one. */
    from: string
    /** The content of the message. A message can be up to 7 parts long; longer messages will be rejected.

        Personalized messages are not supported in this API.

        In many countries, Unicode characters can be used. Country-specific GSM 03.38 code pages, however, are generally not supported.

        ⚠️ Please note that when using unicode-characters, the SMS length calculation changes. Read more about [SMS character limits](https://www.engagespark.com/support/sms-character-limit/). */
    message: string
    /** If not empty; if we already send a message with the give value we won't send a message again. Useful when a POST failed thanks to network errors. */
    clientDedup?: string
    /** Whenever we get a delivery receipt we POST to this URL. */
    clientPushURL?: string
    /** ID we'll return in lists of SMSs. Can be anything and doesn't need to be unique. engageSPARK doesn't do anything with this. */
    clientRef?: string
    /** Full phone number including the country code, may have a leading "+". */
    fullPhoneNumber?: string
    /** Country part of the phone number. If this is used phoneNumberCountry must also be set. */
    phoneNumber?: string
    /** Two letter ISO code of the country. Used with the "phoneNumber" option. */
    phoneNumberCountry?: string
    /** Legacy, same as "fullPhoneNumber". */
    to?: string
  }
}
export type CreateOrganizationApiResponse = /** status 200 All's well */ {
  /** ID of the organization */
  orgId?: number
}
export type CreateOrganizationApiArg = {
  body: {
    /** Name of the organization */
    name?: string
    /** Website of the organization */
    website?: string
    /** Phone number of the organization */
    phone?: string
    /** Address of the organization */
    address?: string
    /** Country of the organization */
    country?: string
    /** Additional description for the organization address */
    location?: string
  }
}
export type GetOrganizationApiResponse = /** status 200 OK */ SchemaOrg
export type GetOrganizationApiArg = {
  /** engageSPARK ID of the organization.
    You can find this ID in your [Organization Profile](https://app.engagespark.com/account/organization/info). */
  orgId: string
}
export type UpdateOrganizationApiResponse = /** status 200 OK */ {
  /** ID of the organization. */
  orgId?: number
}
export type UpdateOrganizationApiArg = {
  /** engageSPARK ID of the organization.
    You can find this ID in your [Organization Profile](https://app.engagespark.com/account/organization/info). */
  orgId: string
  body: {
    /** Name of the organization. */
    name?: string
    /** Website of the organization. */
    website?: string
    /** Phone number of the organization. */
    phone?: string
    /** Address of the organization. */
    address?: string
    /** Country of the organization. */
    country?: string
    /** Additional description for the organization address. */
    location?: string
    /** The minimum amount of money spent that triggers a notification to be sent to the organization. A 0 value means no notification will be sent. */
    spendNotification?: number
    /** The low balance notification that triggers when the organization's balance is below this amount. */
    balanceNotification?: number
    /** The maximum amount of money that can be spent by the organization in a day. A 0 value means no limit. */
    maxDailySpend?: number
    /** Enable automatically deleting unused contacts. */
    contactsAutodelete?: boolean
    /** Days for .contactsAutodelete . Value must be set only if .contactsAutodelete is enabled, and must be > 0. */
    contactsAutodeleteDays?: number
  }
}
export type GetOrgBalanceApiResponse = /** status 200 Show the balance */ {
  /** Balance in USD, up to 6 digits. */
  balance?: number
  /** Balance in micro USD. Divide by 1_000_000 to get dollars. */
  balanceMicro?: number
  /** Balance in USD, rounded (down) to cents, always has 2 digits after the dot. */
  balanceHuman?: string
  /** Autorecharge does a Credit Card transaction if the balance falls under a certain threshold. */
  autoRecharge?: boolean
  /** Autorecharge threshold in USD. */
  autoRechargeThreshold?: number
  /** Autorecharge amount in USD. */
  autoRechargeAmount?: number
}
export type GetOrgBalanceApiArg = {
  /** engageSPARK ID of the organization.
    You can find this ID in your [Organization Profile](https://app.engagespark.com/account/organization/info). */
  orgId: string
}
export type GetOrgBillingApiResponse = /** status 200 All's well */ SchemaBilling
export type GetOrgBillingApiArg = {
  /** engageSPARK ID of the organization.
    You can find this ID in your [Organization Profile](https://app.engagespark.com/account/organization/info). */
  orgId: string
  /** Start of the period */
  start?: string
  /** End of the period */
  end?: string
}
export type GetOrgInvoiceListApiResponse = /** status 200 The list of invoices. */ {
  months?: SchemaInvoice[]
}
export type GetOrgInvoiceListApiArg = {
  /** engageSPARK ID of the organization.
    You can find this ID in your [Organization Profile](https://app.engagespark.com/account/organization/info). */
  orgId: string
}
export type GetOrgPurchaseListApiResponse = /** status 200 The list of purchases. */ SchemaPurchase[]
export type GetOrgPurchaseListApiArg = {
  /** engageSPARK ID of the organization.
    You can find this ID in your [Organization Profile](https://app.engagespark.com/account/organization/info). */
  orgId: string
}
export type GetOrgUserListApiResponse =
  /** status 200 The full list of users which have access to the organization. */ {
    /** The list of users of the organization. */
    users?: SchemaOrgUser[]
  }
export type GetOrgUserListApiArg = {
  /** engageSPARK ID of the organization.
    You can find this ID in your [Organization Profile](https://app.engagespark.com/account/organization/info). */
  orgId: string
}
export type GetOrgUserApiResponse = /** status 200 The organization user's details. */ SchemaOrgUser
export type GetOrgUserApiArg = {
  /** engageSPARK ID of the organization.
    You can find this ID in your [Organization Profile](https://app.engagespark.com/account/organization/info). */
  orgId: string
  /** engageSPARK ID of a user. */
  userId: string
}
export type UpdateOrgUserApiResponse = unknown
export type UpdateOrgUserApiArg = {
  /** engageSPARK ID of the organization.
    You can find this ID in your [Organization Profile](https://app.engagespark.com/account/organization/info). */
  orgId: string
  /** engageSPARK ID of a user. */
  userId: string
  /** Update the permissions of a user in relation to the organization.
    Supported fields: - fullAccess: bool - readGroupIds: int list - readCustomFieldIds: int list
    Not adding a field keep the old value. readGroupIds needs to be set to [] to clear, null doesn't work. */
  body: {
    /** Whether the user has full access to the organization. */
    fullAccess?: boolean
    /** The IDs of the groups the user can read. */
    readGroupIds?: number[]
    /** The IDs of the custom fields the user can read. */
    readCustomFieldIds?: number[]
  }
}
export type DeleteOrgUserApiResponse = /** status 204 The user has been deleted. */ void
export type DeleteOrgUserApiArg = {
  /** engageSPARK ID of the organization.
    You can find this ID in your [Organization Profile](https://app.engagespark.com/account/organization/info). */
  orgId: string
  /** engageSPARK ID of a user. */
  userId: string
}
export type GetSmsListApiResponse = /** status 200 OK. */ {
  /** The SMSs */
  messages?: SchemaModelSms[]
  /** if true there are older SMSs. */
  hasNextPage?: boolean
  /** repeat the call with this value a "&after=" argument to get the next page. */
  nextPage?: string
}
export type GetSmsListApiArg = {
  /** engageSPARK ID of the organization.
    You can find this ID in your [Organization Profile](https://app.engagespark.com/account/organization/info). */
  orgId: string
  /** See "nextPage" in the response. */
  after?: string
  /** Maximum number of results per API call. */
  limit?: number
  /** Limit to only egress, only ingress, or all directions. An empty value is the same as "all". */
  dir?: 'inbound' | 'outbound' | 'all'
  /** Limit to/exclude failed deliveries. Failed means the message didn't get delivered at all. Delivery Receipts are not taken into account here. An empty value is the same as "all". */
  failed?: 'true' | 'false' | 'all'
  /** Keyword search in the message, sender, receiver, and contact fields. */
  search?: string
  /** The creation time of the message must be >= this timestamp. */
  startDate?: string
  /** The creation time of the message must be < this timestamp. */
  endDate?: string
}
export type GetSmsApiResponse = /** status 200 OK. We have the SMS. */ SchemaModelSms
export type GetSmsApiArg = {
  /** engageSPARK ID of the organization.
    You can find this ID in your [Organization Profile](https://app.engagespark.com/account/organization/info). */
  orgId: string
  /** ID of the SMS. */
  messageId: string
}
export type CreateCampaignApiResponse = /** status 200 Campaign created */ SchemaCampaign
export type CreateCampaignApiArg = {
  /** engageSPARK ID of the organization.
    You can find this ID in your [Organization Profile](https://app.engagespark.com/account/organization/info). */
  orgId: string
  body: {
    /** The type of the campaign. */
    template?:
      | 'smsblast'
      | 'smsdrip'
      | 'voiceblast'
      | 'multidrip'
      | 'ivr'
      | 'smssurvey'
      | 'whatsappsurvey'
      | 'cati'
      | 'reminder'
    /** Friendly name of the campaign */
    name?: string
    /** Launch time. FIXME */
    launch?: object
    /** What .open will be set to on launch. "open" campaigns can get new subscriptions. */
    launchOpen?: boolean
    /** Contacts which will be subscribed on launch. FIXME */
    contacts?: object
    /** The campaign configuration. What's accepted here depends on the selected "template" (campaign type) field, and is not documented here. */
    variables?: object
    /** Place for the UI to store fields. The backend doesn't use this. */
    uiStore?: object
    /** Times calls will go out. Only relevant for campaigns which make calls. FIXME */
    callWindow?: object
    /** Maximum number of times we try to contact someone.

        Special values: -2 means use the .callAgainCustom field, -1 means use the system default, 0 means disable.

        Only relevant for campaigns which make calls. */
    callAgainMax?: number
    /** Custom schedule for call retries. Only relevant for campaigns which make calls. FIXME */
    callAgainCustom?: object
    /** Maximum number of times we try to reconnect dropped calls.

        Special values: -1 means use the system default, 0 means disable.

        Only relevant for campaigns which make calls. */
    reconnectMax?: number
    /** If false: only reconnect if the user was connected for at least 10 seconds. If true: only reconnect if they reached a next part. Only relevant for campaigns which make calls and have reconnect enabled. */
    reconnectOnProgress?: boolean
    /** Is Answering Machine Detection enabled? Only relevant for campaign with make voice calls. */
    amd?: boolean
    /** Possible statuses, only relevant for CATI campaigns. */
    userStatuses?: string[]
  }
}
export type GetCampaignApiResponse = /** status 200 Campaign */ SchemaCampaign
export type GetCampaignApiArg = {
  /** engageSPARK ID of the organization.
    You can find this ID in your [Organization Profile](https://app.engagespark.com/account/organization/info). */
  orgId: string
  /** engageSPARK ID of the campaign.
    You can find this ID when editing your campaign, or copy it in the actions drop down in the campaigns list. */
  campaignId: string
}
export type UpdateCampaignApiResponse = /** status 200 Campaign updated */ SchemaCampaign
export type UpdateCampaignApiArg = {
  /** engageSPARK ID of the organization.
    You can find this ID in your [Organization Profile](https://app.engagespark.com/account/organization/info). */
  orgId: string
  /** engageSPARK ID of the campaign.
    You can find this ID when editing your campaign, or copy it in the actions drop down in the campaigns list. */
  campaignId: string
  body: {
    /** Friendly name of the campaign */
    name?: string
    /** Launch time. FIXME */
    launch?: object
    /** What .open will be set to on launch. "open" campaigns can get new subscriptions. */
    launchOpen?: boolean
    /** Contacts which will be subscribed on launch. FIXME */
    contacts?: object
    /** The campaign configuration. What's accepted here depends on the selected "template" (campaign type) field, and is not documented here. */
    variables?: object
    /** Place for the UI to store fields. The backend doesn't use this. */
    uiStore?: object
    /** Times calls will go out. Only relevant for campaigns which make calls. FIXME */
    callWindow?: object
    /** Maximum number of times we try to contact someone.

        Special values: -2 means use the .callAgainCustom field, -1 means use the system default, 0 means disable.

        Only relevant for campaigns which make calls. */
    callAgainMax?: number
    /** Custom schedule for call retries. Only relevant for campaigns which make calls. FIXME */
    callAgainCustom?: object
    /** Maximum number of times we try to reconnect dropped calls.

        Special values: -1 means use the system default, 0 means disable.

        Only relevant for campaigns which make calls. */
    reconnectMax?: number
    /** If false: only reconnect if the user was connected for at least 10 seconds. If true: only reconnect if they reached a next part. Only relevant for campaigns which make calls and have reconnect enabled. */
    reconnectOnProgress?: boolean
    /** Is Answering Machine Detection enabled? Only relevant for campaign with make voice calls. */
    amd?: boolean
    /** Possible statuses, only relevant for CATI campaigns. */
    userStatuses?: string[]
  }
}
export type DeleteCampaignApiResponse = /** status 200 Campaign */ {
  /** Unique engageSPARK ID of the campaign. */
  engagementId?: number
}
export type DeleteCampaignApiArg = {
  /** engageSPARK ID of the organization.
    You can find this ID in your [Organization Profile](https://app.engagespark.com/account/organization/info). */
  orgId: string
  /** engageSPARK ID of the campaign.
    You can find this ID when editing your campaign, or copy it in the actions drop down in the campaigns list. */
  campaignId: string
}
export type SubscribeContactApiResponse = /** status 200 Subscription successful */ {
  /** Unique subscription ID assigned to the contact. */
  runId?: number
  /** ID of the contact that thas been subscribed. */
  contactId?: number
}
export type SubscribeContactApiArg = {
  /** engageSPARK ID of the organization.
    You can find this ID in your [Organization Profile](https://app.engagespark.com/account/organization/info). */
  orgId: string
  /** engageSPARK ID of the campaign.
    You can find this ID when editing your campaign, or copy it in the actions drop down in the campaigns list. */
  campaignId: string
  body: {
    /** engageSPARK ID of the contact. If provided, the contact must exist and be part of the organization. */
    contactId?: number | string
    /** Full phone number including the country code, may have a leading "+". */
    fullPhoneNumber?: string
    /** Country part of the phone number. If this is used phoneNumberCountry must also be set. */
    phoneNumber?: string
    /** Two letter ISO code of the country. Used with the "phoneNumber" option. */
    phoneNumberCountry?: string
    /** Should a contact be resubscribed if it has a previous, already finished subscription? */
    allowResubscribe?: boolean
    /** Overrides the timezones used to calculate message times in Drip and Reminder campaigns. */
    timeZone?: string
    /** Delays the start of the subscription. If omitted, the subscriptions start immediately.

        You must always set the `value` field. When using the `day` unit, you can additionally set the `time` and `timezone` fields. When using the `week` unit, you can additionally set the `time`, `timezone`, and `dayOfWeek` fields.

        Note that “0 days 9:00” can yield a different result than “1 day 9:00”, depending on the current time of day. The former means “the upcoming 9:00” and the latter means “tomorrow's 9AM”. For example, if it's currently 8:30, then the former means the subscription starts in half an hour, while the latter means that the subscription starts in 24 hours and 30 minutes. */
    delay?: {
      /** Time unit for the subscription delay. */
      unit?: 'second' | 'minute' | 'hour' | 'day' | 'week'
      /** The number of units. For example, if you set `unit` to `"minute"`, then a `value` of `5` delays the subscription by five minutes. */
      value?: number
      /** Optional day of the week when the subscription should activate, only relevant for 'week' unit. */
      daysOfWeek?: PropertiesDaysofweek
      /** Optional specific time of day when the subscription should activate, required if unit is 'day' or 'week'. */
      time?: string
      /** Optional timezone for the specified 'time', defaults to UTC if not provided. */
      timezone?: string
    }
    /** Update the contact with the given fields before subscribing it.

        All fields are optional. Fields that are not provided will not be updated. */
    contact?: PropertiesContact
  }
}
export type UnsubscribeContactApiResponse = /** status 200 Unsubscribe successful */ {
  /** Subscription ID, if any. */
  runId?: number
}
export type UnsubscribeContactApiArg = {
  /** engageSPARK ID of the organization.
    You can find this ID in your [Organization Profile](https://app.engagespark.com/account/organization/info). */
  orgId: string
  /** engageSPARK ID of the campaign.
    You can find this ID when editing your campaign, or copy it in the actions drop down in the campaigns list. */
  campaignId: string
  body: {
    /** engageSPARK ID of the contact. */
    contactId?: number | string
  }
}
export type CreateSpeechPreviewApiResponse = /** status 200 OK */ Blob
export type CreateSpeechPreviewApiArg = {
  /** engageSPARK ID of the organization.
    You can find this ID in your [Organization Profile](https://app.engagespark.com/account/organization/info). */
  orgId: string
  body: {
    /** Text to convert to speech. */
    what: string
    /** Optional voice id based on the available voices (https://api.engagespark.com/system/voices). */
    voice?: string
  }
}
export type SendOrgTopupApiResponse =
  /** status 200 OK. All fields look good and we've executed the top-up. */
  | {
      /** engageSPARK ID */
      id?: string
      /** The currency we used. */
      currency?: string
      /** The amount the user got on their phone. */
      amountSent?: number
      /** What you got charged. */
      price?: number
      /** Always false for status '200', always true for status '202'. */
      queued?: boolean
    }
  | /** status 202 Queued.

Either "async" was set, or we didn't manage to do the top-up right away. The top-up will be retried a few more times for a maximum of 5 hours.

The exact schedule:

 - 1st retry: scheduled 1 minute after the initial request

 - 2nd retry: scheduled 2 minutes after the previous request

 - 3rd retry: scheduled 5 minutes after the previous request

 - 4th retry: scheduled 30 minutes after the previous request

 - 5th retry: scheduled 60 minutes after the previous request

 - 6th retry: scheduled 120 minutes after the previous request */ {
      /** engageSPARK ID */
      id?: string
      /** The currency we used. */
      currency?: string
      /** The amount the user got on their phone. */
      amountSent?: number
      /** What you got charged. */
      price?: number
      /** Always false for status '200', always true for status '202'. */
      queued?: boolean
    }
export type SendOrgTopupApiArg = {
  /** engageSPARK ID of the organization.
    You can find this ID in your [Organization Profile](https://app.engagespark.com/account/organization/info). */
  orgId: string
  body: {
    /** We make the topup closest to this amount (but not over). See the request description for examples and a more in-depth discussion. */
    maxAmount: number | string
    /** We try to use the top-up closest to this amount. Optional but recommended. See the request description for examples and a more in-depth discussion. */
    desiredAmount?: number | string
    /** ID we'll return in lists of top-ups. It can be anything and doesn't need to be unique. engageSPARK doesn't do anything with this. */
    clientRef?: string
    /** If not set or false we won't return until the topup is finished. Since this can take up to 10 minutes this might cause problems with timeouts. It's recommended to set async to true, and then check the result. */
    async?: boolean
    /** If true we don't do the actual top-up, but we return what we would have done. */
    test?: boolean
    /** engageSPARK ID of the contact. */
    contactId?: number | string
    /** Full phone number including the country code, may have a leading "+". */
    fullPhoneNumber?: string
    /** Country part of the phone number. If this is used phoneNumberCountry must also be set. */
    phoneNumber?: string
    /** Two letter ISO code of the country. Used with the "phoneNumber" option. */
    phoneNumberCountry?: string
  }
}
export type GetOrgTopupsListApiResponse = /** status 200 OK. Here are your top-ups. */ {
  /** The top-ups */
  topups?: SchemaTopup[]
  /** if true there are older top-ups. */
  hasNextPage?: boolean
  /** repeat the call with this value a "&after=" argument to get the next page. */
  nextPage?: string
}
export type GetOrgTopupsListApiArg = {
  /** engageSPARK ID of the organization.
    You can find this ID in your [Organization Profile](https://app.engagespark.com/account/organization/info). */
  orgId: string
  /** See "nextPage" in the response. */
  after?: string
  /** Maximum number of results per API call. */
  limit?: number
  /** Limit to/exclude failed Top-Ups. */
  failed?: 'true' | 'false' | 'all'
  /** Keyword search in the contact fields. */
  search?: string
  /** Create time of the Top-Up must be >= this timestamp. */
  startDate?: string
  /** Create time of the Top-Up must be < this timestamp. */
  endDate?: string
}
export type GetOrgTopupsApiResponse = /** status 200 OK. We have the Top-Up. */ SchemaTopup
export type GetOrgTopupsApiArg = {
  /** engageSPARK ID of the organization.
    You can find this ID in your [Organization Profile](https://app.engagespark.com/account/organization/info). */
  orgId: string
  /** ID of the Top-Up. */
  topupId: string
}
export type GetOrgDidsApiResponse = /** status 200 List phone numbers. */ {
  phoneNumbers: SchemaDidsItem[]
  /** Total number of phone numbers. */
  total: number
}
export type GetOrgDidsApiArg = {
  /** engageSPARK ID of the organization.
    You can find this ID in your [Organization Profile](https://app.engagespark.com/account/organization/info). */
  orgId: string
}
export type GetOrgDidApiResponse = /** status 200 Give detailed phone number info. */ {
  callHooks: null | SchemaInboundHook[]
  /** 2-letter ISO country code */
  countryCode: string
  /** When the phone number was created */
  created: string
  hooks: null | SchemaInboundHook[]
  inboundCallCamp: null | SchemaCampaign
  /** Inbound call campaign ID, 0 for none */
  inboundCallCampId: number
  /** Name of the phone number */
  name: string
  /** The phone number */
  phoneNumber: string
  /** Price per SMS, in micro USD */
  smsPrice: number
  /** Price per SMS, in USD */
  smsPriceFloat: number
  /** Price per voice call, in micro USD */
  voicePrice: number
  /** Price per voice call, in USD */
  voicePriceFloat: number
  /** Number of seconds per voice call unit */
  voiceUnitSeconds: number
  whatsappHooks?: null | SchemaInboundHook[]
  /** Is this phone number registered with WhatsApp as a senderID? */
  whatsAppRegistered: boolean
  /** If this is true, the users can use this number to complete the whole WhatsApp Business SenderID registration process with Meta. */
  whatsAppMetaOtp?: boolean
  /** Is this phone number capable of sending SMS? */
  sms: boolean
  /** Is this phone number capable of sending WhatsApp messages? */
  whatsApp: boolean
  /** Is this phone number capable of making voice calls? */
  voice: boolean
}
export type GetOrgDidApiArg = {
  /** engageSPARK ID of the organization.
    You can find this ID in your [Organization Profile](https://app.engagespark.com/account/organization/info). */
  orgId: string
  /** engageSPARK ID of the DID. */
  didId: string
}
export type PostOrgDidApiResponse = /** status 200 OK */ {
  /** Phone number ID */
  id: number
}
export type PostOrgDidApiArg = {
  /** engageSPARK ID of the organization.
    You can find this ID in your [Organization Profile](https://app.engagespark.com/account/organization/info). */
  orgId: string
  /** engageSPARK ID of the DID. */
  didId: string
  body: {
    /** New name of the phone number */
    name?: string
    /** Unique engageSPARK ID of the campaign. Set to 0 to remove. */
    inboundCallCampId?: number
  }
}
export type PostDidRegisterWhatsAppApiResponse = /** status 200 OK */ {
  /** Always true */
  ok: boolean
  /** the Sender SID, which is for logging. */
  sid?: string
}
export type PostDidRegisterWhatsAppApiArg = {
  /** engageSPARK ID of the organization.
    You can find this ID in your [Organization Profile](https://app.engagespark.com/account/organization/info). */
  orgId: string
  /** engageSPARK ID of the DID. */
  didId: string
  body: {
    /** WhatsApp Business Account ID */
    wabaId: string
    /** Profile name. */
    profileName?: string
  }
}
export type CreateOrgDidHookApiResponse = /** status 200 The hook was created. */ {
  /** Hook ID */
  id: number
}
export type CreateOrgDidHookApiArg = {
  /** engageSPARK ID of the organization.
    You can find this ID in your [Organization Profile](https://app.engagespark.com/account/organization/info). */
  orgId: string
  /** engageSPARK ID of the DID. */
  didId: string
  schemaInboundHookPayload: SchemaInboundHookPayload
}
export type UpdateOrgDidHookApiResponse = /** status 200 The hook was updated. */ {
  /** Hook ID */
  id: number
}
export type UpdateOrgDidHookApiArg = {
  /** engageSPARK ID of the organization.
    You can find this ID in your [Organization Profile](https://app.engagespark.com/account/organization/info). */
  orgId: string
  /** engageSPARK ID of the DID. */
  didId: string
  /** engageSPARK ID of the DID hook. */
  hookId: string
  schemaInboundHook: SchemaInboundHook
}
export type DeleteOrgDidHookApiResponse = /** status 200 The hook was deleted. */ object
export type DeleteOrgDidHookApiArg = {
  /** engageSPARK ID of the organization.
    You can find this ID in your [Organization Profile](https://app.engagespark.com/account/organization/info). */
  orgId: string
  /** engageSPARK ID of the DID. */
  didId: string
  /** engageSPARK ID of the DID hook. */
  hookId: string
}
export type CreateOrgDidCallHookApiResponse = /** status 200 The hook was created. */ {
  /** Hook ID */
  id: number
}
export type CreateOrgDidCallHookApiArg = {
  /** engageSPARK ID of the organization.
    You can find this ID in your [Organization Profile](https://app.engagespark.com/account/organization/info). */
  orgId: string
  /** engageSPARK ID of the DID. */
  didId: string
  schemaInboundHookPayload: SchemaInboundHookPayload
}
export type UpdateOrgDidCallHookApiResponse = /** status 200 The hook was updated. */ {
  /** Hook ID */
  id: number
}
export type UpdateOrgDidCallHookApiArg = {
  /** engageSPARK ID of the organization.
    You can find this ID in your [Organization Profile](https://app.engagespark.com/account/organization/info). */
  orgId: string
  /** engageSPARK ID of the DID. */
  didId: string
  /** engageSPARK ID of the DID hook. */
  hookId: string
  schemaInboundHook: SchemaInboundHook
}
export type DeleteOrgDidCallHookApiResponse = /** status 200 The hook was deleted. */ object
export type DeleteOrgDidCallHookApiArg = {
  /** engageSPARK ID of the organization.
    You can find this ID in your [Organization Profile](https://app.engagespark.com/account/organization/info). */
  orgId: string
  /** engageSPARK ID of the DID. */
  didId: string
  /** engageSPARK ID of the DID hook. */
  hookId: string
}
export type CreateOrgDidWaHookApiResponse = /** status 200 The hook was created. */ {
  /** Hook ID */
  id: number
}
export type CreateOrgDidWaHookApiArg = {
  /** engageSPARK ID of the organization.
    You can find this ID in your [Organization Profile](https://app.engagespark.com/account/organization/info). */
  orgId: string
  /** engageSPARK ID of the DID. */
  didId: string
  schemaInboundHookPayload: SchemaInboundHookPayload
}
export type UpdateOrgDidWaHookApiResponse = /** status 200 The hook was updated. */ {
  /** Hook ID */
  id: number
}
export type UpdateOrgDidWaHookApiArg = {
  /** engageSPARK ID of the organization.
    You can find this ID in your [Organization Profile](https://app.engagespark.com/account/organization/info). */
  orgId: string
  /** engageSPARK ID of the DID. */
  didId: string
  /** engageSPARK ID of the DID hook. */
  hookId: string
  schemaInboundHook: SchemaInboundHook
}
export type DeleteOrgDidWaHookApiResponse = /** status 200 The hook was deleted. */ object
export type DeleteOrgDidWaHookApiArg = {
  /** engageSPARK ID of the organization.
    You can find this ID in your [Organization Profile](https://app.engagespark.com/account/organization/info). */
  orgId: string
  /** engageSPARK ID of the DID. */
  didId: string
  /** engageSPARK ID of the DID hook. */
  hookId: string
}
export type GetOrgPhonenumberApiResponse = /** status 200 Give phone number info. */ {
  /** This is a valid phone number (full-number version). Note that this does not mean this number is in use or active. */
  isValid?: boolean
  /** engageSPARK supports this phone number range. If not, messages will fail. If the range is supported, it usually means that we can send to this number—but that's not guaranteed. If you want to be sure if a particular route is supported, please reach out to support. */
  hasCarrier?: boolean
  /** This is a valid phone number (region version). */
  isValidForRegion?: boolean
  /** Description what kind of phone number this is. Common options are "mobile", "fixedline", "fixedlineormobile", "premium", "tollfree". */
  phoneNumberType?: string
  /** 2 letter ISO country this phone number belongs to. Only set when "hasCarrier" is true. */
  countryIso?: string
  /** Phone number formatted as E164 number, no leading "+". */
  phoneNumberE164?: string
  /** Phone number as E164 number, but with known migrations applied. */
  phoneNumberFixed?: string
  /** Relevant only for WhatsApp numbers. If this is set this number is registered with Meta. */
  whatsAppRegistered?: boolean
}
export type GetOrgPhonenumberApiArg = {
  /** engageSPARK ID of the organization.
    You can find this ID in your [Organization Profile](https://app.engagespark.com/account/organization/info). */
  orgId: string
  /** The phone number, either the full number, with country code, or only the national part, if "region" parameter is set. Can have a leading "+". */
  phonenumber?: string
  /** Only set this if "phonenumber" is not a full international phone number. Region is the 2-letter ISO country code. */
  region?: string
}
export type CreateContactApiResponse = /** status 200 Contact created */ SchemaContact
export type CreateContactApiArg = {
  /** engageSPARK ID of the organization.
    You can find this ID in your [Organization Profile](https://app.engagespark.com/account/organization/info). */
  orgId: string
  body: {
    /** Full phone number including the country code, may have a leading "+". */
    fullPhoneNumber?: string
    /** Country part of the phone number. If this is used `phoneNumberCountry` must also be set. */
    phoneNumber?: string
    /** Two letter ISO code of the country. Used with the `phoneNumber` option. */
    phoneNumberCountry?: string
    /** First name of the contact. */
    firstName?: string
    /** Last name of the contact. */
    lastName?: string
    /** Language is free-form, you can use any standard or system, or leave it empty. The Language field is used in multi language campaigns to decide which version of a message or call to use. */
    language?: string
    /** List of group IDs of which this contact will be a member. Values can be either integers or the string version of integers. */
    groups?: (number | string)[]
    /** Custom field values. The keys of the object are the engageSPARK integer ID of the custom field, and the value is a free-form string. */
    customFields?: {
      [key: string]: string
    }
    /** An alternative way to set custom fields. It's the same idea as `customFields`, but the keys of the objects are the names of the custom fields. We'll use the existing group ID or create a new one if it doesn't exist.
        - names are compared case in-sensitive (so if you add "Gender", it'll re-use an existing column "gender").
        - if there are multiple possible matches, then we take the oldest existing custom field.
        - if the same custom field is used in `customFields` and `customFieldsByName`, then the value in customFieldsByName wins.
        - if the same custom field is given multiple times in `customFieldsByName` then it's not defined which one wins (`{"color": "green", "COLOR": "red"}`).
          The custom field color can now be either red or green). */
    customFieldsByName?: object
    /** Do not automatically delete this contact if contact autodeletion is enabled for this org. You can still delete it manually. */
    noAutodelete?: boolean
  }
}
export type GetContactApiResponse = /** status 200 Contact created */ SchemaContact
export type GetContactApiArg = {
  /** engageSPARK ID of the organization.
    You can find this ID in your [Organization Profile](https://app.engagespark.com/account/organization/info). */
  orgId: string
  /** engageSPARK ID of the contact.
    You get this ID when creating a contact. */
  contactId: string
}
export type UpdateContactApiResponse = /** status 200 Contact updated */ SchemaContact
export type UpdateContactApiArg = {
  /** engageSPARK ID of the organization.
    You can find this ID in your [Organization Profile](https://app.engagespark.com/account/organization/info). */
  orgId: string
  /** engageSPARK ID of the contact.
    You get this ID when creating a contact. */
  contactId: string
  body: {
    /** Full phone number including the country code, may have a leading "+". */
    fullPhoneNumber?: string
    /** First name of the contact. */
    firstName?: string
    /** Last name of the contact. */
    lastName?: string
    /** Language is free form, you can use any standard or system, or leave it empty. The Language field is used in multi language campaigns to decide which version of a message or call to use. */
    language?: string
    /** List of group IDs of which this contact will be a member. Values can be either integers or the string version of integers. */
    groups?: (number | string)[]
    /** Custom field values. The keys of the object are the engageSPARK integer ID of the custom field, and the value is a free form string.
        Groups which aren't set aren't updated. Set value to an empty string to delete the value. */
    customFields?: {
      [key: string]: string
    }
    /** An alternative way to set Custom fields. It's the same idea as `customFields`, but the keys of the objects are the names of the custom fields. See the documentation in "Create Contact". */
    customFieldsByName?: object
    /** Do not automatically delete this contact if contact autodeletion is enabled for this org. You can still delete it manually. */
    noAutodelete?: boolean
  }
}
export type GetOrgGroupsListApiResponse = /** status 200 List of groups */ {
  groups?: SchemaGroup[]
  total?: number
}
export type GetOrgGroupsListApiArg = {
  /** engageSPARK ID of the organization.
    You can find this ID in your [Organization Profile](https://app.engagespark.com/account/organization/info). */
  orgId: string
  /** The page number to retrieve. */
  page?: number
  /** The requested number of items on a page. */
  size?: number
  /** The field to sort by. */
  sortBy?: string
  direction?: 'ASC' | 'DESC'
}
export type CreateOrgGroupApiResponse = /** status 201 The group was created. */ {
  /** Unique engageSPARK ID of the group. */
  id?: number
}
export type CreateOrgGroupApiArg = {
  /** engageSPARK ID of the organization.
    You can find this ID in your [Organization Profile](https://app.engagespark.com/account/organization/info). */
  orgId: string
  body: {
    /** Name of the group. */
    name: string
    /** Description of the group. */
    description: string
    type: SchemaGroupType
    conditions?: null | SchemaCondition[]
    /** Do not automatically delete the contacts of this group if contact autodeletion is enabled for this org. You can still delete them manually. */
    noAutodelete: boolean
  }
}
export type GetOrgGroupApiResponse = /** status 200 Contact group found */ SchemaGroup
export type GetOrgGroupApiArg = {
  /** engageSPARK ID of the organization.
    You can find this ID in your [Organization Profile](https://app.engagespark.com/account/organization/info). */
  orgId: string
  /** engageSPARK ID of the contact group. */
  groupId: string
}
export type UpdateOrgGroupApiResponse = /** status 200 Contact group updated */ object
export type UpdateOrgGroupApiArg = {
  /** engageSPARK ID of the organization.
    You can find this ID in your [Organization Profile](https://app.engagespark.com/account/organization/info). */
  orgId: string
  /** engageSPARK ID of the contact group. */
  groupId: string
  body: {
    /** Name of the group. */
    name: string
    /** Description of the group. */
    description: string
    type: SchemaGroupType
    conditions?: null | SchemaCondition[]
    /** Do not automatically delete the contacts of this group if contact autodeletion is enabled for this org. You can still delete them manually. */
    noAutodelete?: boolean
  }
}
export type DeleteOrgGroupApiResponse = /** status 200 Contact group deleted */ object
export type DeleteOrgGroupApiArg = {
  /** engageSPARK ID of the organization.
    You can find this ID in your [Organization Profile](https://app.engagespark.com/account/organization/info). */
  orgId: string
  /** engageSPARK ID of the contact group. */
  groupId: string
}
export type AddOrgGroupsContactsApiResponse = /** status 200 Contacts added to group */ {
  /** number of groups we found */
  groups?: number
  /** number of contacts we found */
  contacts?: number
  /** Small number of contacts (currently < 1000) will be added real-time. If the number is larger we'll add the first few real-time, so we can check for errors, but the rest of them will be added in the background, after the call has been returned. If we do this backgroundTask will be true. If there are no (valid) contact IDs or group IDs are give the result is always a 200. */
  backgroundTask?: boolean
}
export type AddOrgGroupsContactsApiArg = {
  /** engageSPARK ID of the organization.
    You can find this ID in your [Organization Profile](https://app.engagespark.com/account/organization/info). */
  orgId: string
  body: {
    /** List of group IDs that the contacts will be added to. */
    groupIds: number[]
    contacts: SchemaContactsSelection
  }
}
export type DeleteOrgGroupContactsApiResponse = /** status 200 Contacts removed from group */ {
  /** Total number of remaining contacts in the group. */
  total?: number
}
export type DeleteOrgGroupContactsApiArg = {
  /** engageSPARK ID of the organization.
    You can find this ID in your [Organization Profile](https://app.engagespark.com/account/organization/info). */
  orgId: string
  /** engageSPARK ID of the contact group. */
  groupId: string
  body: SchemaContactsSelection & {
    /** List of contact IDs that are included in the selection, it's an alias for includedContactIds. */
    contactIds?: number[]
  }
}
export type GetOrgCustomfieldsApiResponse = /** status 200 List custom fields. */ {
  customfields: SchemaCustomfield[]
}
export type GetOrgCustomfieldsApiArg = {
  /** engageSPARK ID of the organization.
    You can find this ID in your [Organization Profile](https://app.engagespark.com/account/organization/info). */
  orgId: string
}
export type CreateOrgCustomfieldApiResponse = /** status 201 Custom field created. */ SchemaCustomfield & {
  /** List of engageSPARK IDs of the custom fields created. */
  ids: number[]
}
export type CreateOrgCustomfieldApiArg = {
  /** engageSPARK ID of the organization.
    You can find this ID in your [Organization Profile](https://app.engagespark.com/account/organization/info). */
  orgId: string
  body: {
    /** Name of the custom field. */
    name?: string
    /** List of names of the custom field. If multiple names are provided, multiple custom fields will be created. */
    names?: string[]
  }
}
export type UpdateOrgCustomfieldApiResponse = /** status 200 Custom field updated. */ SchemaCustomfield
export type UpdateOrgCustomfieldApiArg = {
  /** engageSPARK ID of the organization.
    You can find this ID in your [Organization Profile](https://app.engagespark.com/account/organization/info). */
  orgId: string
  /** engageSPARK ID of the customfield.
    You get this ID when creating a customfield. */
  customfieldId: string
  body: {
    /** Name of the custom field. */
    name: string
  }
}
export type DeleteOrgCustomfieldApiResponse = /** status 200 Custom field deleted. */ object
export type DeleteOrgCustomfieldApiArg = {
  /** engageSPARK ID of the organization.
    You can find this ID in your [Organization Profile](https://app.engagespark.com/account/organization/info). */
  orgId: string
  /** engageSPARK ID of the customfield.
    You get this ID when creating a customfield. */
  customfieldId: string
}
export type CreateOrgReportApiResponse = /** status 200 OK. All fields look good and we've queued the request. */ {
  /** ID of the report */
  exportId?: number
}
export type CreateOrgReportApiArg = {
  /** engageSPARK ID of the organization.
    You can find this ID in your [Organization Profile](https://app.engagespark.com/account/organization/info). */
  orgId: string
  body: {
    /** Which report. See main docs above for the list. */
    function: string
    /** Used in report "smsreport": year/month the report is about, can't be before 2022. */
    month?: string
    /** Used in the "orgspend" report. */
    orgreport?: {
      /** either empty ("forever"), or "month". */
      grouping: '' | 'month'
      /** Limit the data */
      filter?: {
        /** Only use records from these campaigns. All campaigns if empty. */
        campaignIds?: number[]
        /** Limit the date */
        date?: {
          /** On or after this time. No timezone or seconds. */
          from?: string
          /** Before this time. No timezone or seconds. */
          until?: string
          /** Valid time zone. */
          tz?: string
        }
      }
    }
  }
}
export type GetExportsListApiResponse = /** status 200 OK */ {
  total?: number
  exports?: SchemaExport[]
}
export type GetExportsListApiArg = {
  /** engageSPARK ID of the organization.
    You can find this ID in your [Organization Profile](https://app.engagespark.com/account/organization/info). */
  orgId: string
  /** The page number to retrieve. */
  page?: number
  /** The requested number of items on a page. */
  size?: number
}
export type GetExportApiResponse = /** status 200 OK */ {
  export?: SchemaExport
}
export type GetExportApiArg = {
  /** engageSPARK ID of the organization.
    You can find this ID in your [Organization Profile](https://app.engagespark.com/account/organization/info). */
  orgId: string
  /** engageSPARK ID of the exported report. */
  exportId: number
}
export type GetExportsFileApiResponse = /** status 200 OK */ Blob
export type GetExportsFileApiArg = {
  /** engageSPARK ID of the organization.
    You can find this ID in your [Organization Profile](https://app.engagespark.com/account/organization/info). */
  orgId: string
  /** engageSPARK ID of the exported report. */
  exportId: number
  /** The name and extension of the file. */
  filename: string
}
export type GetFileListApiResponse = /** status 200 The list of saved files of the organization. */ SchemaFile[]
export type GetFileListApiArg = {
  /** engageSPARK ID of the organization.
    You can find this ID in your [Organization Profile](https://app.engagespark.com/account/organization/info). */
  orgId: string
  /** Optional category filter for the files. */
  category?: string
}
export type UploadFileApiResponse = /** status 200 The file was uploaded successfully. */ {
  /** The filename of the uploaded file. */
  filename: string
  /** The title of the uploaded file. */
  title: string
}
export type UploadFileApiArg = {
  /** engageSPARK ID of the organization.
    You can find this ID in your [Organization Profile](https://app.engagespark.com/account/organization/info). */
  orgId: string
  body: {
    /** The file contents to upload. */
    upload: Blob
    /** The category of the file. */
    category?: string
    /** Optionally convert audio files. Don't use this on non-audio files. The file is processed by the sox audio processor. */
    filter?: 'ogg' | 'mp3' | 'mono' | 'norm'
    /** We don't recode video, if this option is set (any value), we check if the file is compatible with whatsapp, checking against its limitations: - needs to be h264 video - needs to have aac audio - smaller than 16MB - at least a second long
        We also add a thumbnail of the video to the storage system */
    video?: boolean
  }
}
export type DownloadFileApiResponse = /** status 200 The raw file data. */ Blob
export type DownloadFileApiArg = {
  /** engageSPARK ID of the organization.
    You can find this ID in your [Organization Profile](https://app.engagespark.com/account/organization/info). */
  orgId: string
  /** The name and extension of the file. */
  filename: string
}
export type UpdateFileApiResponse = /** status 200 File updated */ {
  /** The filename which was passed in. */
  filename?: string
}
export type UpdateFileApiArg = {
  /** engageSPARK ID of the organization.
    You can find this ID in your [Organization Profile](https://app.engagespark.com/account/organization/info). */
  orgId: string
  /** The name and extension of the file. */
  filename: string
  body: {
    /** Audio transcript. */
    transcript?: string
  }
}
export type DeleteFileApiResponse = /** status 200 The file was deleted. */ object
export type DeleteFileApiArg = {
  /** engageSPARK ID of the organization.
    You can find this ID in your [Organization Profile](https://app.engagespark.com/account/organization/info). */
  orgId: string
  /** The name and extension of the file. */
  filename: string
}
export type TranscribeFileApiResponse = /** status 200 The transcript */ {
  /** The transcript. */
  transcript?: string
}
export type TranscribeFileApiArg = {
  /** engageSPARK ID of the organization.
    You can find this ID in your [Organization Profile](https://app.engagespark.com/account/organization/info). */
  orgId: string
  body: {
    /** Stored file name. */
    filename: string
    /** Language, the `transcribe` attribute from api.es/system/transcribe */
    language: string
    /** If true the transcript will be stored with the file. */
    store?: boolean
  }
}
export type GetSnippetListApiResponse = /** status 200 The list of snippets. */ SchemaSnippet[]
export type GetSnippetListApiArg = {
  /** engageSPARK ID of the organization.
    You can find this ID in your [Organization Profile](https://app.engagespark.com/account/organization/info). */
  orgId: string
  /** When this key is present, all organization snippets will be returned. By default, only a user's own snippets are listed. */
  all?: boolean
  /** Filter returned snippets by category. */
  category?: string
}
export type CreateSnippetApiResponse = /** status 200 OK */ {
  /** The id of the saved snippet */
  id?: number
}
export type CreateSnippetApiArg = {
  /** engageSPARK ID of the organization.
    You can find this ID in your [Organization Profile](https://app.engagespark.com/account/organization/info). */
  orgId: string
  body: {
    /** Name of the snippet category. */
    category?: string
    snippet?:
      | {
          [key: string]: any
        }
      | {
          [key: string]: any
        }[]
    referencedFiles?: string[]
    referencedCampaigns?: number[]
    referencedContacts?: number[]
  }
}
export type GetSnippetApiResponse = /** status 200 The data of the snippet */ SchemaSnippet
export type GetSnippetApiArg = {
  /** engageSPARK ID of the organization.
    You can find this ID in your [Organization Profile](https://app.engagespark.com/account/organization/info). */
  orgId: string
  /** engageSPARK ID of a snippet. */
  snippetId: string
}
export type UpdateSnippetApiResponse = /** status 200 The data of the snippet */ {
  /** The id of the updated snippet */
  id?: number
}
export type UpdateSnippetApiArg = {
  /** engageSPARK ID of the organization.
    You can find this ID in your [Organization Profile](https://app.engagespark.com/account/organization/info). */
  orgId: string
  /** engageSPARK ID of a snippet. */
  snippetId: string
  body: {
    category?: string
    snippet?: {
      [key: string]: any
    }
    userId?: number
    /** To remove references, update with an explicit empty list. */
    referencedFiles?: string[]
    /** To remove references, update with an explicit empty list. */
    referencedCampaigns?: number[]
    /** To remove references, update with an explicit empty list. */
    referencedContacts?: number[]
  }
}
export type DeleteSnippetApiResponse = /** status 200 The data of the snippet */ object
export type DeleteSnippetApiArg = {
  /** engageSPARK ID of the organization.
    You can find this ID in your [Organization Profile](https://app.engagespark.com/account/organization/info). */
  orgId: string
  /** engageSPARK ID of a snippet. */
  snippetId: string
}
export type SchemaPricing = {
  /** The name of the carrier. */
  carrierName?: string
  /** The currency used for the requested country, e.g. PHP. */
  productCurrency?: string
  /** The value of a topup. */
  productValue?: number
  /** The price in USD. */
  retailPriceUSD?: number
}
export type Orgid = number
export type SchemaSms = {
  /** engageSPARK message ID. Will be unique. */
  messageId?: string
  /** Number of SMSs used to send the message (estimate). */
  estimateParts?: number
  /** Price per part, in millionths of a dollar. */
  estimateMicro?: number
  /** Total estimated cost, in USD, rounded down to cents. */
  estimate?: string
  /** If there are problems with the telco we'll try to deliver the message until this timestamp. */
  deliveryDeadline?: string
  /** Full phone number of the recipient. Including country code, no leading "+". */
  to?: string
  /** The message as we send it. */
  message?: string
  /** Echo of the "clientDedup" field we received in the request. */
  clientDedup?: string
  /** Echo of the "clientPushURL" field we received in the request. */
  clientPushURL?: string
  /** Echo of the "clientRef" field we received in the request. */
  clientRef?: string
}
export type SchemaOrg = {
  /** Name of the organization */
  name: string
  /** Website of the organization */
  website: string
  /** Phone number of the organization */
  phone: string
  /** Address of the organization */
  address: string
  /** Country of the organization */
  country: string
  /** Location of the organization */
  location: string
  /** The minimum amount of money spent that triggers a notification to be sent to the organization. A null value means no notification will be sent. */
  spendNotification: number
  /** The minimum balance that triggers a notification to be sent to the organization. */
  balanceNotification: number
  /** The maximum amount of money that can be spent by the organization in a day. A null value means no limit. */
  maxDailySpend: number
  /** If enabled, automatically delete contacts N days after their last use or update. */
  contactsAutodelete?: boolean
  /** Number of days for .contactsAutodelete . */
  contactsAutodeleteDays?: number
}
export type SchemaBilling = {
  /** Start of the period */
  start?: string
  /** End of the period */
  end?: string
  smsStats?: {
    /** Total number of SMS sent. */
    total: number
    /** Total cost in USD * 1m. */
    costMicro: number
    /** Total cost in USD. */
    costHuman: string
    /** Number of total SMS sent. */
    totalEgress: number
    /** Number of SMS that failed to be delivered to the telco. */
    deliveredToTelcoFailed: number
    /** Number of SMS with an unknown delivery status. */
    deliveredToPhoneUnknown: number
    /** Number of SMS that failed to be delivered to the target device. */
    deliveredToPhoneFailed: number
    /** Number of SMS that were successfully delivered to the target device. */
    deliveredToPhoneConfirmed: number
    /** Number of SMS that are still being processed. */
    inProgress: number
    /** Number of SMS received. */
    totalIngress: number
  }
  whatsappStats?: {
    /** Total number of WhatsApp messages sent. */
    total?: number
    /** Total cost in USD / 1m. */
    costMicro?: number
    /** Total cost in USD. */
    costHuman?: string
    /** Number of total WhatsApp messages sent. */
    totalEgress?: number
    /** Number of successful WhatsApp messages sent. */
    success?: number
    /** Number of failed WhatsApp messages sent. */
    failed?: number
    /** Number of WhatsApp messages still being processed. */
    inProgress?: number
    /** Number of WhatsApp messages received. */
    totalIngress?: number
  }
  voiceStats?: {
    /** Total number of voice calls. */
    total?: number
    /** Total cost in USD / 1m. */
    costMicro?: number
    /** Total cost in USD. */
    costHuman?: string
    /** The total duration of all voice calls in seconds. */
    seconds?: number
    /** Number of total outgoing voice calls. */
    totalEgress?: number
    /** Total duration of outgoing voice calls in seconds. */
    secondsEgress?: number
    /** Number of redialed voice calls. */
    callAgain?: number
    /** Number of voice calls that failed to ring. */
    failedToRing?: number
    /** Number of voice calls that were not answered. */
    notAnswered?: number
    /** Number of voice calls that were answered. */
    answered?: number
    /** Number of voice calls received. */
    totalIngress?: number
    /** Total duration of voice calls received in seconds. */
    secondsIngress?: number
  }
}
export type SchemaInvoice = {
  /** The issue date of the invoice in YYYY-MM-DD format. */
  date: string
  /** The year of the invoice. */
  year: number
  /** The month of the invoice. */
  month: number
  /** Invoice PDF filename. */
  pdf: string
  /** Invoice XLSX filename. */
  xlsx: string
}
export type SchemaPurchase = {
  /** The unique identifier of the purchase. */
  id: number
  /** The amount spent. */
  amount: string
  /** The code for the transaction type of the purchase. */
  transCode:
    | 'purchase_of_funds'
    | 'purchase_of_funds_via_credit_card'
    | 'purchase_of_funds_via_paypal'
    | 'purchase_of_funds_via_bank_wire'
    | 'purchase_of_funds_via_western_union'
    | 'purchase_of_funds_via_cash'
    | 'purchase_of_funds_via_check'
  /** The date and time the purchase was created. */
  created: string
}
export type SchemaOrgUser = {
  /** Unique engageSPARK user ID. */
  id: number
  /** Username of the user. */
  username: string
  /** Full name of the user. */
  name: string
  /** If true, the user has enabled two-factor authentication. */
  totp: boolean
  /** Role of the user. */
  role: 'owner' | 'admin' | 'manager' | 'cati_agent' | 'customer_service' | 'viewer'
  /** If true, the user has full access to the organization. */
  fullAccess: boolean
  /** If true, the user has read access to all groups.
    This is only set if fullAccess is false. */
  readGroupAll?: boolean
  /** List of group IDs the user can see.
    This is only set if fullAccess and readGroupAll is false. */
  readGroupIds?: number[]
  /** If true, the user has read access to all custom fields. */
  readCustomFieldAll?: boolean
  /** List of custom field IDs the user can see. */
  readCustomFieldIds?: number[]
  /** List of fields the user can see. */
  readFields?: string[]
}
export type SchemaModelSms = {
  /** engageSPARK message ID. Will be unique. */
  id?: string
  /** Egress or Ingress message */
  direction?: 'egress' | 'ingress'
  /** Time the messages got created. Delivery (if any) will be after this. */
  created?: string
  /** Sender of the message. Won't be present if the user doesn't have permissions to see this field. */
  sender?: string
  /** Sender of the message. Won't be present if the user doesn't have permissions to see this field. */
  recipient?: string
  /** engageSPARK contact ID. */
  contactId?: number
  /** The charged price in micro USD per part of the SMS. */
  pricePerPart?: number
  /** If not true engageSPARK will try more deliveries still. */
  done?: boolean
  /** Number of parts a successful SMS was made of. */
  successPartCount?: number
  /** Time the message got sent. */
  successT?: string
  /** The message as we (tried to) send. Personalization, if any, will be expanded. */
  message?: string
  /** Description what went wrong with sending the initial message. Note that this field can be empty while the Delivery Receipt does indicate a problem. */
  error?: string
  /** Delivery Receipt error, if any. */
  deliveryError?: string
  /** Was this SMS successfully delivered to our telco partner?

    True if the message is successfully delivered to the telco partner. False if there was an error or while the message is still being delivered.

    Note that this is the first step. It does not mean it has reached the handset. */
  deliveredToTelco?: boolean
  /** The Delivery Receipt status of this SMS.

    Not all telcos support delivery receipts and they are not always reliable. This varies per telco per country. Contact us to know if delivery receipts are available in your program country, and how reliable they can be expected to be.

    - unknown – means we haven’t received a delivery receipt from the telecom. This can happen either when (a) deliveredToTelco is `false` OR (b) deliveredToTelco is `true` but the vendor hasn’t sent us the delivery receipt, yet.
    - confirmed – means we received the delivery receipt from the telecom and it says that, yes, the SMS was delivered.
    - failed – means we received the delivery receipt from the telecom and it says the SMS could not be delivered. */
  deliveredToPhone?: 'unknown' | 'confirmed' | 'failed'
  /** The value set in the "clientRef" value in the original "send SMS" API call. engageSPARK doesn't do anything with this value, besides returning it here. */
  client_ref?: string
  /** The charged price in micro USD in total. In case of refunds this can be 0. */
  chargedMicro?: number
  /** The charged price in USD as string. Has 2 digits, but 6 if there charge uses amounts smaller than cents. */
  chargedHuman?: string
}
export type SchemaCampaign = {
  /** Unique engageSPARK ID of the campaign. */
  id?: number
  /** Same as the .id field. */
  engagementId?: number
  /** Type of the campaign. */
  template?: string
  /** Friendly name of the campaign. */
  name?: string
  /** Launch time. FIXME */
  launch?: object
  /** What .open will be set to on launch. "open" campaigns can get new subscriptions. */
  launchOpen?: boolean
  /** Contacts which will be subscribed on launch. FIXME */
  contacts?: object
  /** Campaign configuration. */
  variables?: object
  /** Place for the UI to store data. */
  uiStore?: object
  /** Times calls will go out. Only relevant for campaigns which make calls. FIXME */
  callWindow?: object
  /** Maximum number of times we try to contact someone.

    Special values: -2 means use the .callAgainCustom field, -1 means use the system default, 0 means disable.

    Only relevant for campaigns which make calls. */
  callAgainMax?: number
  /** Custom schedule for call retries. Only relevant for campaigns which make calls. FIXME */
  callAgainCustom?: object
  /** Maximum number of times we try to reconnect dropped calls.

    Special values: -1 means use the system default, 0 means disable.

    Only relevant for campaigns which make calls. */
  reconnectMax?: number
  /** If false: only reconnect if the user was connected for at least 10 seconds. If true: only reconnect if they reached a next part. Only relevant for campaigns which make calls and have reconnect enabled. */
  reconnectOnProgress?: boolean
  /** Time this campaign was created. */
  createdAt?: string
  /** Published? A campaign can be "unpublished" until it's "launched". */
  published?: boolean
  /** If true contacts can be subscribed to this campaign. */
  open?: boolean
  /** This is the time a published, but not yet launched, campaign will be launched. Or was, if the campaign is already launched. */
  autoLaunchAt?: string
  /** A launched campaign is or has been running. It'll stay true even when a campaign is aborted. */
  launched?: boolean
  /** Time this campaign was launched (only valid if .launched is true). */
  launchedAt?: string
  /** Campaign is stopped. */
  aborted?: boolean
  /** Time this campaign was aborted (only valid if .aborted is true). */
  abortedAt?: string
  /** Archived campaigns aren't shown in the UI by default. That's all this do, it has no effect on the launch or aborted stage. */
  archived?: boolean
  /** Total number of subscriptions. This should be runsInProgress + runsInProgress + runsDone. */
  runs?: number
  /** Total number of subscriptions which have started and are not done yet. */
  runsInProgress?: number
  /** Total number of subscriptions which are paused, and not done yet. */
  runsPaused?: number
  /** Total number of subscriptions which have finished. */
  runsDone?: number
  /** Total number of subscriptions which are done, but with a (fatal) error. */
  runsError?: number
  /** Total number of subscriptions which had a (non-fatal) error, such as an undelivered SMS. They might be done or not. */
  runsWithFailures?: number
  /** Is Answering Machine Detection enabled? Only relevant for campaign with make voice calls. */
  amd?: boolean
  /** Version of the campaign, incremented whenever something is edited. Starts at 1. */
  version?: number
  /** Possible statuses, only relevant for CATI campaigns. */
  userStatuses?: string[]
  /** Files used in this campaign. */
  files?: object
}
export type PropertiesDaysofweek = {
  monday?: boolean
  tuesday?: boolean
  wednesday?: boolean
  thursday?: boolean
  friday?: boolean
  saturday?: boolean
  sunday?: boolean
}
export type PropertiesContact = {
  firstName?: string
  lastName?: string
  /** Sets the language field. Used for campaigns that support multiple languages. */
  language?: string
  /** List of integer group IDs this contact should be added to. */
  groups?: (number | string)[]
  /** Update custom fields as identified by their IDs.

    Empty values are valid, and will remove the current value. Only fields that are provided are updated. */
  customFields?: {
    [key: string]: string
  }
  /** An alterative way to set Custom fields. The keys of the objects are the names of the custom fields. We'll use the existing group ID, or create a new one if it doesn't exist.
    Empty values are valid, and will remove the current value. Only fields that are provided are updated.
    - names are compared case in-sensitive (so if you add "Gender", it'll re-use an existing column "gender").
    - if there are multiple possible matches, then we take the oldest existing custom field.
    - if the same custom field is used in customFields and customFieldsByName, then the value in customFieldsByName wins.
    - if the same custom field is given multiple times in customFieldsByName then it's not defined which one wins (`{"color": "green", "COLOR": "red"}`. The custom field color can now be either red or green). */
  customFieldsByName?: object
}
export type SchemaTopup = {
  /** engageSPARK ID */
  id?: string
  /** If false the Top-Up hasn't been successful yet, but is still being retried. */
  done?: boolean
  /** If true we executed the Top-Up successfully. See "error". */
  success?: boolean
  /** Error describing the problem, if any. */
  error?: string
  /** As given in the original request. */
  clientRef?: string
  /** The currency used, e.g. USD. */
  topupCurrency?: string
  /** The amount the user got on their phone. */
  topupReceived?: number
  /** What you got charged. */
  priceCharged?: number
  /** If the user has a contact in the engageSPARK system, this is the first name of that contact. */
  firstName?: string
  /** If the user has a contact in the engageSPARK system, this is the last name of that contact. */
  lastName?: string
}
export type SchemaDidsItem = {
  /** Phone number ID */
  id: number
  /** Organization ID */
  orgId: number
  /** Name of the phone number */
  name: string
  /** Date and time when the phone number was created */
  create: string
  /** Phone number */
  phoneNumber: string
  /** Keyword associated with the phone number */
  keyword: string
  /** Inbound call campaign ID, only voice-capable numbers have this */
  inboundCallCampId?: number
  /** Country code */
  countryCode: string
  /** Number of SMS hooks associated with the phone number */
  hookCount: number
  /** Number of call hooks associated with the phone number */
  callHookCount: number
  /** Number of WhatsApp hooks associated with the phone number */
  whatsappHookCount: number
  /** Is this an international phone number? */
  international: boolean
  /** Is this phone number registered with WhatsApp as a senderID? */
  whatsAppRegistered: boolean
  /** If this is true, the users can use this number to complete the whole WhatsApp Business SenderID registration process with Meta. */
  whatsAppMetaOtp?: boolean
  /** Is this phone number capable of sending SMS? */
  sms: boolean
  /** Is this phone number capable of sending WhatsApp messages? */
  whatsApp: boolean
  /** Is this phone number capable of making voice calls? */
  voice: boolean
}
export type SchemaCondition = (string | number | any)[][]
export type SchemaActionApi = {
  makeAPIRequest: {
    /** Array of key-value pairs to send as headers. */
    headers: {
      key: string
      value: string
    }[]
    /** HTTP method to use. */
    method: string
    /** Payload to send. */
    payload: string
    /** URL to send the request to. */
    url: string
  }
}
export type SchemaActionFinishFlow = {
  finishFlow: object
}
export type SchemaActionHangup = {
  hangup: object
}
export type SchemaActionJump = {
  jump: {
    /** the id of the part to jump to */
    nextPart: string
  }
}
export type SchemaAudioContent = {
  /** The URL of the audio file to be played. */
  playfile?: string
  /** The text to be spoken. */
  say?: string
  /** The voice to be used for the text-to-speech. */
  voice?: string
}
export type SchemaActionPlayAudio = {
  play: SchemaAudioContent
}
export type SchemaActionReplayQuestion = {
  replayQuestion: object
}
export type SchemaActionResendQuestion = {
  resendQuestion: object
}
export type SchemaActionSendEmail = {
  sendEmail: {
    from: string
    to: string
    toMultiple: string[]
    subject: string
    message: string
  }
}
export type SchemaSmsContent = {
  /** The text of the SMS message. */
  text: string
}
export type SchemaSmsTranslations = {
  translations: {
    [key: string]: SchemaSmsContent
  }
}
export type SchemaActionSendSmsPhone = {
  sendSMSToPhoneNumbers: SchemaSmsContent &
    SchemaSmsTranslations & {
      /** Phone numbers to send the SMS to. */
      phoneNumber: string[]
      /** Sender ID to use. */
      senderId: string
      /** Use the sender ID of the campaign. */
      useCampaignSenderId: string
    }
}
export type SchemaActionSendSms = {
  sendSMS: SchemaSmsContent &
    SchemaSmsTranslations & {
      /** Sender ID to use. */
      senderId: string
      /** Use the sender ID of the campaign. */
      useCampaignSenderId: string
    }
}
export type SchemaWhatsappContent = {
  /** The text of the WhatsApp message. */
  text?: string
  /** The URL of the audio file to be played. */
  audio?: string
  /** The URL of the document to be sent. */
  document?: string
  /** The URL of the image to be sent. */
  image?: string
  /** The URL of the video to be sent. */
  video?: string
}
export type SchemaWhatsappTranslations = {
  translations: {
    [key: string]: SchemaWhatsappContent
  }
}
export type SchemaActionSendWhatsapp = {
  sendWhatsapp: SchemaWhatsappContent &
    SchemaWhatsappTranslations & {
      /** Sender ID to use. */
      senderId: string
      /** Use the sender ID of the campaign. */
      useCampaignSenderId: string
      /** Register this message as a WhatsApp template. */
      registerTemplate: boolean
    }
}
export type SchemaActionSetPaused = {
  setPaused: {
    /** Campaign ID to pause. */
    campId?: number
    /** Pause current campaign. */
    current?: boolean
    /** Pause or unpause the subscription. */
    paused: boolean
  }
}
export type SchemaActionSubscribe = {
  subscribeToCampaign: {
    /** Campaign ID to subscribe to. */
    campaignId: number
    /** Name of the campaign to subscribe to, only use by the UI */
    campaignName: string
  }
}
export type SchemaActionTopup = {
  topup: {
    /** maxAmount is used if maxAmountTemplated is an empty string (before expanding the templates). */
    maxAmount: string
    /** maxAmountTemplated is used if it's not empty. Templates can be used, but the result should be a float. */
    maxAmountTemplated: string
    /** Desired amount to top up, templated. */
    desiredAmountTemplated: string
  }
}
export type SchemaActionTransfer = {
  transfer: {
    /** Phone number to transfer the call to. */
    to: string
  }
}
export type SchemaActionUnsubscribeAll = {
  unsubscribeAll: object
}
export type SchemaActionUnsubscribe = {
  unsubscribeCampaign: {
    /** Campaign ID to unsubscribe from. */
    campId: number
    /** Unsubscribe from the current campaign. */
    current: boolean
  }
}
export type SchemaActionUpdateContact = {
  updateContact: {
    /** - field is either a contact field:
          - contact.firstName
          - contact.lastName
          - contact.language
          - contact.dialingCode
          - contact.phoneNumber
          - contact.fullPhoneNumber
          - contact.email
          - or it's customfields.XXX, in which case it's the ID of a custom field.
          - call.result
          - call.anwer - answer time of a call (empty if not answered)
          - call.duration - call time in seconds (empty it not answered) */
    field: object
    /** - value is one of:
          - answer - the "label" answer given to a question
          - response - the raw response given to a question
          - constant - use the constant field
          - message - the sms if this action is attached to an incoming sms DID hook */
    value: string
    /** used if value is constant. Templating supported */
    constant: string
  }
}
export type SchemaAction = {
  /** Unique ID of the action. */
  id: string
} & (
  | SchemaActionApi
  | SchemaActionFinishFlow
  | SchemaActionHangup
  | SchemaActionJump
  | SchemaActionPlayAudio
  | SchemaActionReplayQuestion
  | SchemaActionResendQuestion
  | SchemaActionSendEmail
  | SchemaActionSendSmsPhone
  | SchemaActionSendSms
  | SchemaActionSendWhatsapp
  | SchemaActionSetPaused
  | SchemaActionSubscribe
  | SchemaActionTopup
  | SchemaActionTransfer
  | SchemaActionUnsubscribeAll
  | SchemaActionUnsubscribe
  | SchemaActionUpdateContact
)
export type SchemaInboundHookPayload = {
  /** Conditions for the hook to trigger */
  conditions: {
    conditions?: null | SchemaCondition[]
    unexpected?: boolean
  }
  actions: SchemaAction[]
  /** Label of the inbound hook */
  label: string
}
export type SchemaInboundHook = SchemaInboundHookPayload & {
  /** Unique ID of the inbound hook. */
  id: number
}
export type SchemaContact = {
  /** Unique engageSPARK ID of the contact. */
  id?: number
  /** International phone number of the contact, without leading "+". */
  fullPhoneNumber?: string
  /** First name of the contact. */
  firstName?: string
  /** Last name of the contact. */
  lastName?: string
  /** Language of the contact. */
  language?: string
  /** Group IDs this contact is in. The IDs can be groups or segments. */
  groups?: number[]
  /** All the custom field values set. */
  customFields?: {
    [key: string]: string
  }
  /** Name of the carrier. */
  carrier?: string
  /** Time this contact was created. */
  createdAt?: string
  /** Last time this contact was updated, either the contact itself, or a custom field, or groups have changed. */
  updatedAt?: string
  /** Last time this contact was used. */
  lastUseAt?: string
  /** If set, this contact will not be deleted by the organization's contact autodelete. */
  noAutodelete?: boolean
}
export type SchemaGroupType = 'NORMAL' | 'SMART'
export type SchemaGroup = {
  /** Unique engageSPARK ID of the group. */
  id: number
  /** Name of the group. Max 100 characters. */
  name: string
  /** Description of the group. */
  description: string
  type: SchemaGroupType
  /** Number of contacts in the group. */
  numberOfContacts: number
  /** Time this group was created. */
  createdAt: string
  conditions: null | SchemaCondition[]
  /** If set, the group is being recounted. Read-only. */
  recounting: boolean
  /** Do not automatically delete the contacts of this group if contact autodeletion is enabled for this org. You can still delete them manually. */
  noAutodelete: boolean
}
export type SchemaContactsSelection = {
  /** If true, all contacts are selected, possibly excluding some via excludedContactIds. Only excludedContactIds can be given. Else no contacts are selected by default, but they are added via (includedContactIds OR search OR phoneNumber) AND includedGroupIds. */
  allContactsAreSelected?: boolean
  /** List of contact IDs that are included in the selection. */
  includedContactIds?: number[]
  /** Search string to filter contacts by, full text search. */
  search?: string
  /** Phone number to filter contacts by, needs to match full phone number. */
  phoneNumber?: string
  /** List of group IDs that are included in the selection. */
  includedGroupIds?: number[]
  conditions?: SchemaCondition & any
}
export type SchemaCustomfield = {
  /** Unique engageSPARK ID of the custom field. */
  id: number
  /** Name of the custom field. */
  name: string
}
export type SchemaExport = {
  /** Unique export id. */
  id: number
  /** Time the export was created. */
  created: string
  /** The type of the export. */
  function: 'orgspend' | 'smsreport'
  /** The status of the export. */
  status: 'queued' | 'inprogress' | 'failed' | 'ready'
  files?: {
    /** The name of the file. */
    filename?: string
    /** The mime type of the file. */
    contentType?: string
    /** The size of the file in bytes. */
    size?: number
    /** The hash of the file. */
    sha256?: string
    /** The number of rows in the file. */
    rowCount?: number
  }[]
}
export type SchemaFile = {
  /** The name of the file. */
  filename: string
  /** The title of the file. */
  title: string
  /** The category of the file given on upload. */
  category: string
  /** Transcript previously set. This field isn't set automatically. */
  transcript: string
  /** The size of the file in bytes. */
  size: number
  /** The SHA1 checksum of the file. */
  sha1: string
  /** The date and time when the file was uploaded. */
  upload: string
  /** We automatically generate a thumbnail for the file if the file is a video file and set this property to true, which is available as /thumb/<filename>. */
  thumb: boolean
}
export type SchemaSnippet = {
  id: number
  category: string
  snippet: {
    [key: string]: any
  }
  userId?: number
  referencedFiles?: string[]
  referencedCampaigns?: number[]
  referencedContacts?: number[]
  /** This signals that the snippet references a campaign / contact / file that is no longer valid. */
  valid: boolean
  files: SchemaFile[]
}
export const {
  useGetAirtimePricingQuery,
  useAuthSigninMutation,
  useAuthTotpMutation,
  useAuthPasswordResetMutation,
  useAuthPasswordResetChangeMutation,
  useGetUserQuery,
  useSendSmsContactMutation,
  useSendSmsPhonenumberMutation,
  useCreateOrganizationMutation,
  useGetOrganizationQuery,
  useUpdateOrganizationMutation,
  useGetOrgBalanceQuery,
  useGetOrgBillingQuery,
  useGetOrgInvoiceListQuery,
  useGetOrgPurchaseListQuery,
  useGetOrgUserListQuery,
  useGetOrgUserQuery,
  useUpdateOrgUserMutation,
  useDeleteOrgUserMutation,
  useGetSmsListQuery,
  useGetSmsQuery,
  useCreateCampaignMutation,
  useGetCampaignQuery,
  useUpdateCampaignMutation,
  useDeleteCampaignMutation,
  useSubscribeContactMutation,
  useUnsubscribeContactMutation,
  useCreateSpeechPreviewMutation,
  useSendOrgTopupMutation,
  useGetOrgTopupsListQuery,
  useGetOrgTopupsQuery,
  useGetOrgDidsQuery,
  useGetOrgDidQuery,
  usePostOrgDidMutation,
  usePostDidRegisterWhatsAppMutation,
  useCreateOrgDidHookMutation,
  useUpdateOrgDidHookMutation,
  useDeleteOrgDidHookMutation,
  useCreateOrgDidCallHookMutation,
  useUpdateOrgDidCallHookMutation,
  useDeleteOrgDidCallHookMutation,
  useCreateOrgDidWaHookMutation,
  useUpdateOrgDidWaHookMutation,
  useDeleteOrgDidWaHookMutation,
  useGetOrgPhonenumberQuery,
  useCreateContactMutation,
  useGetContactQuery,
  useUpdateContactMutation,
  useGetOrgGroupsListQuery,
  useCreateOrgGroupMutation,
  useGetOrgGroupQuery,
  useUpdateOrgGroupMutation,
  useDeleteOrgGroupMutation,
  useAddOrgGroupsContactsMutation,
  useDeleteOrgGroupContactsMutation,
  useGetOrgCustomfieldsQuery,
  useCreateOrgCustomfieldMutation,
  useUpdateOrgCustomfieldMutation,
  useDeleteOrgCustomfieldMutation,
  useCreateOrgReportMutation,
  useGetExportsListQuery,
  useGetExportQuery,
  useGetExportsFileQuery,
  useGetFileListQuery,
  useUploadFileMutation,
  useDownloadFileQuery,
  useUpdateFileMutation,
  useDeleteFileMutation,
  useTranscribeFileMutation,
  useGetSnippetListQuery,
  useCreateSnippetMutation,
  useGetSnippetQuery,
  useUpdateSnippetMutation,
  useDeleteSnippetMutation,
} = injectedRtkApi
