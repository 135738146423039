import Button from '@mui/material/Button'
import { compose, equals, isEmpty, map, pathOr } from 'ramda'
import React, { Component } from 'react'
import DeleteDialog from '@/app/component/atom/delete-dialog'
import Loading from '@/app/component/guard/loading'
import FormLayout from '@/app/component/layout/form'
import Modal from '@/app/component/layout/modal'
import createSelectTable from '@/app/component/wrapper/table/selectable'
import validate, { hasRequiredError } from '@/app/service/validate'
import { selectCustomFieldsItemConfig } from '@/app/module/custom-fields/store/selectors'
import AddToGroup from '@/app/module/groups/component/add-to-group'
import RemoveFromGroup from '@/app/module/groups/component/remove'
import SendMessage from '@/app/module/logs/component/send'
import DownloadDialog from './list/download-dialog'
import ContactsTable from './list/table/contacts-table'

const defaultSendMessageProps = {
  totalRecipients: 0,
  recipients: [],
  contactIds: [],
  excludeIds: [],
  isAllContacts: false,
}

const defaultAddToGroupProps = {
  totalRecipients: 0,
  recipients: [],
  contactIds: [],
  excludeIds: [],
  isAllContacts: false,
  groups: [],
  search: '',
}

const defaultRemoveFromGroupProps = {
  totalRecipients: 0,
  recipients: [],
  contactIds: [],
  excludeIds: [],
  isAllContacts: false,
  groups: [],
  search: '',
}

const convertPropToSelection = (contacts) => ({
  totalRecipients: contacts.length,
  recipients: map((contact) => ({ label: '', value: contact }), contacts),
  contactIds: map((contact) => Number(contact), contacts),
  excludeIds: [],
  isAllContacts: false,
  includedGroupIds: [],
  search: '',
})

class Contacts extends Component {
  state = {
    deleteConfirm: false,
    deleteConfirmCustomField: false,
    editConfirmCustomField: false,
    deleteCustomFieldId: null,
    editCustomFieldId: null,
    downloadConfirm: false,
    deleteIds: [],
    sendMessageModal: false,
    addToGroupModal: false,
    removeFromGroupModal: false,
    sendMessageProps: defaultSendMessageProps,
    addToGroupProps: defaultAddToGroupProps,
    removeFromGroupProps: defaultRemoveFromGroupProps,
    downloadFileExtension: 'xlsx',
  }

  navigateToAddToGroupModal = () => {
    const { addToGroupContacts, getGroups, token, orgId } = this.props
    if (addToGroupContacts) {
      this.setState({
        addToGroupModal: true,
        addToGroupProps: {
          getGroups,
          token,
          orgId,
          ...convertPropToSelection(addToGroupContacts.split(',')),
        },
      })
    }
  }

  componentDidMount() {
    this.navigateToAddToGroupModal()
  }

  componentDidUpdate(prevProps) {
    const { orgId, selection, history, addToGroupContacts } = this.props
    if (prevProps.orgId && !isEmpty(prevProps.orgId) && prevProps.orgId !== orgId) {
      selection.setAll(false)
      history.push('/contacts')
    }
    if (addToGroupContacts !== prevProps.addToGroupContacts) {
      this.navigateToAddToGroupModal()
    }
  }

  sendMessageInit = () => {
    const { token, orgId, getSenderIdList } = this.props

    return getSenderIdList({
      token,
      orgId,
    })
  }

  addToGroupInit = () => {
    const { token, orgId, getGroups, groupsQuery } = this.props

    return getGroups({
      token,
      orgId,
      query: {
        ...groupsQuery,
      },
    })
  }

  removeFromGroupInit = () => {
    const { token, orgId, getGroups, groupsQuery } = this.props

    return getGroups({
      token,
      orgId,
      query: {
        ...groupsQuery,
      },
    })
  }

  sendMessage = (sendProps) => {
    const { token, orgId, sendMessage } = this.props

    return sendMessage({
      token,
      orgId,
      ...sendProps,
    })
  }

  addToGroup = (addToGroupProps) => {
    const { token, orgId, addToGroup } = this.props
    return addToGroup({
      token,
      orgId,
      ...addToGroupProps,
    })
  }

  removeFromGroup = (removeFromGroupProps) => {
    const { token, orgId, removeFromGroup } = this.props
    return removeFromGroup({
      token,
      orgId,
      ...removeFromGroupProps,
    })
  }

  deleteContacts = () => {
    const { token, orgId, selection, deleteItems, getContacts, pageSearchTerm = '' } = this.props

    const ids = this.state.deleteIds

    this.setState({
      deleteConfirm: false,
      deleteIds: [],
    })

    deleteItems({
      token,
      orgId,
      itemIds: ids,
    })
      .then(() =>
        getContacts({
          token,
          orgId,
          query: {
            ...this.query,
            searchTerm: pageSearchTerm,
          },
        }),
      )
      .then(() => {
        selection.setAll(false)
      })
  }

  deleteCustomField = () => {
    const { token, orgId, deleteCustomFieldsItem } = this.props

    const id = this.state.deleteCustomFieldId

    this.setState({
      deleteConfirmCustomField: false,
      deleteCustomFieldId: null,
      editConfirmCustomField: false,
      editCustomFieldId: null,
    })

    deleteCustomFieldsItem({
      token,
      orgId,
      itemId: id,
    })
  }

  editCustomField = ({ item }) => {
    const { token, orgId, saveCustomFieldsItem } = this.props

    const id = this.state.editCustomFieldId

    this.setState({
      editConfirmCustomField: false,
      editCustomFieldId: null,
    })

    saveCustomFieldsItem({
      token,
      orgId,
      itemId: id,
      item,
    })
  }

  downloadContacts = () => {
    const { token, orgId, selection, downloadItems } = this.props

    const query = {
      group: selection.groups,
      search: selection.search,
    }
    this.setState({
      downloadConfirm: false,
    })
    if (selection.isAll || selection.groups.length > 0 || selection.search !== '') {
      query.ex = selection.list
    } else {
      query.id = selection.list
    }

    downloadItems({
      token,
      orgId,
      ext: this.state.downloadFileExtension,
      query,
    })
  }

  handleSendMessage = (props) =>
    this.setState({
      sendMessageModal: true,
      sendMessageProps: props,
    })

  handleAddToGroup = (props) =>
    this.setState({
      addToGroupModal: true,
      addToGroupProps: props,
    })

  handleRemoveFromGroup = (props) =>
    this.setState({
      removeFromGroupModal: true,
      removeFromGroupProps: props,
    })

  handleDeleteItems = (ids) =>
    this.setState({
      deleteConfirm: true,
      deleteIds: ids,
    })

  handleEditCustomField = ({ id, title }) =>
    this.setState({
      editConfirmCustomField: title,
      editCustomFieldId: id,
    })

  handleDeleteCustomField = ({ id, title }) =>
    this.setState({
      deleteConfirmCustomField: title,
      deleteCustomFieldId: id,
    })

  handleDownloadItems = () =>
    this.setState({
      downloadConfirm: true,
    })

  localQuery = {}

  get query() {
    const { activeFilter, query, page, pageSearchTerm } = this.props
    const newQuery = {
      ...query,
      page,
      searchTerm: pageSearchTerm,
      filter: activeFilter,
    }
    if (equals(newQuery, this.localQuery)) {
      return this.localQuery
    }

    this.localQuery = newQuery
    return newQuery
  }

  render() {
    const {
      loading,
      error,
      selection,
      pageSearchTerm,
      activeFilter,
      getContacts,
      getSegments,
      senderIds,
      groups,
      personalizationList = {},
      history,
      values,
      token,
      orgId,
      getGroups,
      navigate,
      groupsQuery,
    } = this.props

    return (
      <Loading isLoading={loading} error={error}>
        <DeleteDialog
          text={`Are you sure you want to delete the ${
            selection.count > 1 ? `${selection.count} selected contacts` : 'selected contact'
          }?`}
          isOpen={this.state.deleteConfirm}
          onClose={() =>
            this.setState({
              deleteIds: [],
              deleteConfirm: false,
            })
          }
          onConfirm={() => this.deleteContacts()}
        />
        <DeleteDialog
          text={
            <React.Fragment>
              Are you sure you want to delete the custom field "<strong>{this.state.deleteConfirmCustomField}</strong>"?
            </React.Fragment>
          }
          isOpen={this.state.deleteConfirmCustomField}
          onClose={() =>
            this.setState({
              deleteCustomFieldId: null,
              deleteConfirmCustomField: false,
            })
          }
          onConfirm={() => this.deleteCustomField()}
        />
        <DownloadDialog
          isOpen={this.state.downloadConfirm}
          extension={this.state.downloadFileExtension}
          onChange={({ value }) =>
            this.setState({
              downloadFileExtension: value,
            })
          }
          onClose={() =>
            this.setState({
              downloadConfirm: false,
            })
          }
          onConfirm={() => this.downloadContacts()}
        />
        <Modal
          title="Edit custom field"
          isOpen={this.state.editConfirmCustomField}
          onClose={() =>
            this.setState({
              editCustomFieldId: null,
              editConfirmCustomField: false,
            })
          }
        >
          <FormLayout
            loading={loading}
            headers={[
              {
                title: 'Name',
                fields: ['name'],
              },
            ]}
            validation={{
              name: validate(hasRequiredError),
            }}
            config={selectCustomFieldsItemConfig()}
            values={{
              name: this.state.editConfirmCustomField,
            }}
            onSubmit={({ item }) => this.editCustomField({ item })}
            Actions={({ changed, hasErrors }) => (
              <>
                <Button color="primary" variant="contained" type="submit" disabled={loading || !changed || hasErrors}>
                  Save
                </Button>
              </>
            )}
          />
          <div
            style={{
              paddingLeft: '20px',
            }}
          >
            <Button
              style={{
                marginTop: '-90px',
              }}
              size="small"
              color="secondary"
              onClick={() =>
                this.setState({
                  deleteConfirmCustomField: this.state.editConfirmCustomField,
                  deleteCustomFieldId: this.state.editCustomFieldId,
                })
              }
            >
              Delete Custom Field
            </Button>
          </div>
        </Modal>
        <Modal
          isOpen={this.state.sendMessageModal}
          icon="sms"
          title="Send message"
          onClose={() => this.setState({ sendMessageModal: false })}
        >
          <SendMessage
            hydrate={() => this.sendMessageInit()}
            error={{}}
            senderIds={senderIds}
            personalizationList={personalizationList}
            {...this.state.sendMessageProps}
            closeHandler={() =>
              this.setState({
                sendMessageModal: false,
                sendMessageProps: defaultSendMessageProps,
              })
            }
            sendMessage={(sendArgs) =>
              this.sendMessage(sendArgs).then(() => {
                selection.setAll(false)
              })
            }
          />
        </Modal>
        <Modal
          isOpen={this.state.addToGroupModal}
          icon="group_add"
          title="Add Contacts to Groups"
          onClose={() => {
            this.setState({ addToGroupModal: false })
            navigate({
              ...this.query,
              history,
              page: 1,
              searchTerm: pageSearchTerm,
              filter: activeFilter,
            })
          }}
        >
          <AddToGroup
            token={token}
            orgId={orgId}
            error={{}}
            groups={groups}
            activeFilter={activeFilter}
            personalizationList={personalizationList}
            {...this.state.addToGroupProps}
            closeHandler={() => {
              this.setState({
                addToGroupModal: false,
                addToGroupProps: defaultAddToGroupProps,
              })
              navigate({
                ...this.query,
                history,
                page: 1,
                searchTerm: pageSearchTerm,
                filter: activeFilter,
              })
            }}
            addToGroup={(addToGroupArgs) =>
              this.addToGroup(addToGroupArgs).then(() => {
                selection.setAll(false)
                getContacts({
                  token,
                  orgId,
                  query: {
                    ...this.query,
                    ...(pageSearchTerm && {
                      searchTerm: pageSearchTerm,
                    }),
                    ...(activeFilter?.value && {
                      filter: activeFilter,
                    }),
                  },
                })
                getGroups({
                  token,
                  orgId,
                  query: {
                    ...groupsQuery,
                  },
                })
              })
            }
          />
        </Modal>
        <Modal
          isOpen={this.state.removeFromGroupModal}
          icon="remove_circle"
          title="Remove Contacts from Group"
          onClose={() => this.setState({ removeFromGroupModal: false })}
        >
          <RemoveFromGroup
            hydrate={() => this.removeFromGroupInit()}
            error={{}}
            selectedGroup={pathOr(undefined, ['value'], activeFilter)}
            groups={groups}
            history={history}
            personalizationList={personalizationList}
            {...this.state.removeFromGroupProps}
            closeHandler={() =>
              this.setState({
                removeFromGroupModal: false,
                removeFromGroupProps: defaultRemoveFromGroupProps,
              })
            }
            removeFromGroup={(removeFromGroupArgs) =>
              this.removeFromGroup(removeFromGroupArgs).then(() => {
                selection.setAll(false)
              })
            }
          />
        </Modal>
        <ContactsTable
          {...this.props}
          data={values}
          selection={selection}
          query={this.query}
          addContactsToGroup={this.handleAddToGroup}
          deleteCustomField={this.handleDeleteCustomField}
          deleteItems={this.handleDeleteItems}
          downloadItems={this.handleDownloadItems}
          editCustomField={this.handleEditCustomField}
          getData={getContacts}
          getSegments={getSegments}
          removeContactsFromGroup={this.handleRemoveFromGroup}
          sendMessage={this.handleSendMessage}
        />
      </Loading>
    )
  }
}

export default compose(createSelectTable)(Contacts)
