import Button from '@mui/material/Button'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { map } from 'ramda'
import { Column, TableColumn } from '@/config/types'
import CountryFlag from '@/app/component/atom/flag'
import IconText from '@/app/component/layout/icon-text'
import TableRowActions from '@/app/component/layout/power-table/actions'
import { formatDate, formatDateTime, formatPhoneNumber, parsePhoneNumber } from './format'

export const generateTableColumns = (columns: Column[], tz: string, action?: any): TableColumn[] =>
  map(
    (singleColumn) => ({
      header: singleColumn.label,
      accessor: singleColumn.key,
      editable: singleColumn.editable,
      sticky: singleColumn.sticky,
      ...(singleColumn.display === 'phone' && {
        cell: (value: string) => {
          const { country = '' } = parsePhoneNumber(value)
          return (
            <IconText>
              <CountryFlag code={country} />
              <Typography component="span">{formatPhoneNumber(`+${value}`)}</Typography>
            </IconText>
          )
        },
      }),
      ...(singleColumn.display === 'date' && {
        cell: (value: string) => (
          <IconText>
            <Icon>calendar_today</Icon>
            <Typography component="span">{formatDate(value, tz)}</Typography>
          </IconText>
        ),
      }),
      ...(singleColumn.display === 'datetime' && {
        cell: (value: string) => (
          <IconText>
            <Icon>calendar_today</Icon>
            <Typography component="span">{formatDateTime(value, tz)}</Typography>
          </IconText>
        ),
      }),
      ...(singleColumn.submitAction && {
        cell: (value: string) => (
          <Button
            variant="contained"
            color="primary"
            onClick={() => singleColumn.submitAction.onClick(value)}
            endIcon={singleColumn.submitAction.icon}
          >
            {singleColumn.submitAction.label}
          </Button>
        ),
      }),
      ...(singleColumn.actions && {
        cell: (value: string) => <TableRowActions itemId={value} config={singleColumn.actions} />,
      }),
      ...(singleColumn.display === 'delete' && {
        cell: (value: string) => (
          <IconButton onClick={() => action(value)}>
            <Icon>delete</Icon>
          </IconButton>
        ),
      }),
    }),
    columns,
  )

export const escapeSpecialCharactersForRegex = (word: string) => word.replace(/[\\^$.|?*+()[\]{}]/g, '\\$&')
