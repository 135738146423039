import CallIcon from '@mui/icons-material/Call'
import Button from '@mui/material/Button'
import React from 'react'
import Loading from '@/app/component/guard/loading'
import { SelectOption } from '@/app/types'
import { useSelector } from 'react-redux'
import { selectLanguages } from '@/app/module/utils/store/selectors'
import { Form } from '@/app/component/form'
import { useGetCustomFields } from '@/app/module/custom-fields/hooks/use-get-custom-fields'
import { styled } from '@mui/material'
import { ContactValues } from '@/app/module/contacts/types'
import { useGetContact } from '@/app/module/contacts/hooks/use-get-contact'
import { useAppDispatch } from '@/app/store/redux-hooks'
import { toggleCallDialog } from '@/app/module/user/store/actions'
import { useUpdateContact } from '@/app/module/contacts/hooks/use-update-contact'
import { ActionsContainer, FormContainer } from './common'
import DeleteProtectionToggle from './delete-protection-toggle'
import { getUpdateContactFields, getUpdateContactSchema } from './specs'

type Props = {
  itemId: number

  onExit: () => void
}

const UpdateContactForm: React.FC<Props> = ({ itemId, onExit }) => {
  const dispatch = useAppDispatch()
  const languageOptions = useSelector(selectLanguages) as SelectOption<string>[]
  const {
    data: cfData,
    isFetching: isCFFetching,
    isLoading: isCFLoading,
    isUninitialized: isCFUninitialized,
  } = useGetCustomFields()
  const {
    data: contact,
    isFetching: isContactFetching,
    isLoading: isContactLoading,
    isUninitialized: isContactUninitialized,
  } = useGetContact(itemId)
  const [updateContact, { isLoading: isUpdating }] = useUpdateContact()

  const [noAutodelete, setNoAutodelete] = React.useState(contact?.noAutodelete || false)
  const [changed, setChanged] = React.useState(false)
  React.useEffect(() => {
    setNoAutodelete(contact?.noAutodelete || false)
  }, [contact?.noAutodelete])
  const handleNoAutodeleteChange = React.useCallback((value: boolean) => {
    setNoAutodelete(value)
    setChanged(true)
  }, [])

  const customFields = React.useMemo(() => cfData?.customfields || [], [cfData])

  const fields = React.useMemo(
    () => getUpdateContactFields(customFields, languageOptions),
    [customFields, languageOptions],
  )
  const schema = React.useMemo(() => getUpdateContactSchema(customFields), [customFields])

  const defaultValues = React.useMemo(
    () => ({
      firstName: contact?.firstName || '',
      lastName: contact?.lastName || '',
      fullPhoneNumber: contact?.fullPhoneNumber ? `+${contact.fullPhoneNumber}` : '',
      language: contact?.language || '',
      ...customFields.reduce(
        (acc, { id }) => ({
          ...acc,
          [`customFields${id}`]: contact?.customFields?.[id] || '',
        }),
        {},
      ),
      createdAt: contact?.createdAt || '',
      updatedAt: contact?.updatedAt || '',
      lastUseAt: contact?.lastUseAt || '',
      carrier: contact?.carrier || '',
    }),
    [contact, customFields],
  )

  const handleSubmit = React.useCallback(
    async (data: ContactValues) => {
      const updated = await updateContact(itemId, data, noAutodelete)
      if (!updated) {
        return
      }

      onExit()
    },
    [itemId, noAutodelete, updateContact, onExit],
  )

  const showLoading =
    isUpdating ||
    isCFFetching ||
    isCFLoading ||
    isCFUninitialized ||
    isContactFetching ||
    isContactLoading ||
    isContactUninitialized
  const showForm = !!contact

  return (
    <Loading isLoading={showLoading} testId="contact-item">
      <CallContainer>
        <Button
          id="item-contact-call-button"
          style={{ zIndex: 999 }}
          size="small"
          variant="outlined"
          color="primary"
          onClick={() =>
            dispatch(
              toggleCallDialog({
                contactId: itemId,
                contactName: contact?.firstName || `contact (${itemId})`,
                isOpen: true,
              }),
            )
          }
          startIcon={<CallIcon />}
        >
          <span
            style={{
              maxWidth: '150px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            Call {contact?.firstName || contact?.fullPhoneNumber}
          </span>
        </Button>
      </CallContainer>
      {showForm && (
        <FormContainer id="contact-item-form">
          <Form
            defaultValues={defaultValues}
            fields={fields}
            schema={schema}
            shouldReset
            onSubmit={handleSubmit}
            renderActions={(formState) => (
              <ActionsContainer>
                <Button id="contact-back-button" variant="outlined" onClick={() => onExit()}>
                  Back
                </Button>
                <Button
                  color="primary"
                  id="contact-save-button"
                  type="submit"
                  variant="contained"
                  disabled={showLoading || !formState.isValid || (!formState.isDirty && !changed)}
                >
                  Save
                </Button>
              </ActionsContainer>
            )}
          >
            <DeleteProtectionToggle checked={noAutodelete} isGroup={false} onChange={handleNoAutodeleteChange} />
          </Form>
        </FormContainer>
      )}
    </Loading>
  )
}

const CallContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',
  marginTop: -30,
  paddingBottom: 10,
})

export default UpdateContactForm
