import { useAppDispatch } from '@/app/store/redux-hooks'
import { useSelector } from 'react-redux'
import { selectOrgId } from '@/app/module/user/store/selectors'
import { esApi, getErrorMessage } from '@/app/store/api'
import { useCallback } from 'react'
import { createNotification } from '@/app/module/notifications/store/actions'
import { ERROR_DELETE_GROUPS_ITEM_SERVER } from '@/app/module/groups/definitions'

export const useDeleteGroup = () => {
  const dispatch = useAppDispatch()
  const orgId = useSelector(selectOrgId)

  const [deleteGr, res] = esApi.endpoints.deleteOrgGroup.useMutation()

  const deleteGroup = useCallback(
    async (groupId: number) => {
      try {
        await deleteGr({ orgId: orgId.toString(), groupId: groupId.toString() }).unwrap()
        dispatch(
          createNotification({
            'delete-group-success': {
              type: 'success',
              message: 'Group has been deleted successfully',
            },
          }),
        )
        return true
      } catch (error) {
        dispatch(
          createNotification({
            'delete-group-failure': {
              type: 'error',
              message: getErrorMessage(error, ERROR_DELETE_GROUPS_ITEM_SERVER),
            },
          }),
        )
        return false
      }
    },
    [orgId, deleteGr, dispatch],
  )

  return [deleteGroup, res] as const
}
