import { useSelector } from 'react-redux'
import { selectOrgId } from '@/app/module/user/store/selectors'
import { esApi } from '@/app/store/api'
import { useEffect } from 'react'
import { useAppDispatch } from '@/app/store/redux-hooks'
import { createNotification } from '@/app/module/notifications/store/actions'
import { ERROR_GET_INVOICES_SERVER } from '@/app/module/payments/definitions'

export const useGetInvoices = () => {
  const dispatch = useAppDispatch()
  const orgId = useSelector(selectOrgId)
  const res = esApi.endpoints.getOrgInvoiceList.useQuery({ orgId: orgId.toString() })

  useEffect(() => {
    if (!res.error) {
      return
    }
    dispatch(
      createNotification({
        'get-invoices': {
          type: 'error',
          message: ERROR_GET_INVOICES_SERVER,
        },
      }),
    )
  }, [res.error, dispatch])

  return res
}
