// ACTIONS

export const ACTION_GET_GROUPS_ITEM_REQUEST = '@actions/GROUPS/GET_ITEM_REQUEST'
export const ACTION_GET_GROUPS_ITEM_SUCCESS = '@actions/GROUPS/GET_ITEM_SUCCESS'
export const ACTION_GET_GROUPS_ITEM_FAILURE = '@actions/GROUPS/GET_ITEM_FAILURE'

export const ACTION_GET_GROUPS_REQUEST = '@actions/GROUPS/GET_LIST_REQUEST'
export const ACTION_GET_GROUPS_SUCCESS = '@actions/GROUPS/GET_LIST_SUCCESS'
export const ACTION_GET_GROUPS_FAILURE = '@actions/GROUPS/GET_LIST_FAILURE'

export const ACTION_RESET_GROUPS_QUERY = '@actions/GROUPS/RESET_QUERY'

export const ACTION_ADD_TO_GROUP_REQUEST = '@actions/GROUPS/ADD_TO_GROUP_REQUEST'
export const ACTION_ADD_TO_GROUP_SUCCESS = '@actions/GROUPS/ADD_TO_GROUP_SUCCESS'
export const ACTION_ADD_TO_GROUP_FAILURE = '@actions/GROUPS/ADD_TO_GROUP_FAILURE'

export const ACTION_REMOVE_FROM_GROUP_REQUEST = '@actions/GROUPS/REMOVE_FROM_GROUP_REQUEST'
export const ACTION_REMOVE_FROM_GROUP_SUCCESS = '@actions/GROUPS/REMOVE_FROM_GROUP_SUCCESS'
export const ACTION_REMOVE_FROM_GROUP_FAILURE = '@actions/GROUPS/REMOVE_FROM_GROUP_FAILURE'

export const ACTION_GROUPS_NAVIGATE = '@actions/GROUPS/NAVIGATE'

// ERRORS
export const ERROR_GET_GROUPS_SERVER = 'Failed to load groups. Please try again later.'
export const ERROR_SAVE_GROUPS_ITEM_SERVER = 'Failed to save group. Please try again later.'
export const ERROR_GET_GROUPS_ITEM_CONTACTS_SERVER =
  'Failed to get contacts for the selected group. Please try again later.'
export const ERROR_DELETE_GROUPS_ITEM_SERVER = 'Failed to delete group. Please try again later.'
/* eslint-disable import/prefer-default-export */
export const operands = [
  {
    title: 'Regular Operators',
    data: [
      {
        label: 'equals',
        value: 'equal',
        component: 'TextField',
      },
      {
        label: 'does not equal',
        value: 'not_equal',
        component: 'TextField',
      },
      {
        label: 'is greater than',
        value: '>',
        component: 'TextField',
      },
      {
        label: 'is greater than or equal to',
        value: '>=',
        component: 'TextField',
      },
      {
        label: 'is less than',
        value: '<',
        component: 'TextField',
      },
      {
        label: 'is less than or equal to',
        value: '<=',
        component: 'TextField',
      },
      {
        label: 'contains',
        value: 'contain',
        component: 'TextField',
      },
      {
        label: 'does not contain',
        value: 'not_contain',
        component: 'TextField',
      },
      {
        label: 'is empty',
        value: 'empty',
        component: 'Fragment',
      },
      {
        label: 'is not empty',
        value: 'not_empty',
        component: 'Fragment',
      },
      {
        label: 'starts with',
        value: 'startsWith',
        component: 'TextField',
      },
      {
        label: 'does not start with',
        value: 'notStartsWith',
        component: 'TextField',
      },
      {
        label: 'is before',
        value: 'before',
        component: 'DateTime',
      },
      {
        label: 'is after',
        value: 'after',
        component: 'DateTime',
      },
      {
        label: 'equals any of',
        value: 'in',
        component: 'List',
      },
      {
        label: 'does not equal any of',
        value: 'notIn',
        component: 'List',
      },
      {
        label: 'starts with any of',
        value: 'anyStartsWith',
        component: 'List',
      },
      {
        label: 'does not start with any of',
        value: 'noneStartsWith',
        component: 'List',
      },
    ],
  },
  {
    title: 'Randomize Operators',
    data: [
      {
        label: 'mod 2 equals',
        value: 'mod2_equal',
        component: 'TextField',
      },
      {
        label: 'mod 3 equals',
        value: 'mod3_equal',
        component: 'TextField',
      },
      {
        label: 'mod 4 equals',
        value: 'mod4_equal',
        component: 'TextField',
      },
      {
        label: 'mod 5 equals',
        value: 'mod5_equal',
        component: 'TextField',
      },
    ],
  },
]
