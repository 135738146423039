import { esApi } from '@/app/store/api'
import { useAppDispatch } from '@/app/store/redux-hooks'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import { ERROR_GET_CUSTOM_FIELDS_SERVER } from '@/app/module/custom-fields/definitions'
import { selectOrgId } from '@/app/module/user/store/selectors'
import { createNotification } from '@/app/module/notifications/store/actions'

export const useGetCustomFields = () => {
  const dispatch = useAppDispatch()
  const orgId = useSelector(selectOrgId)
  const res = esApi.endpoints.getOrgCustomfields.useQuery({ orgId: orgId.toString() }, { skip: !orgId })

  useEffect(() => {
    if (!res.error) {
      return
    }
    dispatch(
      createNotification({
        'get-receipts': {
          type: 'error',
          message: ERROR_GET_CUSTOM_FIELDS_SERVER,
        },
      }),
    )
  }, [res.error, dispatch])

  return res
}
