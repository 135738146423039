import ConfirmationDialog from '@/app/module/campaigns/component/item/manage/subscriptions/confirmation-dialog'
import React from 'react'

type Props = {
  isGroup: boolean
  open: boolean
  onClose: () => void
  onConfirm: () => void
}

const DeleteProtectionConfirmDialog: React.FC<Props> = ({ isGroup, open, onClose, onConfirm }) => (
  <ConfirmationDialog
    id="delete-protection-confirm-dialog"
    deleteButtonText="Disable"
    isOpen={open}
    title="Disable Auto-delete protection"
    icon="priority_high"
    text={
      <>
        <br />
        Are you sure you want to disable protection for this {isGroup ? 'group' : 'contact'}? If auto-deletion is
        enabled and the {isGroup ? 'contacts in this group match' : 'contact matches'} the conditions, it may be removed
        at any moment.
      </>
    }
    onClose={onClose}
    onConfirm={onConfirm}
  />
)

export default DeleteProtectionConfirmDialog
