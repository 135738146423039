import { GROUPS_DEFAULT_FIELD_CHART_LIMIT } from '@/app/module/contacts/definitions'
import { z } from 'zod'

export const fields = {
  name: {
    fieldProps: { label: 'Name', name: 'name', required: true },
    type: 'text',
  },
} as const

export const schema = z.object({
  name: z
    .string()
    .min(1)
    .max(GROUPS_DEFAULT_FIELD_CHART_LIMIT, {
      message: `Name cannot be longer than ${GROUPS_DEFAULT_FIELD_CHART_LIMIT} characters`,
    }),
})
