import { Dialog, DialogContent, DialogTitle, Typography } from '@mui/material'
import React from 'react'

type Props = {}

const ConnectMetaLoadingDialog: React.FC<Props> = () => {
  return (
    <Dialog open>
      <DialogTitle>Connecting with Meta...</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          We're connecting your WhatsApp Sender ID with Meta. Please finish the registration process in the Meta popup.
        </Typography>
        <Typography>
          <strong>Please DO NOT CLOSE this window</strong> while we complete the registration process.
        </Typography>
      </DialogContent>
    </Dialog>
  )
}

export default ConnectMetaLoadingDialog
