import { styled } from '@mui/material'

export const FormContainer = styled('div')({
  paddingBottom: 40,
})

export const ActionsContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  position: 'fixed',
  gap: 10,
  bottom: 0,
  left: 0,
  display: 'flex',
  justifyContent: 'center',
  paddingTop: 8,
  paddingBottom: 8,
  width: '100%',
  zIndex: 2,
}))
