import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { green, red } from '@mui/material/colors'
import React from 'react'
import { makeStyles } from 'tss-react/mui'
import moment from 'moment'
import CustomTooltip from '@/app/component/atom/tooltip'
import Loading from '@/app/component/guard/loading'
import ContentLayout from '@/app/component/layout/content'
import PageTitle from '@/app/component/layout/page-title'
import AccessWrapper from '@/app/component/wrapper/access'
import createHydrated from '@/app/component/wrapper/hydratable'
import { downloadBlob } from '@/app/service/util/download'
import BillingBlock from '@/app/module/dashboard/component/planBlock'
import TOTPWarning from '@/app/module/dashboard/component/totpWarning'
import { FUNDS_TRANSFER_ROLES, PAYMENT_ROLES } from '@/app/module/payments/roles'
import { useRequirePermission } from '@/app/module/user/store/ts/hooks'
import { useGetReceipts } from '@/app/module/payments/hooks/use-get-receipts'
import { useGetInvoices } from '@/app/module/payments/hooks/use-get-invoices'
import AutoRecharge from './autoRecharge'
import Invoices from './invoices'
import Payment from './payment'
import ProformaInvoice from './proforma-invoice'
import Receipts from './receipts'
import SpendNotificationsContainer from './spend/spend-notifications.container'
import TransferFundsModal from './transferFunds'
import { getDisabledAutoRechargeReason, getDisabledCreditCardReason } from './utils'

export const TABS = {
  PAY: 'pay',
  RECEIPTS: 'receipts',
  INVOICES: 'invoice',
}

const useStyles = makeStyles()(() => ({
  cardWrap: {
    marginBottom: '110px',
  },
  billingBox: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    padding: '24px 0',
  },
  autoRechargeBtnCont: {
    marginTop: '5px',
  },
  headerButtons: {
    marginTop: '2rem',
    marginBottom: '3rem',
    marginLeft: '10px',
    display: 'flex',
  },
  headerButton: {
    marginLeft: '10px',
  },
}))

function Billing(props) {
  const {
    token,
    orgId,
    error,
    user,
    payment,
    countries,
    hydrate,
    saveCard,
    chargeCard,
    deleteCard,
    loading,
    autoRecharge = {},
    activeOrg = {},
    organizations = [],
    role,
    generateProformaInvoice,
    createNotification,
    transferFunds,
    transferFundsLoading,
    showOrgWarning,
    showTotpWarning,
    totpEnabled,
  } = props

  const { data: invoices } = useGetInvoices()
  const { data: receipts } = useGetReceipts()

  const autoRechargeAction = ({ autoRecharge: value, autoRechargeThreshold, autoRechargeAmount }) => {
    const { autoRechargeCard } = props
    autoRechargeCard({
      orgId,
      token,
      item: {
        autoRecharge: value,
        autoRechargeThreshold,
        autoRechargeAmount,
      },
    })
  }

  const [autoRechargeModal, setAutoRechargeModal] = React.useState(false)
  const [proformaModal, setProformaModal] = React.useState(false)
  const [spendModal, setSpendModal] = React.useState(false)
  const [transferModal, setTransferModal] = React.useState(false)
  const { classes } = useStyles()

  const canTransferFund = organizations.length > 1 && FUNDS_TRANSFER_ROLES.includes(activeOrg.role)
  const hasCreditCard = payment?.cards?.length > 0
  const hasCCPermission = useRequirePermission('creditCard')
  const hasBillingRoles = PAYMENT_ROLES.includes(activeOrg.role)
  const canMakePayment = hasBillingRoles && hasCCPermission
  const hasInvoices = invoices?.months?.length > 0
  const hasReceipts = receipts?.length > 0

  return (
    <Loading isLoading={loading} error={error}>
      {autoRechargeModal && (
        <AutoRecharge
          autoRecharge={autoRecharge}
          onClose={() => {
            setAutoRechargeModal(false)
          }}
          onSuccess={(value) => {
            autoRechargeAction(value)
            setAutoRechargeModal(false)
          }}
        />
      )}
      {proformaModal && (
        <ProformaInvoice
          loading={loading}
          onClose={() => {
            setProformaModal(false)
          }}
          onSave={(value) => {
            generateProformaInvoice({
              orgId,
              token,
              item: {
                ...value,
                amount: parseFloat(value.amount),
              },
            }).then((res) => {
              if (!(res instanceof Error)) {
                setProformaModal(false)
                res
                  .blob()
                  .then((blob) => {
                    downloadBlob(blob, `proform-invoice-${moment(value.date).format('YYYY/MM/DD')}.pdf`)
                  })
                  .catch(() => {
                    createNotification({
                      'proforma-invoice': {
                        type: 'error',
                        message: 'Unable to download your proforma invoice. Please try again later.',
                      },
                    })
                  })
              } else {
                createNotification({
                  'proforma-invoice': {
                    type: 'error',
                    message: 'Unable to download your proforma invoice. Please try again later.',
                  },
                })
              }
            })
          }}
        />
      )}
      {spendModal && <SpendNotificationsContainer open={spendModal} onClose={() => setSpendModal(false)} />}
      {transferModal && (
        <TransferFundsModal
          activeOrg={activeOrg}
          balance={autoRecharge}
          loading={transferFundsLoading}
          organizations={organizations}
          onClose={() => {
            setTransferModal(false)
          }}
          onSubmit={(data) => {
            transferFunds({
              token,
              orgId,
              item: data,
            })
            setTransferModal(false)
          }}
        />
      )}
      {showTotpWarning && <TOTPWarning showOrgWarning={showOrgWarning} totpEnabled={totpEnabled} />}
      <ContentLayout>
        <div
          style={{
            paddingBottom: '1px',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <PageTitle>Billing</PageTitle>
            <div className={classes.headerButtons}>
              <Button
                className={classes.headerButton}
                variant="contained"
                color="primary"
                onClick={() => setProformaModal(true)}
              >
                Create a Proforma Invoice
              </Button>
              {canTransferFund && (
                <CustomTooltip title="Transfer funds to another organization">
                  <div className={classes.headerButton}>
                    <Button
                      disabled={!canTransferFund}
                      variant="contained"
                      color="primary"
                      onClick={() => setTransferModal(true)}
                    >
                      Transfer funds
                    </Button>
                  </div>
                </CustomTooltip>
              )}
            </div>
          </div>
          <div
            style={{
              width: '100%',
              margin: 'auto',
            }}
          >
            <Grid
              container
              spacing={2}
              style={{
                marginBottom: '15px',
              }}
            >
              <Grid item md={8} xs={12}>
                <BillingBlock
                  style={{
                    height: '100%',
                  }}
                >
                  <div style={{ padding: '24px' }}>
                    <AccessWrapper
                      fallback={
                        <Typography component="div" variant="h6">
                          {getDisabledCreditCardReason(hasBillingRoles, hasCCPermission)}
                        </Typography>
                      }
                      hasAccess={canMakePayment}
                    >
                      <Payment
                        token={token}
                        orgId={orgId}
                        hydrate={hydrate}
                        saveCard={saveCard}
                        chargeCard={chargeCard}
                        deleteCard={deleteCard}
                        loading={payment.loading}
                        initialized={payment.initialized}
                        cards={payment.cards}
                        user={user}
                        countries={countries}
                      />
                    </AccessWrapper>
                  </div>
                </BillingBlock>
              </Grid>
              <Grid item md={4} xs={12}>
                <BillingBlock className="billing-cards-block" isLoading={autoRecharge.loading}>
                  <CustomTooltip
                    title={
                      !receipts?.length ? 'Auto-recharging will be available after your first successful payment.' : ''
                    }
                  >
                    <div className={classes.billingBox}>
                      <div className={classes.billingBox} style={{ flex: 1 }}>
                        <div>
                          <Typography variant="h5" color="textSecondary">
                            Current Balance
                          </Typography>
                          <Typography variant="h3" color="primary">
                            ${autoRecharge.balanceHuman}
                          </Typography>
                        </div>
                        <div>
                          <AccessWrapper
                            fallback={
                              <div style={{ padding: 16 }}>
                                <Typography>
                                  {getDisabledAutoRechargeReason(hasBillingRoles, hasCCPermission)}
                                </Typography>
                              </div>
                            }
                            hasAccess={canMakePayment}
                          >
                            {hasCreditCard && (
                              <>
                                <Typography id="auto-recharge-text" variant="subtitle2" color="textSecondary">
                                  Auto recharge is&nbsp;
                                  {autoRecharge.autoRecharge ? (
                                    <strong
                                      style={{
                                        color: green.A700,
                                      }}
                                    >
                                      ENABLED
                                    </strong>
                                  ) : (
                                    <strong
                                      style={{
                                        color: red.A700,
                                      }}
                                    >
                                      DISABLED
                                    </strong>
                                  )}
                                  &nbsp;
                                  {hasReceipts && autoRecharge.autoRecharge && (
                                    <strong>
                                      (
                                      <Link href="#" onClick={() => setAutoRechargeModal(true)}>
                                        Edit settings
                                      </Link>
                                      )
                                    </strong>
                                  )}
                                </Typography>
                                {hasReceipts && !autoRecharge.autoRecharge && (
                                  <div className={classes.autoRechargeBtnCont}>
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      onClick={() => setAutoRechargeModal(true)}
                                    >
                                      Enable Auto Recharge
                                    </Button>
                                  </div>
                                )}
                              </>
                            )}
                            {!hasCreditCard && (
                              <Typography id="add-credit-card-text" variant="subtitle2" color="textSecondary">
                                Add a Credit Card to start adding funds
                              </Typography>
                            )}
                          </AccessWrapper>
                        </div>
                      </div>
                      <div>
                        <Button variant="contained" onClick={() => setSpendModal(true)}>
                          Edit Notifications and Spend Limits
                        </Button>
                      </div>
                    </div>
                  </CustomTooltip>
                </BillingBlock>
              </Grid>
              <Grid item md={12} xs={12}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  {hasReceipts && (
                    <BillingBlock
                      className="billing-receipts-block"
                      style={{
                        height: '100%',
                        marginRight: '5px',
                      }}
                      title="Purchase receipts"
                    >
                      <Receipts orgId={orgId} />
                    </BillingBlock>
                  )}
                  {hasInvoices && (
                    <BillingBlock
                      className="billing-invoices-block"
                      style={{
                        height: '100%',
                        marginLeft: '5px',
                      }}
                      title="Usage Statements"
                      subtitle="Usage Statements can be downloaded starting with the 6th of the following month."
                    >
                      <Invoices orgId={orgId} role={role} />
                    </BillingBlock>
                  )}
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </ContentLayout>
    </Loading>
  )
}

export default createHydrated(Billing)
