import { UpdateContactApiArg } from '@/__generated/es-api'
import { esApi, getErrorMessage } from '@/app/store/api'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { selectOrgId } from '@/app/module/user/store/selectors'
import { useAppDispatch } from '@/app/store/redux-hooks'
import { createNotification } from '@/app/module/notifications/store/actions'
import { capitalize } from '@mui/material'
import { trimPhoneNumberPlus } from '@/app/service/util/format'
import { ContactValues } from '@/app/module/contacts/types'

export const useUpdateContact = () => {
  const dispatch = useAppDispatch()
  const orgId = useSelector(selectOrgId)
  const [update, res] = esApi.endpoints.updateContact.useMutation()

  const updateContact = useCallback(
    async (contactId: number, payload: ContactValues, noAutodelete: boolean) => {
      const { firstName, lastName, fullPhoneNumber, language, ...rest } = payload
      const newContact: UpdateContactApiArg['body'] = {
        firstName,
        lastName,
        fullPhoneNumber: fullPhoneNumber ? trimPhoneNumberPlus(fullPhoneNumber) : '',
        language,
        noAutodelete,
      }
      const customFields = new Map<string, string>()
      Object.entries(rest).forEach(([key, value]) => {
        if (key.startsWith('customFields')) {
          const id = key.replace('customFields', '')
          customFields.set(id, value as string)
        }
      })
      newContact.customFields = Object.fromEntries(customFields)
      try {
        const updated = await update({
          orgId: orgId.toString(),
          contactId: contactId.toString(),
          body: newContact,
        }).unwrap()
        dispatch(
          createNotification({
            'update-contact': {
              type: 'success',
              message: 'Contact saved successfully!',
            },
          }),
        )
        return updated
      } catch (err) {
        const errorMessage = capitalize(getErrorMessage(err, 'Failed to save contact'))
        dispatch(
          createNotification({
            'update-contact-failure': {
              type: 'error',
              message: errorMessage,
            },
          }),
        )
        return undefined
      }
    },
    [orgId, dispatch, update],
  )

  return [updateContact, res] as const
}
