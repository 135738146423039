import { useSelector } from 'react-redux'
import { useAppDispatch } from '@/app/store/redux-hooks'
import { esApi } from '@/app/store/api'
import { useEffect } from 'react'
import { createNotification } from '@/app/module/notifications/store/actions'
import { ERROR_GET_CONTACTS_ITEM_SERVER } from '@/app/module/contacts/definitions'
import { selectOrgId } from '@/app/module/user/store/selectors'

export const useGetContact = (id: number) => {
  const dispatch = useAppDispatch()
  const orgId = useSelector(selectOrgId)
  const res = esApi.endpoints.getContact.useQuery(
    { orgId: orgId.toString(), contactId: id.toString() },
    { skip: !orgId || !id },
  )

  useEffect(() => {
    if (!res.error) {
      return
    }

    dispatch(
      createNotification({
        'get-contacts': {
          type: 'error',
          message: ERROR_GET_CONTACTS_ITEM_SERVER,
        },
      }),
    )
  }, [res.error, dispatch])

  return res
}
