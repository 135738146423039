import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import { pathOr } from 'ramda'
import { makeStyles } from 'tss-react/mui'
import Fields from '@/app/component/wrapper/fields'
import FieldDynamic from '@/app/component/layout/field/dynamic'
import FormActions from './actions'

function Form({
  id,
  submitLabel,
  loading,
  headers,
  values = {},
  config = {},
  validation = {},
  onSubmit,
  Actions = FormActions,
  customProps = {},
}) {
  const { classes } = useStyles()
  return (
    <Fields fields={values} validation={validation} onSubmit={onSubmit}>
      {({ fields, changed, hasErrors, submitHandler, fieldChangeHandler, fieldBlurHandler }) => (
        <form
          id={id}
          onSubmit={(e) => {
            e.preventDefault()
            submitHandler()
          }}
        >
          <CardContent>
            {(headers || []).map((headersItem) =>
              headersItem.fields.map((key) => (
                <div key={key} className={classes.wrap} style={{ width: `${100 / headersItem.fields.length}%` }}>
                  <FieldDynamic
                    name={key}
                    field={{
                      value: pathOr('', [key, 'value'], fields),
                      error: pathOr('', [key, 'error'], fields),
                    }}
                    specs={config[key]}
                    onChange={fieldChangeHandler}
                    onBlur={fieldBlurHandler}
                    customProps={{
                      ...customProps,
                      ...(headersItem.id && {
                        multiline: true,
                      }),
                    }}
                  />
                </div>
              )),
            )}
          </CardContent>
          <CardActions>
            <Actions submitLabel={submitLabel} loading={loading} changed={changed} hasErrors={hasErrors} />
          </CardActions>
        </form>
      )}
    </Fields>
  )
}

const useStyles = makeStyles()(() => ({
  wrap: {
    display: 'inline-flex',
    marginBottom: '0.5rem',
  },
}))

export default Form
