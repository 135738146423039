import Paper from '@mui/material/Paper'
import Slide from '@mui/material/Slide'
import Typography from '@mui/material/Typography'
import { filter, find, map, pathOr, pick, propEq, without } from 'ramda'
import React from 'react'
import { Column } from '@/config/types'
import ContentLayout from '@/app/component/layout/content'
import PowerTable from '@/app/component/layout/power-table'
import SelectAll from '@/app/component/layout/table/select-all'
import { useDeepState } from '@/app/helpers'
import { generateTableColumns } from '@/app/service/util/helpers'
import { Contact, ContactSelection, Filters } from '@/app/types/contact'
import { History } from 'history'
import GroupFilter from './drawer'
import ContactsTableToolbar from './toolbar'

type Filter = { name: string; value: string }

type Query = {
  page: number
  size: number
  searchTerm: string
  filter: Filter
}

type DeleteContactsProps = {
  includedContactIds: number[]
  excludedContactIds: number[]
  allContactsAreSelected: boolean
  includedGroupIds: number[]
  search: string
}

type ActionProps = {
  totalRecipients: number
  recipients: Array<{ label: string; value: number }>
  contactIds: number[]
  excludeIds: number[]
  isAllContacts: boolean
  includedGroupIds: number[]
  search: string
}

type AddContactsToGroupProps = ActionProps & {
  orgId: number
  token: string
  getGroups: (payload: { token: string; orgId: number; query: { page: number; search?: string } }) => Promise<void>
}

type RemoveContactsFromGroupProps = ActionProps

type SendMessageProps = ActionProps

type NavigateProps = Partial<Query> & {
  history: History
}

type Props = {
  activeFilter: Filter
  data: Contact[]
  filters: Filters
  groupItem: {
    id: number
    name: string
  }
  headers: Column[]
  history: History
  loading: boolean
  loadingItems: unknown[]
  orgId: number
  orgTotal: number
  page: number
  query: Query
  segmentItem: {
    id: number
    name: string
  }
  selection: ContactSelection
  timezone: string
  token: string
  total: number

  addContactsToGroup: (props: AddContactsToGroupProps) => void
  deleteItems: (props: DeleteContactsProps) => void
  downloadItems: () => void
  editCustomField: (payload: { id: number; title: string }) => void
  getContactsTableHeaders: (orgId: number) => Column[]
  getCustomFields: (payload: { token: string; orgId: number }) => Promise<void>
  getData: (payload: { token: string; orgId: number; query: Query }) => Promise<void>
  getGroups: (payload: { token: string; orgId: number; query: { page: number; search?: string } }) => Promise<void>
  getGroupsItem: (payload: { token: string; orgId: number; itemId: string }) => Promise<void>
  getSegments: (payload: { token: string; orgId: number; query: { page: number; search?: string } }) => Promise<void>
  getSegmentsItem: (payload: { token: string; orgId: number; itemId: string }) => Promise<void>
  navigate: (props: NavigateProps) => void
  removeContactsFromGroup: (props: RemoveContactsFromGroupProps) => void
  saveContactsTableHeaders: (orgId: number, headers: Column[]) => void
  sendMessage: (props: SendMessageProps) => void
  setPageSize: (size: number) => void
}

export const removeHiddenColumns = (headers: Column[]): Column[] => filter((header) => header.visible, headers)

export default function ContactsTable(props: Props) {
  const {
    data,
    total,
    token,
    orgId,
    getData,
    getSegments,
    getGroups,
    filters,
    selection,
    loading,
    sendMessage,
    deleteItems,
    downloadItems,
    history,
    addContactsToGroup,
    removeContactsFromGroup,
    navigate,
    getCustomFields,
    timezone,
    page,
    editCustomField,
    setPageSize,
    orgTotal,
    getContactsTableHeaders,
    saveContactsTableHeaders,
    getGroupsItem,
    getSegmentsItem,
    groupItem,
    segmentItem,
  } = props

  const [headers, setHeaders] = React.useState<Column[]>(props.headers)
  const [activeFilter] = useDeepState(props.activeFilter)
  const [query] = useDeepState(props.query)

  React.useEffect(() => {
    setHeaders(props.headers)
  }, [props.headers])

  const contactFields: Column[] = React.useMemo(
    () => [
      {
        label: '',
        key: 'id',
        sticky: 'left',
        action: 'check',
        visible: true,
      },
      ...removeHiddenColumns(headers),
    ],
    [headers],
  )

  const columns = React.useMemo(() => generateTableColumns(contactFields, timezone), [contactFields, timezone])

  const [initialized, setInitialized] = React.useState<boolean>(false)
  const [groupsFilter, setGroupsFilter] = React.useState(true)
  const [groupSearchTerm, setGroupSearchTerm] = React.useState({
    groups: '',
    segments: '',
  })

  const loadData = React.useCallback(async () => {
    try {
      const segmentsPromise = getSegments({
        token,
        orgId,
        query: {
          page: 1,
          search: groupSearchTerm.segments,
        },
      })
      const groupsPromise = getGroups({
        token,
        orgId,
        query: {
          page: 1,
          search: groupSearchTerm.groups,
        },
      })
      const customFieldsPromise = getCustomFields({
        token,
        orgId,
      })
      await Promise.all([segmentsPromise, groupsPromise, customFieldsPromise])
    } finally {
      setInitialized(true)
    }
  }, [groupSearchTerm.groups, groupSearchTerm.segments, orgId, token, getCustomFields, getGroups, getSegments])

  const reloadData = () => {
    loadData()
    getData({
      token,
      orgId,
      query,
    })
  }

  const searchGroups = React.useCallback(
    (searchValue: string, tab: string) => {
      setGroupSearchTerm((s) => ({
        ...s,
        [tab]: searchValue,
      }))
      if (!initialized) {
        return
      }

      const method = tab === 'groups' ? getGroups : getSegments
      method({
        token,
        orgId,
        query: {
          page: 1,
          search: searchValue,
        },
      })
    },
    [initialized, orgId, token, getGroups, getSegments],
  )

  const ordered = React.useMemo(() => map((item) => item.id, data), [data])

  const getSelectedRecipients = () => {
    const selectedItems =
      selection.isAll || selection.groups.length > 0 || selection.search !== ''
        ? without(selection.list, ordered)
        : selection.list

    return (selectedItems as any).map((id: any) => {
      const found: any = find(propEq('id', id), data)
      return {
        label: pathOr('', ['fullPhoneNumber'], found),
        value: id,
      }
    })
  }

  const onFilterChange = React.useCallback(
    ({ name, value }: { name: string; value: string }) => {
      selection.clearSelection()
      if (name && value) {
        return navigate({
          history,
          ...pick(['size'], query),
          page: 1,
          filter: {
            name,
            value,
          },
        })
      }
      return navigate({
        history,
        ...pick(['page', 'size'], query),
      })
    },
    [history, query, selection, navigate],
  )
  const submitHandler = (term: string) => {
    navigate({ ...query, history, page: 1, searchTerm: term })
  }

  React.useEffect(() => {
    const filtersStringified = JSON.stringify(activeFilter)
    if (token && orgId) {
      if (filtersStringified) {
        if (activeFilter.name === 'groups') {
          getGroupsItem({
            token,
            orgId,
            itemId: activeFilter.value,
          })
        } else if (activeFilter.name === 'segments') {
          getSegmentsItem({
            token,
            orgId,
            itemId: activeFilter.value,
          })
        }
      }
      getData({
        token,
        orgId,
        query,
      })
    }
  }, [activeFilter, orgId, query, token, getData, getGroupsItem, getSegmentsItem])

  React.useEffect(() => {
    if (!initialized && token && orgId) {
      loadData()
    }
  }, [initialized, orgId, token, loadData])

  React.useEffect(() => {
    if (orgId) {
      getContactsTableHeaders(orgId)
    }
  }, [orgId, getContactsTableHeaders])

  const selectAll = React.useMemo(
    () => (
      <SelectAll
        activeFilter={query.filter}
        filters={filters}
        ordered={ordered}
        orgTotal={orgTotal}
        pagination={{ total }}
        search={{ term: query.searchTerm }}
        selection={selection}
      />
    ),
    [filters, ordered, orgTotal, query.filter, query.searchTerm, selection, total],
  )

  const noData = React.useMemo(
    () => (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          padding: '90px',
          width: '100%',
        }}
      >
        <Typography variant="h5" color="textSecondary">
          {query.searchTerm ? `No results for "${query.searchTerm}".` : 'No contacts were found.'}
        </Typography>
      </div>
    ),
    [query.searchTerm],
  )

  const tableStyle = React.useMemo(
    () => ({
      height: 'calc(100vh - 206px)',
      width: '100%',
      transition: 'width 0.3s',
      maxWidth: `calc(100vw - ${groupsFilter ? '322px' : '0px'})`,
      boxShadow: '0px 0px 0px 1px rgb(224 224 224)',
    }),
    [groupsFilter],
  )

  const handlePageChange = React.useCallback(
    (newPage: number) => {
      navigate({
        ...query,
        history,
        page: newPage,
      })
    },
    [history, query, navigate],
  )
  const handlePageSizeChange = React.useCallback(
    (newPageSize: number) => {
      setPageSize(newPageSize)
    },
    [setPageSize],
  )
  const handleRowAction = React.useCallback(
    (val: string) => {
      history.push(`/contacts/edit/${val}`, {
        contactsQuery: query,
        fromUrl: window.location.pathname + window.location.search,
      })
    },
    [history, query],
  )

  return (
    <div>
      <ContentLayout>
        <ContactsTableToolbar
          headers={headers}
          setHeaders={(customHeaders: Column[]) => {
            saveContactsTableHeaders(orgId, customHeaders)
          }}
          search={{
            term: query.searchTerm,
            onSubmit: (searchTerm: string) => submitHandler(searchTerm),
          }}
          loading={loading}
          selection={selection}
          toggleGroups={() => setGroupsFilter(!groupsFilter)}
          groupsFilter={groupsFilter}
          filters={{
            ...filters,
            data: {
              ...filters.data,
              groups: {
                ...filters.data.groups,
                values: [
                  {
                    ...groupItem,
                    value: `${groupItem.id}`,
                    label: groupItem.name,
                  },
                ],
              },
              segments: {
                ...filters.data.segments,
                values: [
                  {
                    ...segmentItem,
                    value: `${segmentItem.id}`,
                    label: segmentItem.name,
                  },
                ],
              },
            },
          }}
          activeFilter={query.filter}
          reloadContacts={reloadData}
          onUploadContacts={() => history.push('/contacts/upload')}
          onAddContacts={() => {
            history.push('/contacts/add', {
              contactsQuery: query,
              fromUrl: window.location.pathname + window.location.search,
            })
          }}
          onAddGroups={() => history.push('/groups/add')}
          onAddCustomFields={() => history.push('/customfields/add')}
          onAddSegmentsFields={() => history.push('/segments/add')}
          onAddContactsToGroup={() =>
            addContactsToGroup({
              totalRecipients: selection.count,
              recipients: getSelectedRecipients(),
              contactIds:
                selection.isAll || selection.groups.length > 0 || selection.search !== '' ? [] : selection.list,
              excludeIds:
                selection.isAll || selection.groups.length > 0 || selection.search !== '' ? selection.list : [],
              isAllContacts: selection.isAll,
              includedGroupIds: selection.groups,
              search: selection.search,
              getGroups,
              token,
              orgId,
            })
          }
          onFilterChange={({ name, value }: any = {}) => {
            onFilterChange({ name, value })
          }}
          onDeleteContacts={() =>
            deleteItems({
              includedContactIds: selection.isAll ? [] : selection.list,
              excludedContactIds: selection.isAll ? selection.list : [],
              allContactsAreSelected: selection.isAll,
              includedGroupIds: selection.groups,
              search: selection.search,
            })
          }
          onDownloadContacts={() => downloadItems()}
          onRemoveContactsFromGroup={() =>
            removeContactsFromGroup({
              totalRecipients: selection.count,
              recipients: getSelectedRecipients(),
              contactIds:
                selection.isAll || selection.groups.length > 0 || selection.search !== '' ? [] : selection.list,
              excludeIds:
                selection.isAll || selection.groups.length > 0 || selection.search !== '' ? selection.list : [],
              isAllContacts: selection.isAll,
              includedGroupIds: selection.groups,
              search: selection.search,
            })
          }
          onSendMessage={() =>
            sendMessage({
              totalRecipients: selection.count,
              recipients: getSelectedRecipients(),
              contactIds:
                selection.isAll || selection.groups.length > 0 || selection.search !== '' ? [] : selection.list,
              excludeIds:
                selection.isAll || selection.groups.length > 0 || selection.search !== '' ? selection.list : [],
              isAllContacts: selection.isAll,
              includedGroupIds: selection.groups,
              search: selection.search,
            })
          }
        />
      </ContentLayout>
      <div
        style={{
          display: 'flex',
        }}
      >
        <div
          style={{
            height: 'calc(100vh - 124px)',
          }}
        >
          <Slide direction="right" in={groupsFilter} mountOnEnter unmountOnExit>
            <Paper
              elevation={4}
              style={{
                minHeight: '60px',
                display: 'inline-block',
                background: 'white',
                verticalAlign: 'top',
                borderRight: '1px solid rgba(224, 224, 224, 1)',
                borderRadius: '0px',
              }}
            >
              <GroupFilter
                loading={filters.loading}
                searchGroups={searchGroups}
                groups={pathOr([], ['data', 'groups', 'values'], filters)}
                segments={pathOr([], ['data', 'segments', 'values'], filters)}
                onFilterChange={onFilterChange}
                selected={pathOr(0, ['filter', 'value'], query)}
                history={history}
              />
            </Paper>
          </Slide>
        </div>

        {initialized && (
          <PowerTable
            columns={columns}
            data={data}
            noData={noData}
            page={page}
            pageSize={query.size}
            selectAll={selectAll}
            selection={selection}
            style={tableStyle}
            tableActions={null}
            total={total}
            editAction={editCustomField}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            rowAction={handleRowAction}
          />
        )}
      </div>
    </div>
  )
}
