import fetch from 'isomorphic-unfetch'
import cookies from 'js-cookie'
import downloadConfig from '@/config/download'
import { ReceiptLines } from '@/app/types'

export const downloadBlob = (blob: Blob, filename: string) => {
  const obj = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = obj
  a.download = filename
  document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
  a.click()
  a.remove()
}

export const downloadFile = async (url: string, filename: string, isSigned = false) => {
  const headers: Record<string, string> = {}

  if (!isSigned) {
    const token = cookies.get('authToken')
    headers.Authorization = `JWT ${token}`
  }

  const response = await fetch(url, {
    method: 'GET',
    headers,
  })

  if (!response.ok) {
    throw new Error('Failed to download file')
  } else {
    const blob = await response.blob()
    downloadBlob(blob, filename)
  }
}

const download = {
  exports: (orgId: number, exportId: number, filename: string) => {
    const url = downloadConfig.exports.getPath({ orgId, exportId, filename })
    return downloadFile(url, filename)
  },
  invoices: (orgId: number, filename: string, lines?: ReceiptLines) => {
    const url = downloadConfig.invoices.getPath({ orgId, filename, lines })
    return downloadFile(url, filename)
  },
  receipts: (orgId: number, transactionId: number, lines: ReceiptLines) => {
    const filename = downloadConfig.receipts.getFileName(transactionId)
    const url = downloadConfig.receipts.getPath({ orgId, transactionId, lines })
    return downloadFile(url, filename)
  },
}

export default download
