import { compose, map, path, pathOr, pick } from 'ramda'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import config from '@/config'
import { createNested } from '@/app/service/util'
import { selectToken } from '@/app/module/auth/store/selectors'
import {
  addCustomFieldsItem,
  deleteCustomFieldsItem,
  getCustomFields,
  saveCustomFieldsItem,
} from '@/app/module/custom-fields/store/actions'
import {
  addToGroup,
  getGroupContacts,
  getGroups,
  getGroupsItem,
  removeFromGroup,
} from '@/app/module/groups/store/actions'
import { selectGroupValues } from '@/app/module/groups/store/selectors'
import { getSenderIdList, sendMessage } from '@/app/module/logs/store/actions'
import { selectSenderIdValues } from '@/app/module/logs/store/selectors'
import { getSegmentContacts, getSegments, getSegmentsItem } from '@/app/module/segments/store/actions'
import { selectOrgId, selectUserId, selectUserTimezone } from '@/app/module/user/store/selectors'
import { selectCountriesMapped } from '@/app/module/utils/store/selectors'
import Contacts from './component'
import Group from './component/group'
import Upload from './component/upload'
import {
  confirmUpload,
  deleteContacts,
  downloadContacts,
  getContacts,
  getContactsItem,
  getContactsTableHeaders,
  getUploadStatus,
  navigateContacts,
  saveContactsItem,
  saveContactsTableHeaders,
  setContactsPageSize,
  uploadContacts,
} from './store/actions'
import {
  selectContactFilters,
  selectContactsItemConfig,
  selectContactsValues,
  selectGroupHeaders,
  selectGroupsConfig,
  selectHeaders,
  selectNewContactValues,
  selectOrdered,
} from './store/selectors'

const GROUP_TYPE = pathOr({}, ['modules', 'groups', 'type'], config)
const SEGMENT_TYPE = pathOr({}, ['modules', 'segments', 'type'], config)

const pageSizeDefault = path(['modules', 'contacts', 'pageSize', 'default'], config)
export const pageSizeValues = compose(
  map((val) => ({ label: val, value: val })),
  path(['modules', 'contacts', 'pageSize', 'values']),
)(config)

const getContactsAction =
  ({ token, orgId, query }) =>
  (dispatch) => {
    if (query.filter && query.filter.name === GROUP_TYPE.name) {
      return dispatch(getGroupContacts({ token, orgId, groupId: query.filter.value, query }))
    }
    if (query.filter && query.filter.name === SEGMENT_TYPE.name) {
      return dispatch(getSegmentContacts({ token, orgId, segmentId: query.filter.value, query }))
    }
    return dispatch(getContacts({ token, orgId, query }))
  }

const selector = ({ auth, user, contacts, groups, segments, customFields, logs, utils }) => ({
  root: 'contacts',
  hydrateProps: ['token', 'orgId'],
  error: {
    ...customFields.error,
    ...contacts.list.error,
  },
  item: {
    id: contacts.item.data.id,
    error: pathOr({}, ['item', 'error', 'fields'], contacts),
  },
  filters: {
    loading: groups.list.loading || groups.item.loading || segments.list.loading || segments.item.loading,
    data: selectContactFilters({ contacts, groups, segments }),
  },
  token: selectToken({ auth }),
  orgId: selectOrgId({ user }),
  userId: selectUserId({ user }),
  loading:
    !contacts.list.initialized ||
    contacts.list.loading ||
    user.details.loading ||
    user.organization.loading ||
    customFields.loading,
  loadingItems: pathOr([], ['list', 'data', 'loadingItems'], contacts),
  data: selectContactsValues({ contacts, customFields }),
  values: selectNewContactValues({ contacts }),
  specs: selectContactsItemConfig({ user, customFields, utils }),
  ordered: selectOrdered({ contacts }),
  headers: selectHeaders({ customFields, contacts }),
  total: pathOr(0, ['list', 'data', 'total'], contacts),
  orgTotal: pathOr(0, ['list', 'data', 'orgTotal'], contacts),
  pageSizeValues,
  query: {
    page: pathOr(1, ['list', 'data', 'page'], contacts),
    size: pathOr(pageSizeDefault, ['list', 'data', 'size'], contacts),
    searchTerm: pathOr(false, ['list', 'data', 'search', 'active'], contacts),
    filter: pathOr(false, ['list', 'data', 'filter', 'active'], contacts),
  },
  senderIds: selectSenderIdValues({ logs }),
  groups: selectGroupValues({ groups }),
  personalizationList: {},
  timezone: selectUserTimezone({ user, utils }),
  groupsQuery: pick(['page', 'search'], pathOr({}, ['list', 'data'], groups)),
  groupItem: pathOr({}, ['item', 'data'], groups),
  segmentItem: pathOr({}, ['item', 'data'], segments),
})

const dispatcher = (dispatch) =>
  bindActionCreators(
    {
      getContacts: getContactsAction,
      saveItem: ({ token, orgId, item }) =>
        saveContactsItem({
          token,
          orgId,
          item: createNested(item),
        }),
      deleteItems: deleteContacts,
      downloadItems: downloadContacts,
      navigate: navigateContacts,
      setPageSize: setContactsPageSize,
      getGroups,
      getSegments,
      getSenderIdList,
      sendMessage,
      addToGroup,
      removeFromGroup,
      getCustomFields,
      saveCustomFieldsItem,
      deleteCustomFieldsItem,
      getContactsTableHeaders,
      saveContactsTableHeaders,
      getGroupsItem,
      getSegmentsItem,
    },
    dispatch,
  )

export default connect(selector, dispatcher)(withRouter(Contacts))

const uploadSelector = ({ auth, user, contacts, customFields, utils }) => ({
  loading: contacts.upload.loading || customFields.loading,
  token: selectToken({ auth }),
  orgId: selectOrgId({ user }),
  page: pathOr(1, ['list', 'data', 'page'], contacts),
  size: pathOr(pageSizeDefault, ['list', 'data', 'size'], contacts),
  countriesList: selectCountriesMapped({ utils }),
  uploadData: pathOr({}, ['upload', 'data'], contacts),
  uploadError: pathOr({}, ['upload', 'error'], contacts),
  uploadConfirmError: pathOr({}, ['upload', 'confirmError'], contacts),
  specs: selectContactsItemConfig({ contacts, user, customFields, utils }),
  customFieldsLoading: customFields.loading,
})

const uploadDispatcher = (dispatch) =>
  bindActionCreators(
    {
      uploadContacts,
      confirmUpload,
      getUploadStatus,
      addCustomFieldsItem,
    },
    dispatch,
  )

export const UploadContacts = connect(uploadSelector, uploadDispatcher)(Upload)

const groupItemSelector = ({ auth, user, contacts, customFields }) => ({
  hydrateProps: ['token', 'orgId', 'itemId'],
  error: {
    ...customFields.error,
    ...contacts.item.error,
  },
  loading: contacts.item.loading || customFields.loading,
  token: selectToken({ auth }),
  orgId: selectOrgId({ user }),
  specs: selectGroupsConfig({ user }),
  headers: selectGroupHeaders({ customFields }),
  fieldErrors: pathOr({}, ['item', 'error', 'fields'], contacts),
  page: pathOr(1, ['list', 'data', 'page'], contacts),
})

const groupItemDispatcher = (dispatch) =>
  bindActionCreators(
    {
      hydrate:
        ({ token, orgId, itemId }) =>
        () =>
          itemId
            ? Promise.all([
                dispatch(getCustomFields({ token, orgId })),
                dispatch(getContactsItem({ token, orgId, itemId })),
              ])
            : dispatch(getCustomFields({ token, orgId })),
      getContacts,
      submit: ({ token, orgId, item }) =>
        saveContactsItem({
          token,
          orgId,
          item: createNested(item),
        }),
    },
    dispatch,
  )

export const AddToGroupsItem = connect(groupItemSelector, groupItemDispatcher)(Group)
