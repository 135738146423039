import { applySpec, compose, indexBy, merge, pathOr, prop } from 'ramda'
import { createReducer } from '@/app/service/util'
import {
  ACTION_ADD_TO_GROUP_FAILURE,
  ACTION_ADD_TO_GROUP_REQUEST,
  ACTION_ADD_TO_GROUP_SUCCESS,
  ACTION_GET_GROUPS_FAILURE,
  ACTION_GET_GROUPS_ITEM_FAILURE,
  ACTION_GET_GROUPS_ITEM_REQUEST,
  ACTION_GET_GROUPS_ITEM_SUCCESS,
  ACTION_GET_GROUPS_REQUEST,
  ACTION_GET_GROUPS_SUCCESS,
  ACTION_GROUPS_NAVIGATE,
  ACTION_RESET_GROUPS_QUERY,
} from '@/app/module/groups/definitions'
import defaultState from './state'

const transformGroups = (state, value = { values: [] }) => {
  const indexedPage = indexBy(prop('id'), value.values || [])

  return compose(
    merge(state),
    applySpec({
      page: ({ page }) => parseInt(page, 10),
      size: ({ size }) => parseInt(size, 10),
      total: ({ total }) => parseInt(total, 10),
      search: ({ search }) => search,
      values: () => ({
        ...state.values,
        ...indexedPage,
      }),
      pages: ({ page }) => ({
        ...state.pages,
        [page]: value.values.map(({ id }) => id),
      }),
    }),
  )(value)
}

export default createReducer(defaultState, {
  [ACTION_GET_GROUPS_REQUEST]: (state) => ({
    ...state,
    list: {
      ...state.list,
      loading: true,
    },
  }),
  [ACTION_GET_GROUPS_ITEM_REQUEST]: (state, { value }) => ({
    ...state,
    item: {
      data: {
        id: value,
      },
      loading: false,
    },
  }),
  [ACTION_GET_GROUPS_ITEM_SUCCESS]: (state, { value }) => ({
    ...state,
    item: {
      data: value,
      loading: false,
    },
  }),
  [ACTION_GET_GROUPS_ITEM_FAILURE]: (state, { value }) => ({
    ...state,
    item: {
      ...state.item,
      error: value,
      loading: false,
    },
  }),
  [ACTION_GET_GROUPS_SUCCESS]: (state, { value }) => ({
    ...state,
    list: {
      ...state.list,
      loading: false,
      data: transformGroups(state.list.data, value),
    },
  }),
  [ACTION_GET_GROUPS_FAILURE]: (state, { value }) => ({
    ...state,
    list: {
      ...state.list,
      loading: false,
      error: value,
    },
  }),
  [ACTION_RESET_GROUPS_QUERY]: (state) => {
    const values = pathOr({}, ['list', 'data', 'values'], state)
    const keys = Object.keys(values)
    const pages = {
      1: keys,
    }
    return {
      ...state,
      list: {
        loading: false,
        error: {},
        data: {
          values,
          page: 1,
          pages,
          size: 1000,
          total: keys.length,
        },
      },
    }
  },

  // Add to Group
  [ACTION_ADD_TO_GROUP_REQUEST]: (state) => ({
    ...state,
    item: {
      ...state.item,
      loading: true,
    },
  }),
  [ACTION_ADD_TO_GROUP_SUCCESS]: (state) => ({
    ...state,
    item: {
      ...state.item,
      loading: false,
    },
  }),
  [ACTION_ADD_TO_GROUP_FAILURE]: (state, { value }) => ({
    ...state,
    item: {
      ...state.item,
      loading: false,
      error: value,
    },
  }),

  // NAVIGATE
  [ACTION_GROUPS_NAVIGATE]: (state, { value }) => ({
    ...state,
    list: {
      ...state.list,
      data: {
        ...state.list.data,
        page: value.page,
        search: value.searchTerm,
      },
    },
  }),
})
