import { pathOr } from 'ramda'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createNested } from '@/app/service/util'
import { selectToken } from '@/app/module/auth/store/selectors'
import { getCustomFields } from '@/app/module/custom-fields/store/actions'
import { selectPersonalizationList } from '@/app/module/logs/store/selectors'
import { deleteSegmentsItem, getSegments, getSegmentsItem, saveSegmentsItem } from '@/app/module/segments/store/actions'
import { selectOrgId } from '@/app/module/user/store/selectors'
import Segment from './component/segment'
import { getGroups } from './store/actions'
import { selectGroup, selectGroupQuery, selectSegmentsHeaders, selectSegmentsItemConfig } from './store/selectors'
import CreateGroupForm from './component/form/create-group-form'
import UpdateGroupForm from './component/form/update-group-form'

const groupSelector = ({ auth, groups, user }) => ({
  orgId: selectOrgId({ user }),
  query: selectGroupQuery({ groups }),
  token: selectToken({ auth }),
})

const groupDispatcher = (dispatch) => ({
  getGroups: ({ token, orgId, query }) => dispatch(getGroups({ token, orgId, query })),
})

export const CreateGroup = connect(groupSelector, groupDispatcher)(CreateGroupForm)

export const UpdateGroup = connect(groupSelector, groupDispatcher)(UpdateGroupForm)

const SegmentsItemSelector = ({ auth, user, contacts, customFields, segments }) => ({
  hydrateProps: ['token', 'orgId', 'itemId'],
  error: {
    ...customFields.error,
    ...segments.item.error,
  },
  item: selectGroup(segments),
  loading:
    contacts.item.loading ||
    contacts.list.loading ||
    customFields.loading ||
    segments.item.loading ||
    segments.list.loading,
  personalizationList: selectPersonalizationList({ customFields }),
  token: selectToken({ auth }),
  orgId: selectOrgId({ user }),
  specs: selectSegmentsItemConfig({ user }),
  headers: selectSegmentsHeaders({ customFields }),
  fieldErrors: pathOr({}, ['item', 'error', 'fields'], segments),
  page: pathOr(1, ['list', 'data', 'page'], contacts),
})

const segementsItemDispatcher = (dispatch) =>
  bindActionCreators(
    {
      hydrate:
        ({ token, orgId, itemId }) =>
        () =>
          itemId
            ? Promise.all([
                dispatch(getCustomFields({ token, orgId })),
                dispatch(getSegmentsItem({ itemId, token, orgId })),
              ])
            : dispatch(getCustomFields({ token, orgId })),
      getSegments,
      submit: ({ token, orgId, item }) =>
        saveSegmentsItem({
          token,
          orgId,
          item: createNested(item),
        }),
      deleteSegmentsItem,
    },
    dispatch,
  )

// eslint-disable-next-line import/prefer-default-export
export const SegmentsItem = connect(SegmentsItemSelector, segementsItemDispatcher)(Segment)
