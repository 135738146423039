import { styled } from '@mui/material'
import React from 'react'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd'
import { v1 as uuid } from 'uuid'
import { CampaignAddButton } from '@/app/module/campaigns/component/helpers'
import { validateUserStatuses } from '@/app/module/campaigns/campaign-validation-helpers'
import UserStatusFormItem from './user-status-form-item'
import { UserStatusesFormValues } from './types'

type Props = {
  userStatuses: string[]

  onChange: (userStatuses: string[]) => void
}

const UserStatusForm: React.FC<Props> = ({ userStatuses, onChange }) => {
  const defaultValues = React.useMemo(() => toFormValues(userStatuses), [userStatuses])
  const form = useForm<UserStatusesFormValues>({
    defaultValues,
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  })
  const { fields, append, move, remove } = useFieldArray({
    control: form.control,
    name: 'userStatuses',
  })

  const { getValues, setValue, trigger } = form
  React.useEffect(() => {
    // validate user statuses on mount to show errors if initial values are invalid
    const hasError = validateUserStatuses(userStatuses)
    if (!hasError) {
      return
    }

    trigger()
  }, [userStatuses, trigger])

  const submit = React.useCallback(() => {
    const values = getValues()
    onChange(fromFormValues(values.userStatuses))
  }, [getValues, onChange])

  const handleAdd = React.useCallback(() => {
    append({
      id: uuid(),
      status: '',
    })
    trigger()
    submit()
  }, [append, submit, trigger])
  const handleBlur = React.useCallback(
    (index: number) => {
      const values = getValues()
      const value = values.userStatuses[index].status.trim()
      setValue(`userStatuses.${index}.status`, value)
      trigger()
      submit()
    },
    [getValues, setValue, submit, trigger],
  )
  const handleRemove = React.useCallback(
    (index: number) => {
      remove(index)
      trigger()
      submit()
    },
    [remove, submit, trigger],
  )
  const handleDrop = React.useCallback(
    (result: DropResult) => {
      if (!result.destination) {
        return
      }

      move(result.source.index, result.destination.index)
    },
    [move],
  )

  return (
    <>
      <AddButtonContainer>
        <CampaignAddButton icon="add" text="Add new status" tooltip="" onClick={handleAdd} />
      </AddButtonContainer>
      <FormProvider {...form}>
        <DragDropContext onDragEnd={handleDrop}>
          <Droppable droppableId="user-status-form-droppable">
            {(provided) => (
              <Container ref={provided.innerRef} {...provided.droppableProps}>
                {fields.map((field, index) => (
                  <UserStatusFormItem
                    key={field.id}
                    id={field.id}
                    index={index}
                    onBlur={handleBlur}
                    onRemove={handleRemove}
                  />
                ))}
                {provided.placeholder}
              </Container>
            )}
          </Droppable>
        </DragDropContext>
      </FormProvider>
    </>
  )
}

const Container = styled('div')({
  height: '100%',
})

const AddButtonContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  marginBottom: '1rem',
})

const toFormValues = (userStatuses: string[]) => ({
  userStatuses: userStatuses.map((s) => ({ id: uuid(), status: s })),
})

const fromFormValues = (fields: UserStatusesFormValues['userStatuses']) => fields.map((f) => f.status.trim())

export default UserStatusForm
