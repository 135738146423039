import Button from '@mui/material/Button'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'
import React from 'react'
import DialogHeader from '@/app/component/atom/dialog/header'
import { Form } from '@/app/component/form'
import { CardActions } from '@mui/material'
import { ReceiptLines } from '@/app/types'
import download from '@/app/service/util/download'
import { SchemaInvoice } from '@/__generated/es-api'
import { useAppDispatch } from '@/app/store/redux-hooks'
import { createNotification } from '@/app/module/notifications/store/actions'
import { ERROR_DOWNLOAD_INVOICE_SERVER } from '@/app/module/payments/definitions'
import { linesDefaultValues, linesFields, linesSchema } from '@/app/module/payments/lines-form'
import { captureError } from '@/app/service/util/error'
import Dialog from '@/app/component/atom/dialog'

type Props = {
  invoice: SchemaInvoice
  orgId: number

  onClose: () => void
}

/**
 * For downloading Invoice as PDF
 */
export default function DownloadInvoiceDialog({ invoice, orgId, onClose }: Props) {
  const dispatch = useAppDispatch()
  const [isLoading, setIsLoading] = React.useState(false)

  const downloadInvoice = async (lines: ReceiptLines) => {
    setIsLoading(true)
    try {
      await download.invoices(orgId, invoice.pdf, lines)
      onClose()
    } catch (e) {
      captureError(e)
      dispatch(
        createNotification({
          'download-invoice': {
            type: 'error',
            message: ERROR_DOWNLOAD_INVOICE_SERVER,
          },
        }),
      )
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Dialog open onClose={onClose}>
      <DialogHeader icon="input" title="Set Usage Statement information" onClose={onClose} />
      <DialogContent>
        <Typography color="textPrimary" variant="subtitle2">
          Enter whatever organizational, legal, or project details you want to appear on the statement, such as your
          organization's legal name, address, tax ID, phone number, PO number, etc. You can add up to 5 lines of detail.
        </Typography>
        <br />
        <Form defaultValues={linesDefaultValues} fields={linesFields} schema={linesSchema} onSubmit={downloadInvoice}>
          <CardActions sx={{ p: 0 }}>
            <Button disabled={isLoading} variant="outlined" onClick={onClose}>
              Cancel
            </Button>
            <Button disabled={isLoading} type="submit" variant="contained">
              Download
            </Button>
          </CardActions>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
