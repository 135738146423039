import Button from '@mui/material/Button'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'
import React from 'react'
import TablePagination from '@/app/component/atom/table-pagination'
import Loading from '@/app/component/guard/loading'
import { getMonthName } from '@/app/service/util/format'
import { SchemaInvoice } from '@/__generated/es-api'
import { useGetInvoices } from '@/app/module/payments/hooks/use-get-invoices'
import download from '@/app/service/util/download'
import { useAppDispatch } from '@/app/store/redux-hooks'
import { createNotification } from '@/app/module/notifications/store/actions'
import { ERROR_DOWNLOAD_INVOICE_SERVER } from '@/app/module/payments/definitions'
import { captureError } from '@/app/service/util/error'
import DownloadInvoiceDialog from './download-invoice-dialog'

type Props = {
  orgId: number
  role: string
}

type InvoiceType = {
  year: number
  month: number
  date: string
  pdf: string
  xlsx: string
}

const pageSizeValues = [
  {
    label: '5',
    value: 5,
  },
  {
    label: '10',
    value: 10,
  },
  {
    label: '15',
    value: 15,
  },
  {
    label: '20',
    value: 20,
  },
  {
    label: '50',
    value: 50,
  },
  {
    label: '100',
    value: 100,
  },
]

function Invoices({ orgId, role }: Props) {
  const dispatch = useAppDispatch()
  const [isDownloading, setIsDownloading] = React.useState(false)
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(15)
  const [toBeDownloaded, setToBeDownloaded] = React.useState<SchemaInvoice | undefined>()

  const { data, isFetching } = useGetInvoices()
  const invoices = data?.months || []

  const isAuthorized = React.useMemo(() => role === 'owner' || role === 'manager', [role])

  const downloadInvoiceXLSX = async (invoice: SchemaInvoice) => {
    setIsDownloading(true)
    try {
      await download.invoices(orgId, invoice.xlsx)
    } catch (e) {
      captureError(e)
      dispatch(
        createNotification({
          'download-invoice': {
            type: 'error',
            message: ERROR_DOWNLOAD_INVOICE_SERVER,
          },
        }),
      )
    } finally {
      setIsDownloading(false)
    }
  }

  const handlePageChange = (newPage: number) => {
    setPage(newPage - 1)
  }

  const handleRowsPerPageChange = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage)
  }

  return (
    <Loading style={{ paddingTop: '5px' }} isLoading={isFetching || isDownloading}>
      <TableContainer component="div">
        <Table size="small" className="invoices-table">
          <TableHead>
            <TableRow>
              <TableCell>Year</TableCell>
              <TableCell align="left">Month</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoices.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row: InvoiceType) => (
              <TableRow key={row.date}>
                <TableCell>{row.year}</TableCell>
                <TableCell align="left">{getMonthName[row.month - 1]}</TableCell>
                <TableCell align="right">
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-around',
                    }}
                  >
                    <Tooltip
                      title={
                        isAuthorized
                          ? 'Download this Usage Statement as PDF'
                          : 'You are not authorized to download usage statements.'
                      }
                    >
                      <span>
                        <Button
                          disabled={!isAuthorized}
                          size="small"
                          onClick={() => setToBeDownloaded(row)}
                          variant="contained"
                          color="primary"
                        >
                          Download PDF
                        </Button>
                      </span>
                    </Tooltip>
                    <Tooltip
                      title={
                        isAuthorized
                          ? 'Download this Usage Statement as XSLX'
                          : 'You are not authorized to download usage statements.'
                      }
                    >
                      <span>
                        <Button
                          disabled={!isAuthorized || isDownloading}
                          size="small"
                          onClick={() => downloadInvoiceXLSX(row)}
                          variant="contained"
                          color="primary"
                        >
                          Download XLSX
                        </Button>
                      </span>
                    </Tooltip>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        namespace="Invoices"
        page={page + 1}
        size={rowsPerPage}
        total={invoices.length}
        pageSizeValues={pageSizeValues}
        onPageSizeChange={handleRowsPerPageChange}
        onPageChange={handlePageChange}
      />
      {!!toBeDownloaded && (
        <DownloadInvoiceDialog invoice={toBeDownloaded} orgId={orgId} onClose={() => setToBeDownloaded(undefined)} />
      )}
    </Loading>
  )
}

export default Invoices
