import Card from '@mui/material/Card'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Toolbar from '@mui/material/Toolbar'
import React from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import StyledBadge from '@/app/component/atom/styled-badge'
import Loading from '@/app/component/guard/loading'
import ContentLayout from '@/app/component/layout/content'
import { CountryTimezone, FileType, FileUploadType, SelectOption, TimezoneWithCountryCodeType } from '@/app/types'
import { ChannelType, PhoneNumber } from '@/app/module/phonenumbers/types'
import { FilesState, PersonalizationType, SenderIdOption, SnippetType } from '@/app/module/campaigns/types'
import { History } from 'history'
import { styled } from '@mui/material'
import { useGetPhoneNumber } from '@/app/module/phonenumbers/hooks/use-get-phonenumber'
import { useUpatePhoneNumber } from '@/app/module/phonenumbers/hooks/use-update-phonenumber'
import createHydrated from '@/app/component/wrapper/hydratable'
import { cannot } from '@/app/helpers'
import config from '@/config'
import InboundVoiceCampaign from './campaign-hook-tab'
import PhoneNumberTitle from './phone-number-title'
import WhatsappHooksTab from './whatsapp-hooks-tab'
import SmsHooksTab from './sms-hooks-tab'
import VoiceHookTab from './voice-hook-tab'
import ConnectMetaButton from './connect-meta-button'
import ConnectMetaStatus from './connect-meta-status'

type Props = {
  campaignIds: SelectOption<number>[]
  history: History
  tabName: ChannelType
  countriesTimezones: CountryTimezone[]
  countryCode: string
  files: FilesState
  orgId: number
  personalizationList: PersonalizationType
  phoneNumberId: number
  senderIds: SenderIdOption[]
  snippets: {
    data: SnippetType[]
    error: string
    loading: boolean
  }
  timezone: string
  timezones: TimezoneWithCountryCodeType
  token: string

  getCampaigns: (payload: { token: string; orgId: number }) => Promise<void>
  getFile: (payload: { file: string; msgId: string; orgId: number; token: string }) => Promise<FileType>
  getSnippets: (payload: { orgId: number; token: string }) => Promise<void>
  onError: (payload: { message: string }) => void
  onMicAccessError: () => void
  saveFile: (payload: { item: FileUploadType; msgId: string; orgId: number; token: string }) => Promise<FileType>
  saveSnippet: (payload: {
    orgId: number
    token: string
    item: Pick<SnippetType, 'category' | 'snippet'>
  }) => Promise<SnippetType>
}

const PhoneNumberRules: React.FC<Props> = (props) => {
  const { orgId, token, phoneNumberId, history, tabName } = props
  const [tab, setTab] = React.useState<ChannelType>(tabName)
  const { data, error, isFetching } = useGetPhoneNumber(phoneNumberId)
  const [updatePhoneNumber, { isLoading }] = useUpatePhoneNumber()

  const phoneNumber = data as PhoneNumber | undefined

  React.useEffect(() => {
    if (error && 'status' in error && error.status === 404) {
      history.push('/phonenumbers')
    }
  }, [error, history])

  React.useEffect(() => {
    setTab(props.tabName)
  }, [props.tabName])

  const generateTabCount = (type: ChannelType) => {
    switch (type) {
      case ChannelType.Sms:
        return phoneNumber?.hooks?.length || 0
      case ChannelType.WhatsApp:
        return phoneNumber?.whatsappHooks?.length || 0
      case ChannelType.Voice:
        return phoneNumber?.callHooks?.length || 0
      case ChannelType.Campaign:
        return phoneNumber?.inboundCallCampId ? 1 : 0

      default:
        return 0
    }
  }

  const handleTitleSubmit = (name: string) => updatePhoneNumber(phoneNumberId, { name })

  const availableTabs = React.useMemo(
    () =>
      tabNames.filter(({ value }) => {
        switch (value) {
          case ChannelType.Sms:
            return phoneNumber?.sms
          case ChannelType.WhatsApp:
            return phoneNumber?.whatsApp
          case ChannelType.Campaign:
          case ChannelType.Voice:
            return phoneNumber?.voice
          default:
            return cannot(value)
        }
      }),
    [phoneNumber],
  )

  const isWhitelisted = !!config.meta.orgWhitelist && config.meta.orgWhitelist.includes(orgId)

  return (
    <DndProvider backend={HTML5Backend}>
      <Loading isLoading={isFetching || isLoading}>
        {phoneNumber && (
          <ContentLayout>
            <TitleContainer>
              <div style={{ flex: 1 }}>
                <PhoneNumberTitle phoneNumber={phoneNumber} onSubmit={handleTitleSubmit} />
              </div>
              {isWhitelisted && tab === ChannelType.WhatsApp && phoneNumber.whatsApp && (
                <MetaContainer>
                  <ConnectMetaStatus registered={phoneNumber.whatsAppRegistered} />
                  {!phoneNumber.whatsAppRegistered && (
                    <ConnectMetaButton
                      didId={phoneNumberId}
                      phoneNumber={phoneNumber.phoneNumber}
                      whatsappMetaOtp={phoneNumber.whatsAppMetaOtp ?? false}
                    />
                  )}
                </MetaContainer>
              )}
            </TitleContainer>
            <StyledCard>
              <StyledToolbar>
                <Tabs
                  value={tab}
                  indicatorColor="primary"
                  onChange={(e, selectedTab) => {
                    setTab(selectedTab)
                    history.push(`/phonenumbers/${phoneNumberId}/${selectedTab}`)
                  }}
                >
                  {availableTabs.map(({ label, value }, index) => (
                    <StyledTab
                      key={`tab-${index}`}
                      id={`tab-${index}`}
                      value={value}
                      label={
                        <StyledBadge color="primary" badgeContent={generateTabCount(value)} showZero>
                          {label}
                        </StyledBadge>
                      }
                    />
                  ))}
                </Tabs>
              </StyledToolbar>
              {tab === ChannelType.Sms && <SmsHooksTab {...props} phoneNumberId={phoneNumberId} />}
              {tab === ChannelType.WhatsApp && <WhatsappHooksTab {...props} phoneNumberId={phoneNumberId} />}
              {tab === ChannelType.Voice && <VoiceHookTab {...props} phoneNumberId={phoneNumberId} />}
              {tab === ChannelType.Campaign && (
                <InboundVoiceCampaign
                  campaignIds={props.campaignIds}
                  orgId={orgId}
                  phoneNumberId={phoneNumberId}
                  token={token}
                  getCampaigns={props.getCampaigns}
                />
              )}
            </StyledCard>
          </ContentLayout>
        )}
      </Loading>
    </DndProvider>
  )
}

const StyledCard = styled(Card)({
  marginBottom: '1rem',
})

const StyledToolbar = styled(Toolbar)({
  backgroundColor: '#f5f5f5',
  minHeight: '0',
})

const StyledTab = styled(Tab)({
  paddingRight: '30px',
})

const TitleContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

const MetaContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
})

export const tabNames = [
  {
    label: 'Incoming SMS Rules',
    value: ChannelType.Sms,
  },
  {
    label: 'Incoming WhatsApp Rules',
    value: ChannelType.WhatsApp,
  },
  {
    label: 'Incoming Voice Rules',
    value: ChannelType.Voice,
  },
  {
    label: 'Incoming Voice Campaign',
    value: ChannelType.Campaign,
  },
]

export default createHydrated(PhoneNumberRules)
