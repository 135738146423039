import React from 'react'
import { Form } from '@/app/component/form'
import { useCreateGroup } from '@/app/module/groups/hooks/use-create-group'
import { Box } from '@mui/material'
import { GroupQuery } from '@/app/module/groups/types'
import { LoadingButton } from '@mui/lab'
import { FormContainer } from './common'
import { fields, schema } from './specs'

type Props = {
  orgId: number
  query: GroupQuery
  token: string

  getGroups: (payload: { token: string; orgId: number; query: GroupQuery }) => void
  onExit: () => void
}

const CreateGroupForm: React.FC<Props> = ({ orgId, query, token, getGroups, onExit }) => {
  const [createGroup, { isLoading }] = useCreateGroup()

  const handleSubmit = React.useCallback(
    async (values: { name: string }) => {
      const success = await createGroup({ name: values.name, description: '', noAutodelete: false })
      if (!success) {
        return
      }
      getGroups({ token, orgId, query }) // FIXME: this should be removed when we move getGroups to generated API
      onExit()
    },
    [orgId, query, token, createGroup, getGroups, onExit],
  )

  return (
    <FormContainer>
      <Form
        defaultValues={defaultValues}
        fields={fields}
        name="create-group"
        schema={schema}
        shouldReset
        onSubmit={handleSubmit}
        renderActions={({ isDirty, isValid }) => (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 3 }}>
            <LoadingButton
              color="primary"
              disabled={!isDirty || !isValid}
              loading={isLoading}
              type="submit"
              variant="contained"
            >
              Save
            </LoadingButton>
          </Box>
        )}
      />
    </FormContainer>
  )
}

const defaultValues = {
  name: '',
}

export default CreateGroupForm
