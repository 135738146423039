import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import React from 'react'
import Tooltip from '@/app/component/atom/tooltip'
import Loading from '@/app/component/guard/loading'
import { SelectOption } from '@/app/types'
import { useSelector } from 'react-redux'
import { selectLanguages } from '@/app/module/utils/store/selectors'
import { Form } from '@/app/component/form'
import { useGetCustomFields } from '@/app/module/custom-fields/hooks/use-get-custom-fields'
import { styled } from '@mui/material'
import { useCreateContact } from '@/app/module/contacts/hooks/use-create-contact'
import { ContactValues } from '@/app/module/contacts/types'
import { ActionsContainer, FormContainer } from './common'
import { getCreateContactFields, getCreateContactSchema } from './specs'

type Props = {
  activeFilter?: { name: string; value: string }

  onExit: (id?: number) => void
}

const CreateContactForm: React.FC<Props> = ({ activeFilter, onExit }) => {
  const [addToGroupAfterSaving, setAddToGroupAfterSaving] = React.useState(activeFilter?.name === 'groups')
  const languageOptions = useSelector(selectLanguages) as SelectOption<string>[]
  const { data: cfData, isFetching, isLoading, isUninitialized } = useGetCustomFields()
  const [createContact, { isLoading: isCreating }] = useCreateContact()

  const customFields = React.useMemo(() => cfData?.customfields || [], [cfData])

  const fields = React.useMemo(
    () => getCreateContactFields(customFields, languageOptions),
    [customFields, languageOptions],
  )
  const schema = React.useMemo(() => getCreateContactSchema(customFields), [customFields])

  const defaultValues = React.useMemo(
    () => ({
      firstName: '',
      lastName: '',
      fullPhoneNumber: '',
      language: '',
      ...customFields.reduce(
        (acc, { id }) => ({
          ...acc,
          [`customFields${id}`]: '',
        }),
        {},
      ),
    }),
    [customFields],
  )

  const handleSubmit = React.useCallback(
    async (data: ContactValues) => {
      const created = await createContact(data)
      if (!created) {
        return
      }

      if (addToGroupAfterSaving) {
        onExit(created.id)
      } else {
        onExit()
      }
    },
    [addToGroupAfterSaving, createContact, onExit],
  )

  const showLoading = isCreating || isFetching || isLoading || isUninitialized
  const showForm = !isFetching && !isLoading && !isUninitialized

  return (
    <Loading isLoading={showLoading} testId="contact-item">
      <AddToGroupContainer>
        <Tooltip title="You'll be able to select the group(s) after saving the contact.">
          <FormControlLabel
            control={
              <Checkbox
                id="add-edit-contact-add-to-group-checkbox"
                checked={addToGroupAfterSaving}
                onChange={(e) => setAddToGroupAfterSaving(e.currentTarget.checked)}
              />
            }
            label="Also add this contact to a group(s)"
          />
        </Tooltip>
      </AddToGroupContainer>
      {showForm && (
        <FormContainer id="contact-item-form">
          <Form
            defaultValues={defaultValues}
            fields={fields}
            schema={schema}
            shouldReset
            onSubmit={handleSubmit}
            renderActions={(formState) => (
              <ActionsContainer>
                <Button id="contact-back-button" variant="outlined" onClick={() => onExit()}>
                  Back
                </Button>
                <Button
                  color="primary"
                  id="contact-save-button"
                  type="submit"
                  variant="contained"
                  disabled={isCreating || isFetching || isLoading || !formState.isValid}
                >
                  Save
                </Button>
              </ActionsContainer>
            )}
          />
        </FormContainer>
      )}
    </Loading>
  )
}

const AddToGroupContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',
  marginTop: -30,

  '& .MuiFormControlLabel-root': {
    marginRight: 0,
  },
})

export default CreateContactForm
