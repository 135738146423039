import { esApi, getErrorMessage } from '@/app/store/api'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '@/app/store/redux-hooks'
import { useEffect } from 'react'
import { createNotification } from '@/app/module/notifications/store/actions'
import { selectOrgId } from '@/app/module/user/store/selectors'

export const useGetGroup = (groupId: number) => {
  const dispatch = useAppDispatch()
  const orgId = useSelector(selectOrgId)

  const res = esApi.endpoints.getOrgGroup.useQuery(
    { orgId: orgId.toString(), groupId: groupId.toString() },
    { skip: !orgId || !groupId },
  )

  useEffect(() => {
    if (!res.error) {
      return
    }
    dispatch(
      createNotification({
        'get-group-failure': {
          type: 'error',
          message: getErrorMessage(res.error, 'Failed to get contact group'),
        },
      }),
    )
  }, [res.error, dispatch])

  return res
}
