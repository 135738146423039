import { CampaignTemplateType, Message } from '@/app/module/campaigns/types'
import { ensureUniqueIdForMessageActions } from '@/app/module/campaigns/utils/actions/ids'

export const duplicateMessage = (campaignType: CampaignTemplateType, message: Message, newId: string): Message => {
  const actionsData = ensureUniqueIdForMessageActions(message)

  const newMessage = {
    ...message,
    ...actionsData,
    id: newId,
  }

  const isFirstMessage = !message.delta?.unit || message.delta.unit === 'immediate'
  if (campaignType !== 'drip' || !isFirstMessage) {
    return newMessage
  }

  // if there's no delta unit or unit is immediate, the previous message is the first message in the drip,
  // so we need to set the delta to 1 day for the new message
  return {
    ...newMessage,
    delta: {
      ...message.delta,
      unit: 'day',
      value: 1,
    },
  }
}
