import React from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import Layout from '@/app/component/layout/page'
import { AuthGuard, AuthHeader } from '@/app/module/auth'
import { updateItem } from '@/app/module/contacts/store/actions'
import Notifications from '@/app/module/notifications'
import UpdateContactForm from '@/app/module/contacts/component/form/update-contact-form'

const Container = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  maxWidth: '960px',
  margin: 'auto',
}))

type ContactsItemPageProps = {
  name: string
  match: {
    url: string
    path: string
  }
  clearItem: () => void
}

function ContactsEditItemPage({ name, match, clearItem }: ContactsItemPageProps) {
  const history = useHistory()
  const location = useLocation()
  const params = useParams<{ contactsId: string }>()

  const onExitItem = React.useCallback(() => {
    history.push(location.state?.fromUrl || '/contacts')
  }, [history, location])

  React.useEffect(() => {
    return () => {
      clearItem()
    }
  }, [clearItem])

  return (
    <AuthGuard url={match.url} path={match.path}>
      <Layout title="Edit Contact - engageSPARK">
        <AuthHeader currentPage={name} />
        <Container>
          <Box sx={{ my: 4 }}>
            <Typography variant="h5">Edit contact</Typography>
          </Box>
          <UpdateContactForm itemId={Number(params.contactsId)} onExit={onExitItem} />
        </Container>
      </Layout>
      <Notifications />
    </AuthGuard>
  )
}

export default connect(
  ({ contacts }) => ({
    data: contacts.list.data,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        clearItem: () => () => {
          dispatch(updateItem({}))
        },
      },
      dispatch,
    ),
)(ContactsEditItemPage)
